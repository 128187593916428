/* eslint-disable max-len */
import mcoreMessages from "mcore/messages"
import mcommonMessages from "mcommon/messages"
import { defineMessages } from "react-intl"
import mdashboardMessages from "mdashboard/messages"

export const messages = defineMessages({
  xcomponent : {
    id : "xmet.xcomponent",
    defaultMessage : "Component"
  },
  xcomponentType : {
    id : "xmet.xcomponentType",
    defaultMessage : "Component type"
  },
  extranetList : {
    id : "xmet.extranetList",
    defaultMessage : "Extranet list"
  },
  isActive : {
    id : "xmet.isActive",
    defaultMessage : "Active"
  },
  xcomponentList : {
    id : "xmet.xcomponentList",
    defaultMessage : "Component list"
  },
  extranetCreation : {
    id : "xmet.extranetCreation",
    defaultMessage : "Extranet creation"
  },
  editExtranet : {
    id : "xmet.editExtranet",
    defaultMessage : "Edit extranet"
  },
  extranetCreationSuccess : {
    id : "xmet.extranetCreationSuccess",
    defaultMessage : "Extranet successfully created. You can access it now."
  },
  viewComponent : {
    id : "xmet.viewComponent",
    defaultMessage : "View Component"
  },
  viewExtranet : {
    id : "xmet.viewExtranet",
    defaultMessage : "View Extranet"
  },
  unexpectedError : {
    id : "xmet.unexpectedError",
    defaultMessage : "An unexpected error occurred. Please retry."
  },
  errorFetchingData : {
    id : "xmet.errorFetchingData",
    defaultMessage : "An error occured while fetching data. Please retry"
  },
  errorFetchingReports : {
    id : "xmet.errorFetchingReports",
    defaultMessage : "An error occured while fetching reports. Please retry"
  },
  saveExtranet : {
    id : "xmet.saveExtranet",
    defaultMessage : "Save extranet"
  },
  extranetName : {
    id : "xmet.extranetName",
    defaultMessage : "Extranet name"
  },
  extranetUrlUnicityError : {
    id : "xmet.extranetUrlUnicityError",
    defaultMessage : "This URL already exists. URL must be unique."
  },
  extranetUrlRegexError : {
    id : "xmet.extranetUrlRegexError",
    defaultMessage : "URL must contains only - characters and lowercase letters."
  },
  extranetAddPagesAndMenus : {
    id : "xmet.extranetAddPagesAndMenus",
    defaultMessage : "Add pages and organize menus"
  },
  extranetMenusOrganization : {
    id : "xmet.extranetMenusOrganization",
    defaultMessage : "Menus organization"
  },
  extranetNewPage : {
    id : "xmet.extranetNewPage",
    defaultMessage : "New page"
  },
  extranetEditPage : {
    id : "xmet.extranetEditPage",
    defaultMessage : "Edit page"
  },
  extranetPageName : {
    id : "xmet.extranetPageName",
    defaultMessage : "Page name"
  },
  extranetDivergingPCWarning : {
    id : "xmet.extranetDivergingPCWarning",
    defaultMessage : "Production center from component does not match this extranet production center."
  },
  extranetPageDivergingPCWarning : {
    id : "xmet.extranetPageDivergingPCWarning",
    defaultMessage : "This page contain a component form a different production center."
  },
  extranetPCRightsWarning : {
    id : "xmet.extranetPCRightsWarning",
    defaultMessage : "The selected component is part of a production center that require additionnal access rights."
  },
  mapDivergingMeteogramPCWarning : {
    id : "xmet.mapDivergingPCWarning",
    defaultMessage : "Production center from selected meteogram does not match this map production center."
  },
  dashboardDivergingPCWarning : {
    id : "xmet.dashboardDivergingPCWarning",
    defaultMessage : "Production center from component does not match this dashboard production center."
  },
  xcomponentTypeFetchError : {
    id : "xmet.xcomponentTypeFetchError",
    defaultMessage : "Error when retrieving component types. Refresh the page and retry."
  },
  xcomponentFetchError : {
    id : "xmet.xcomponentFetchError",
    defaultMessage : "Error when retrieving components. Refresh the page and retry."
  },
  defaultDomain : {
    id : "xmet.defaultDomain",
    defaultMessage : "Domain by default"
  },
  maxDomain : {
    id : "xmet.maxDomain",
    defaultMessage : "Max Domain"
  },
  maxDataExtentDomain : {
    id : "xmet.maxDataExtentDomain",
    defaultMessage : "Max data extent Domain"
  },
  xmetSetZoom : {
    id : "xmet.xmetSetZoom",
    defaultMessage : "Set Max Zoom"
  },
  maxZoom : {
    id : "xmet.xmetZoom",
    defaultMessage : "Max Zoom"
  },
  saveMap : {
    id : "xmet.saveMap",
    defaultMessage : "Save map"
  },
  confirmClearMapComponent : {
    id : "xmet.confirmClearMapComponent",
    defaultMessage : "Are you sure you want to clear this map component ?"
  },
  clearMapComponent : {
    id : "xmet.clearMapComponent",
    defaultMessage : "Clear map component"
  },
  mapComponentSaved : {
    id : "xmet.mapComponentSaved",
    defaultMessage : "Map component saved"
  },
  saveMapComponent : {
    id : "xmet.saveMapComponent",
    defaultMessage : "Save map component"
  },
  editMap : {
    id : "xmet.editMap",
    defaultMessage : "Edit map"
  },
  viewMap : {
    id : "xmet.viewMap",
    defaultMessage : "View map"
  },
  rasterLayer : {
    id : "xmet.rasterLayer",
    defaultMessage : "Raster layer"
  },
  featureLayer : {
    id : "xmet.featureLayer",
    defaultMessage : "Feature layer"
  },
  warningLayer : {
    id : "xmet.warningLayer",
    defaultMessage : "Warning layer"
  },
  formWithTabValidationError : {
    id : "xmet.formWithTabValidationError",
    defaultMessage : "Field {field} is required in tab {tab}. Please complete it."
  },
  extendMaxDomain : {
    id : "xmet.extendMaxDomain",
    defaultMessage : "Max domain has been extended to include default domain."
  },
  newMeteogramComponent : {
    id : "xmet.newMeteogramComponent",
    defaultMessage : "New meteogram"
  },
  editMeteogram : {
    id : "xmet.editMeteogram",
    defaultMessage : "Edit meteogram"
  },
  viewMeteogram : {
    id : "xmet.viewMeteogram",
    defaultMessage : "View meteogram"
  },
  saveMeteogramComponent : {
    id : "xmet.saveMeteogramComponent",
    defaultMessage : "Save meteogram component"
  },
  meteogramComponentSaved : {
    id : "xmet.meteogramComponentSaved",
    defaultMessage : "Meteogram component saved"
  },
  confirmClearMeteogramComponent : {
    id : "xmet.confirmClearMeteogramComponent",
    defaultMessage : "Are you sure you want to clear this meteogram component ?"
  },
  clearMeteogramComponent : {
    id : "xmet.clearMeteogramComponent",
    defaultMessage : "Clear meteogram component"
  },
  addFeatureLayer : {
    id : "xmet.addFeatureLayer",
    defaultMessage : "Add a feature layer"
  },
  additionalParameters : {
    id : "xmet.additionalParameters",
    defaultMessage : "Additional parameters"
  },
  editFeatureLayer : {
    id : "xmet.editFeatureLayer",
    defaultMessage : "Edit feature layer"
  },
  addWarningLayer : {
    id : "xmet.addWarningLayer",
    defaultMessage : "Add a warning layer"
  },
  editWarningLayer : {
    id : "xmet.editWarningLayer",
    defaultMessage : "Edit warning layer"
  },
  aggregateType : {
    id : "xmet.aggregateType",
    defaultMessage : "Aggregation Method"
  },
  poisTypeField : {
    id : "xmet.poisTypeField",
    defaultMessage : "Pois types"
  },
  clickAction : {
    id : "xmet.clickAction",
    defaultMessage : "Click action"
  },
  meteogram : {
    id : "xmet.meteogram",
    defaultMessage : "Meteogram"
  },
  meteogramsFetchError : {
    id : "xmet.meteogramsFetchError",
    defaultMessage : "Error when retrieving Meteograms. Refresh the page and retry."
  },
  meteogramSavingFailed : {
    id : "xmet.meteogramSavingFailed",
    defaultMessage : "Meteogram saving failed."
  },
  noParamRegistered : {
    id : "xmet.noParamRegistered",
    defaultMessage : "No parameter registered."
  },
  clickActionFetchError : {
    id : "xmet.clickActionFetchError",
    defaultMessage : "Error when retrieving click actions. Refresh the page and retry."
  },
  open_meteogram : {
    id : "xmet.open_meteogram",
    defaultMessage : "Open a meteogram"
  },
  lineTitle : {
    id : "xmet.lineTitle",
    defaultMessage : "Line title"
  },
  allowExtranetAccessToContacts : {
    id : "xmet.allowExtranetAccessToContacts",
    default : "Allow extranet access to contacts"
  },
  addContact : {
    id : "xmet.addContact",
    defaultMessage : "Add contact"
  },
  noContacts : {
    id : "xmet.noContacts",
    defaultMessage : "No contacts added yet."
  },

  fetchClientError : {
    id : "xmet.fetchClientError",
    defaultMessage : "Error when retrieving clients data. Please retry."
  },
  currentTime : {
    id : "xmet.currentTime",
    defaultMessage : "Current time"
  },
  periodUTC : {
    id : "xmet.periodUTC",
    defaultMessage : "Period (UTC)"
  },
  displayPeriod : {
    id : "xmet.displayPeriod",
    defaultMessage : "Display period"
  },
  componentDeletionFailure : {
    id : "xmet.componentDeletionFailure",
    defaultMessage : "Component deletion failed. Please retry."
  },
  componentDeletionSuccess : {
    id : "xmet.componentDeletionSuccess",
    defaultMessage : "Component deletion successful."
  },
  pageReportsDeleted : {
    id : "xmet.pageReportsDeleted",
    defaultMessage : "The following reports have been deleted : {reports}. They won't be available anymore."
  },
  componentInUseInExtranets : {
    id : "xmet.componentInUseInExtranets",
    defaultMessage : "Cannot delete this component which is in use in the following extranets : {extranets}"
  },
  extranetDeletionFailure : {
    id : "xmet.extranetDeletionFailure",
    defaultMessage : "Extranet deletion failed. Please retry."
  },
  extranetDeletionSuccess : {
    id : "xmet.extranetDeletionSuccess",
    defaultMessage : "Extranet deletion successful."
  },
  extranetEditSuccess : {
    id : "xmet.extranetEditSuccess",
    defaultMessage : "Extranet edited with success."
  },
  extranetNameUnicityError : {
    id : "xmet.extranetNameUnicityError",
    defaultMessage : "This name is already used. Name must be unique."
  },
  pageNameUnicityError : {
    id : "xmet.pageNameUnicityError",
    defaultMessage : "This name is already used in this folder. Name must be unique in each folder."
  },
  pageSlugUnicityError : {
    id : "xmet.pageSlugUnicityError",
    defaultMessage : "This slug is already used in this folder. Slug must be unique in each folder."
  },
  updatePoiStyle : {
    id : "xmet.updatePoiStyle",
    defaultMessage : "Update POIs style"
  },
  symbolSize : {
    id : "xmet.symbolSize",
    defaultMessage : "Symbol size"
  },
  borderSize : {
    id : "xmet.borderSize",
    defaultMessage : "Border size"
  },
  poiSymbolFetchError : {
    id : "xmet.poiSymbolFetchError",
    defaultMessage : "Error when retrieving POI symbols. Refresh the page and retry."
  },
  Circle : {
    id : "xmet.circle",
    defaultMessage : "Circle"
  },
  Square : {
    id : "xmet.square",
    defaultMessage : "Square"
  },
  Triangle : {
    id : "xmet.triangle",
    defaultMessage : "Triangle"
  },
  firstAvailableDate : {
    id : "xmet.firstAvailableDate",
    defaultMessage : "First available date"
  },
  lastAvailableDate : {
    id : "xmet.lastAvailableDate",
    defaultMessage : "Last available date"
  },
  duplicationSuccess : {
    id : "xmet.duplicationSucess",
    defaultMessage : "Successfully duplicate \"{name}\""
  },
  duplicationFailure : {
    id : "xmet.duplicationFailure",
    defaultMessage : "Error when duplicating. Please retry."
  },
  withContacts : {
    id : "xmet.withContacts",
    defaultMessage : "Maintain the existing access rights"
  },
  createdAt : {
    id : "xmet.createdAt",
    defaultMessage : "Created on"
  },
  updatedAt : {
    id : "xmet.updatedAt",
    defaultMessage : "Last update on"
  },
  tagType : {
    id : "xmet.tagType",
    defaultMessage : "Tag type"
  },
  activesExtranets : {
    id : "xmet.activesExtranets",
    defaultMessage : "Active extranets"
  },
  inactivesExtranets : {
    id : "xmet.inactivesExtranets",
    defaultMessage : "Inactive extranets"
  },
  extranetsRelatedToComponent : {
    id : "xmet.extranetsRelatedToComponent",
    defaultMessage : "Extranets linked to this component"
  },
  noExtranetFound : {
    id : "xmet.noExtranetFound",
    defaultMessage : "No extranet found"
  },
  fromMediaLibrary : {
    id : "xmet.fromMediaLibrary",
    defaultMessage : "From media library..."
  },
  fromDisk : {
    id : "xmet.fromDisk",
    defaultMessage : "From disk..."
  },
  fromCustomerLogo : {
    id : "xmet.fromCustomerLogo",
    defaultMessage : "From customer logo..."
  },
  noCustomers : {
    id : "xmet.noCustomers",
    defaultMessage : "No customers"
  },
  noCustomersWithLogo : {
    id : "xmet.noCustomersWithLogo",
    defaultMessage : "No customers with logo"
  },
  customersFetchError : {
    id : "xmet.customersFetchError",
    defaultMessage : "Error while trying to fetch customers"
  },
  substitutionFetchError : {
    id : "xmet.substitutionFetchError",
    defaultMessage : "Error while trying to fetch substitutions"
  },
  textPlusColor : {
    id : "xmet.textPlusColor",
    defaultMessage : "Text + Color"
  },
  multilingualText : {
    id : "xmet.multilingualText",
    defaultMessage : "Multilingual text"
  },
  missingLangForText : {
    id : "xmet.missingLangForText",
    defaultMessage : "Missing text value for {lang}"
  },
  colorPlusValue : {
    id : "xmet.colorPlusValue",
    defaultMessage : "Color + Value"
  },
  substitutionTypeField : {
    id : "xmet.substitutionTypeField",
    defaultMessage : "Substitution type"
  },
  substitutionInvalid : {
    id : "xmet.substitutionInvalid",
    defaultMessage : "Substitution invalid"
  },
  paletteInvalid : {
    id : "xmet.paletteInvalid",
    defaultMessage : "No palette configured for this parameter in this substitution set"
  },
  paletteConfiguration : {
    id : "xmet.paletteConfiguration",
    defaultMessage : "Substitution configuration"
  },
  availableTimezone : {
    id : "xmet.availableTimezone",
    defaultMessage : "Available timezone"
  },
  defaultTimezone : {
    id : "xmet.defaultTimezone",
    defaultMessage : "Default timezone"
  },
  overrideSubstitution : {
    id : "xmet.overrideSubstitution",
    defaultMessage : "Override subsitution set"
  },
  display_2 : {
    id : "xmet.display_2",
    defaultMessage : "Display"
  },
  displayReports : {
    id : "xmet.displayReports",
    defaultMessage : "Display reports"
  },
  editReport : {
    id : "xmet.editReport",
    defaultMessage : "Edit report"
  },
  reports : {
    id : "xmet.reports",
    defaultMessage : "Reports"
  },
  addReport : {
    id : "xmet.addReport",
    defaultMessage : "Add report"
  },
  expertiseReportNotConfigured : {
    id : "xmet.expertiseReportNotConfigured",
    defaultMessage : "Your report has not been configured in any expertise interface"
  },
  relative : {
    id : "xmet.relative",
    defaultMessage : "Relative"
  },
  today_midnight : {
    id : "xmet.today_midnight",
    defaultMessage : "00:00 Today (GMT:00:00)UTC"
  },
  first_day_week : {
    id : "xmet.first_day_week",
    defaultMessage : "00:00 1st day of current week (GMT:00:00)UTC"
  },
  first_day_month : {
    id : "xmet.first_day_month",
    defaultMessage : "00:00 1st day of current month (GMT:00:00)UTC"
  },
  first_day_year : {
    id : "xmet.first_day_year",
    defaultMessage : "00:00 1st day of current year (GMT:00:00)UTC"
  },
  first_decade : {
    id : "xmet.first_decade",
    defaultMessage : "00:00 1st decade of current month (GMT:00:00)UTC"
  },
  second_decade : {
    id : "xmet.second_decade",
    defaultMessage : "00:00 2nd decade of current month (GMT:00:00)UTC"
  },
  third_decade : {
    id : "xmet.third_decade",
    defaultMessage : "00:00 3rd decade of current month (GMT:00:00)UTC"
  },
  years : {
    id : "xmet.years",
    defaultMessage : "years"
  },
  timeInterval : {
    id : "xmet.timeInterval",
    defaultMessage : "Time interval"
  },
  startRangeError : {
    id : "xmet.startRangeError",
    defaultMessage : "Starting range cannot be greater than ending range."
  },
  timeIntervalError : {
    id : "xmet.timeIntervalError",
    defaultMessage : "Time interval cannot be equal or less than 0."
  },
  timeIntervalError_2 : {
    id : "xmet.timeIntervalError_2",
    defaultMessage : "Time interval cannot be greater than total range."
  },
  finestTimeFormat : {
    id : "xmet.finestTimeFormat",
    defaultMessage : "Finest time format"
  },
  aggregateBy : {
    id : "xmet.aggregateBy",
    defaultMessage : "Aggregate by"
  },
  aggregatedTimeFormat : {
    id : "xmet.aggregatedTimeFormat",
    defaultMessage : "Aggregated time format"
  },
  weeks : {
    id : "xmet.weeks",
    defaultMessage : "weeks"
  },
  semesters : {
    id : "xmet.semesters",
    defaultMessage : "semesters"
  },
  quarters : {
    id : "xmet.quarters",
    defaultMessage : "quarters"
  },
  isoWeek : {
    id : "xmet.isoWeek",
    defaultMessage : "weeks"
  },
  trimesters : {
    id : "xmet.trimesters",
    defaultMessage : "trimesters"
  },
  generalInfos : {
    id : "xmet.generalInfos",
    defaultMessage : "General Infos"
  },
  spatialSettings : {
    id : "xmet.spatialSettings",
    defaultMessage : "Spatial Settings"
  },
  timeSettings : {
    id : "xmet.timeSettings",
    defaultMessage : "Time"
  },
  invalidPoi : {
    id : "xmet.invalidPoi",
    defaultMessage : "Warning : Some POIs you picked won't be shown because they are outside the data extent"
  },
  newMeteofactoryComponent : {
    id : "xmet.newMeteofactoryComponent",
    defaultMessage : "New meteofactory component"
  },
  editMeteofactory : {
    id : "xmet.editMeteofactory",
    defaultMessage : "Edit meteofactory"
  },
  viewMeteofactory : {
    id : "xmet.viewMeteofactory",
    defaultMessage : "View meteofactory"
  },
  saveMeteofactoryComponent : {
    id : "xmet.saveMeteofactoryComponent",
    defaultMessage : "Save meteofactory component"
  },
  meteofactoryComponentSaved : {
    id : "xmet.meteofactoryComponentSaved",
    defaultMessage : "Meteofactory component saved"
  },
  confirmClearMeteofactoryComponent : {
    id : "xmet.confirmClearMeteofactoryComponent",
    defaultMessage : "Are you sure you want to clear this meteofactory component ?"
  },
  clearMeteofactoryComponent : {
    id : "xmet.clearMeteofactoryComponent",
    defaultMessage : "Clear meteofactory component"
  },
  meteofactory : {
    id : "xmet.meteofactory",
    defaultMessage : "Meteofactory"
  },
  meteofactoriesFetchError : {
    id : "xmet.meteofactoriesFetchError",
    defaultMessage : "Error when retrieving Meteofactories. Refresh the page and retry."
  },
  meteofactorySavingFailed : {
    id : "xmet.meteofactorySavingFailed",
    defaultMessage : "Meteofactory saving failed."
  },
  openMeteofactory : {
    id : "xmet.openMeteofactory",
    defaultMessage : "Open a meteofactory"
  },
  displayOnlyLastProduct : {
    id : "xmet.displayOnlyLastProduct",
    defaultMessage : "Display only the latest product"
  },
  displayProductsFrom : {
    id : "xmet.displayProductsFrom",
    defaultMessage : "Display products dating less than"
  },
  noAvailableProductions : {
    id : "xmet.noAvailableProductions",
    defaultMessage : "No available productions"
  },
  noAvailableProducts : {
    id : "xmet.noAvailableProducts",
    defaultMessage : "No available products"
  },
  productionFetchError : {
    id : "xmet.productionFetchError",
    defaultMessage : "Error when retrieving Productions. Refresh the page and retry."
  },
  editContent : {
    id : "xmet.editContent",
    defaultMessage : "Edit content"
  },
  viewContent : {
    id : "xmet.viewContent",
    defaultMessage : "View content"
  },
  newContentComponent : {
    id : "xmet.newContentComponent",
    defaultMessage : "New content"
  },
  errorFieldsRequired : {
    id : "xmet.errorFieldsRequired",
    defaultMessage : "The following fields are required : {fields}. Please complete them."
  },
  textField : {
    id : "xmet.textField",
    defaultMessage : "Text field"
  },
  contentComponentSaved : {
    id : "xmet.contentComponentSaved",
    defaultMessage : "Content component saved."
  },
  gridPreviewError : {
    id : "xmet.gridPreviewError",
    defaultMessage : "Component {componentType} did not load properly."
  },
  folder : {
    id : "xmet.folder",
    defaultMessage : "Folder"
  },
  dashboard : {
    id : "xmet.dashboard",
    defaultMessage : "Dashboard"
  },
  embeddedComponentsNeeded : {
    id : "xmet.embeddedComponentsNeeded",
    defaultMessage : "At least one component is needed inside the dashboard."
  },
  noComponentsFound : {
    id : "xmet.noComponentsFound",
    defaultMessage : "No component found"
  },
  componentInUseInDashboards : {
    id : "xmet.componentInUseInDashboards",
    defaultMessage : "Cannot delete this component which is in use in the following dashboards : {dashboards}"
  },
  meteogramDisplayType : {
    id : "xmet.meteogramDisplayType",
    defaultMessage : "Display type"
  },
  meteogramDisplayTypeOneMany : {
    id : "xmet.meteogramDisplayTypeOneMany",
    defaultMessage : "One location, multiple parameters"
  },
  meteogramDisplayTypeManyOne : {
    id : "xmet.meteogramDisplayTypeManyOne",
    defaultMessage : "Multiple locations, one parameter"
  },
  columnMinWidth : {
    id : "xmet.columnMinWidth",
    defaultMessage : "Column minimum width (1 = 16px)"
  },
  columnMaxWidth : {
    id : "xmet.columnMaxWidth",
    defaultMessage : "Column maximum width (1 = 16px)"
  },
  chartComponentSaved : {
    id : "xmet.chartComponentSaved",
    defaultMessage : "Chart component saved."
  },
  editChart : {
    id : "xmet.editChart",
    defaultMessage : "Edit chart"
  },
  viewChart : {
    id : "xmet.viewChart",
    defaultMessage : "View chart"
  },
  newChartComponent : {
    id : "xmet.newChartComponent",
    defaultMessage : "New chart"
  },
  chartTypes : {
    id : "xmet.chartTypes",
    defaultMessage : "Chart types"
  },
  resetElevation : {
    id : "xmet.resetElevation",
    defaultMessage : `Be careful, the modifications will cause a reinitialization
    of the elevation previously set in the component.
    Are you sure you want to continue ?`
  },
  fieldIsRequired : {
    id : "xmet.fieldIsRequired",
    defaultMessage : "This field is required."
  },
  availableLanguages : {
    id : "xmet.availableLanguages",
    defaultMessage : "Available languages"
  },
  defaultLanguage : {
    id : "xmet.defaultLanguage",
    defaultMessage : "Default language"
  },
  folderSlugUnicityError : {
    id : "xmet.folderSlugUnicityError",
    defaultMessage : "This slug is already used in this folder. Slug must be unique in each folder."
  },
  headerMenu : {
    id : "xmet.headerMenu",
    defaultMessage : "Navigation menu"
  },
  footerMenu : {
    id : "xmet.footerMenu",
    defaultMessage : "Footer menu"
  },
  pageNameIncomplete : {
    id : "xmet.pageNameIncomplete",
    defaultMessage : "There's at least one page with incomplete name ({slug})."
  },
  folderNameIncomplete : {
    id : "xmet.folderNameIncomplete",
    defaultMessage : "There's at least one folder with incomplete name ({slug})."
  },
  aggregationPeriod : {
    id : "xmet.aggregationPeriod",
    defaultMessage : "Aggregation period"
  },
  lastUpdate : {
    id : "xmet.lastUpdate",
    defaultMessage : "Last update"
  },
  availablePeriod : {
    id : "xmet.availablePeriod",
    defaultMessage : "Available period"
  },
  enableAnimation : {
    id : "xmet.enableAnimation",
    defaultMessage : "Enable animation"
  },
  wmsUnreacheable : {
    id : "xmet.wmsUnreacheable",
    defaultMessage : "The WMS server chosen is not reachable currently. Please re-try later."
  },
  layerNameIncomplete : {
    id : "xmet.layerNameIncomplete",
    defaultMessage : "Layer name incomplete."
  },
  poiNameIncomplete : {
    id : "xmet.poiNameIncomplete",
    defaultMessage : "POI name incomplete."
  },
  pageOrMenuIncomplete : {
    id : "xmet.pageOrMenuIncomplete",
    defaultMessage : "Incomplete page and/or menu name."
  },
  wrongDomainProjection : {
    id : "xmet.wrongDomainProjection",
    defaultMessage : "The projection used for this domain is not supported. Please use one of theses projections : {projections}"
  },
  layerStyleNameUnicityError : {
    id : "xmet.layerStyleNameUnicityError",
    defaultMessage : "This layer style name is already used. Style name must be unique in each layer."
  },
  invalidProcessSelected : {
    id : "xmet.invalidProcessSelected",
    defaultMessage : "Invalid process selected. List not saved."
  },
  foldersTooManyDepths : {
    id : "xmet.foldersTooManyDepths",
    defaultMessage : "Maximum depths crossed (3 nested folders max)."
  },
  renderActive : {
    id : "xmet.renderActive",
    defaultMessage : "Render active"
  },
  renderInactive : {
    id : "xmet.renderInactive",
    defaultMessage : "Render inactive"
  },
  somePoisOutOfZone : {
    id : "xmet.somePoisOutOfZone",
    defaultMessage : "Some points of interest are out of zone."
  },
  thisPoiOutOfZone : {
    id : "xmet.thisPoiOutOfZone",
    defaultMessage : "This point of interest is out of zone."
  },
  verticalProfileElevations : {
    id : "xmet.verticalProfileElevations",
    defaultMessage : "Vertical profile heights"
  },
  verticalProfileElevationsConfiguration : {
    id : "xmet.verticalProfileElevationsConfiguration",
    defaultMessage : "Settings for the vertical profiles heights"
  },
  sort : {
    id : "xmet.sort",
    defaultMessage : "Sort"
  },
  componentName : {
    id : "xmet.componentName",
    defaultMessage : "Component name"
  },
  POIs : {
    id : "xmet.pois",
    defaultMessage : "Geographical positions"
  },
  newDashboardComponent : {
    id : "xmet.newDashboardComponent",
    defaultMessage : "New dashboard"
  },
  editDashboard : {
    id : "xmet.editDashboard",
    defaultMessage : "Edit dashboard"
  },
  viewDashboard : {
    id : "xmet.viewDashboard",
    defaultMessage : "View dashboard"
  },
  dashboardComponentSaved : {
    id : "xmet.dashboardComponentSaved",
    defaultMessage : "Dashboard component saved."
  },
  deleteFollowingItems : {
    id : "xmet.deleteFollowingItems",
    defaultMessage : "Are you sure you want to delete the following items ?"
  },
  duplicateFollowingItems : {
    id : "xmet.duplicateFollowingItems",
    defaultMessage : "Are you sure you want to duplicate the following items ?"
  },
  itemsDeletionFailure : {
    id : "xmet.itemsDeletionFailure",
    defaultMessage : "Some items deletions have failed."
  },
  itemsDeletionSuccess : {
    id : "xmet.itemsDeletionSuccess",
    defaultMessage : "Items deleted with success."
  },
  itemsEditSuccess : {
    id : "xmet.itemsEditSuccess",
    defaultMessage : "Items edited with success."
  },
  itemsEditFailure : {
    id : "xmet.itemsEditFailure",
    defaultMessage : "Some items deletions have failed."
  },
  itemsDuplicationSuccess : {
    id : "xmet.itemsDuplicationSuccess",
    defaultMessage : "Items duplicated with success."
  },
  itemsDuplicationFailure : {
    id : "xmet.itemsDuplicationFailure",
    defaultMessage : "Items duplication have failed."
  },
  widthInPercent : {
    id : "xmet.widthInPercent",
    defaultMessage : "Width (in %)"
  },
  heightInPixel : {
    id : "xmet.heightInPixel",
    defaultMessage : "Height (in px)"
  },
  extranetMenusErrorPleaseSave : {
    id : "xmet.extranetMenusErrorPleaseSave",
    defaultMessage : "Errors have been detected and corrected in the menus. Please save this extranet to save the corrections."
  },
  mixedPoiTypesDetected : {
    id : "xmet.mixedPoiTypesDetected",
    defaultMessage : "Mixed POI types detected."
  },
  mixedPoiTypesNotAllowed : {
    id : "xmet.mixedPoiTypesNotAllowed",
    defaultMessage : "Mixing POI types is not allowed."
  },
  componentSaved : {
    id : "xmet.componentSaved",
    defaultMessage : "Component saved"
  },
  extranetNoPageConfigured : {
    id : "xmet.extranetNoPageConfigured",
    defaultMessage : "No visible page is configured so far in this extranet. Therefore, this extranet website will not be made available online"
  },
  extranetCannotBeActivated : {
    id : "xmet.extranetCannotBeActivated",
    defaultMessage : "Extranet cannot be activated because is empty or does not contain any active pages"
  },
  elevationError : {
    id : "xmet.elevationError",
    defaultMessage : "Selected elevation is invalid with the current process/aggregate combination."
  },
  hazard : {
    id : "xmet.hazard",
    defaultMessage : "Hazard"
  },
  externalWmsLayer : {
    id : "xmet.externalWmsLayer",
    defaultMessage : "External WMS layer"
  },
  abstract : {
    id : "xmet.abstract",
    defaultMessage : "Abstract"
  },
  warningDifferentPoisType : {
    id : "xmet.warningDifferentPoisType",
    defaultMessage : "You can only have one type of point of interest. If you want to change, all precedent Pois will be deleted. Are you sure you want to continue ?"
  },
  externalDataDatetimeDimensionError : {
    id : "xmet.externalDataDatetimeDimensionError",
    defaultMessage : "External Data configuration error: must have only one datetime dimension."
  },
  errorMaxArrayLength : {
    id : "xmet.errorMaxArrayLength",
    defaultMessage : "Maximum {max} {max, plural, one {item} other {items} }."
  },
  createRow : {
    id : "xmet.createRow",
    defaultMessage : "Create row"
  },
  editRow : {
    id : "xmet.editRow",
    defaultMessage : "Edit row"
  },
  shouldReplaceNullValue : {
    id : "xmet.shouldReplaceNullValue",
    defaultMessage : "Should replace empty value ?"
  },
  replacementValue : {
    id : "xmet.replacementValue",
    defaultMessage : "Replacement value"
  },
  extranetNotActivableWarning : {
    id : "xmet.extranetNotActivableWarning",
    defaultMessage : "You have selected 1 or several extranets which cannot be activated, therefore they will not be edited."
  },
  extranetImpactMessage : {
    id : "xmet.extranetImpactMessage",
    defaultMessage : "If you change the production center of this component you will impact {extranetNumber} {extranetString} :"
  },
  extranetSingular : {
    id : "xmet.extranetSingular",
    defaultMessage : "extranet"
  },
  extranetPlural : {
    id : "xmet.extranetPlural",
    defaultMessage : "extranets"
  },
  divergingProjectionWarning : {
    id : "xmet.divergingProjectionWarning",
    defaultMessage : "Predefined domain {domain} does not match map projection {projection} please select another domain to save the map"
  },
  divergingCustomProjectionWarning : {
    id : "xmet.divergingCustomProjectionWarning",
    defaultMessage : "Custom domain projection ({customProjection}) does not match map projection {projection} please edit the custom domain to save the map"
  },
  readOnlyMode : {
    id : "xmet.readOnlyMode",
    defaultMessage : "Attention, you are in read only mode, any change made won't be saved."
  },
  filterByExternalCredentials : {
    id : "xmet.filterByExternalCredentials",
    defaultMessage : "Filter list to contacts with external credentials"
  }
})

export default { ...mcoreMessages, ...mdashboardMessages, ...mcommonMessages, ...messages }
