/* eslint-disable max-len */
import { defineMessages } from "react-intl"
import commonMessages from "mcommon/messages"

export const messages = defineMessages({
  reference : {
    id : "mdirectory.reference",
    defaultMessage : "Reference"
  },
  customerManagement : {
    id : "mdirectory.customerManagement",
    defaultMessage : "Customer management"
  },
  customerType : {
    id : "mdirectory.customerType",
    defaultMessage : "Customer type"
  },
  customerTypeNew : {
    id : "mdirectory.customerTypeNew",
    defaultMessage : "New Customer type"
  },
  customerName : {
    id : "mdirectory.customerName",
    defaultMessage : "Customer name"
  },
  fullName : {
    id : "mdirectory.fullName",
    defaultMessage : "Full name"
  },
  children : {
    id : "mdirectory.children",
    defaultMessage : "Children"
  },
  contactSearch : {
    id : "mdirectory.contactSearch",
    defaultMessage : "The search is carried out on the names of the contacts, their descriptions and their coordinates."
  },
  proactiveContact : {
    id : "mdirectory.proactiveContact",
    defaultMessage : "Contact for proactivity"
  },
  internalContact : {
    id : "mdirectory.internalContact",
    defaultMessage : "Internal contact"
  },
  function : {
    id : "mdirectory.function",
    defaultMessage : "Function"
  },
  functions : {
    id : "mdirectory.functions",
    defaultMessage : "Functions"
  },
  contactsList : {
    id : "mdirectory.contactsList",
    defaultMessage : "Contacts list"
  },
  newContact : {
    id : "mdirectory.newContact",
    defaultMessage : "New Contact"
  },
  newActivityArea : {
    id : "mdirectory.newActivityArea",
    defaultMessage : "New activity area"
  },
  categorie : {
    id : "mdirectory.categorie",
    defaultMessage : "Category"
  },
  categoryNew : {
    id : "mdirectory.categoryNew",
    defaultMessage : "New Category"
  },
  branchInfo : {
    id : "mdirectory.branchInfo",
    defaultMessage : "branch informations"
  },
  toContacts : {
    id : "mdirectory.toContacts",
    defaultMessage : "To contacts"
  },
  toSubscriptions : {
    id : "mdirectory.toSubscriptions",
    defaultMessage : "To suscriptions"
  },
  newSubscriptions : {
    id : "mdirectory.newSubscriptions",
    defaultMessage : "New subscription"
  },
  customerTypes : {
    id : "mdirectory.customerTypes",
    defaultMessage : "Customer types"
  },
  identifierAssociation : {
    id : "mdirectory.identifierAssociation",
    defaultMessage : "Associate to identifier"
  },
  identifiersAssociation : {
    id : "mdirectory.identifiersAssociation",
    defaultMessage : "Associate to identifiers"
  },
  fieldSubscription : {
    id : "mdirectory.label",
    defaultMessage : "Subscription"
  },
  labelRisk : {
    id : "mdirectory.labelRisk",
    defaultMessage : "Risk label"
  },
  becoming : {
    id : "mdirectory.becoming",
    defaultMessage : "Becoming"
  },
  incoming : {
    id : "mdirectory.incoming",
    defaultMessage : "Incoming"
  },
  outgoing : {
    id : "mdirectory.outgoing",
    defaultMessage : "Outgoing"
  },
  call_duration : {
    id : "mdirectory.call_duration",
    defaultMessage : "Call duration"
  },
  duration : {
    id : "mdirectory.duration",
    defaultMessage : "Duration"
  },
  exchange : {
    id : "mdirectory.exchange",
    defaultMessage : "Exchange"
  },
  correspondent : {
    id : "mdirectory.correspondent",
    defaultMessage : "Correspondent"
  },
  channel : {
    id : "mdirectory.channel",
    defaultMessage : "Channel"
  },
  objectCall : {
    id : "mdirectory.objectCall",
    defaultMessage : "Object of exchange"
  },
  objectCallNew : {
    id : "mdirectory.objectCallNew",
    defaultMessage : "New Object"
  },
  warning_editing : {
    id : "mdirectory.warning_editing",
    defaultMessage : "An exchange is being edited"
  },
  warning_editing_desc : {
    id : "mdirectory.warning_editing_desc",
    defaultMessage : "Warning, all changes will be lost"
  },
  add_call : {
    id : "mdirectory.add_call",
    defaultMessage : "Add Call"
  },
  edit_exchange : {
    id : "mdirectory.edit_exchange",
    defaultMessage : "Edit an exchange"
  },
  create_exchange : {
    id : "mdirectory.create_exchange",
    defaultMessage : "Add a new exchange"
  },
  add_exchange : {
    id : "mdirectory.add_exchange",
    defaultMessage : "Add a new exchange"
  },
  create_call : {
    id : "mdirectory.create_call",
    defaultMessage : "Add a new Call"
  },
  edit_call : {
    id : "mdirectory.edit_call",
    defaultMessage : "Edit a call"
  },
  sucess_saving : {
    id : "mdirectory.sucess_saving",
    defaultMessage : "Succesfuly saved ticket"
  },
  error_saving : {
    id : "mdirectory.error_saving",
    defaultMessage : "There was an error while saving ticket"
  },
  close_customer_care : {
    id : "mdirectory.close_customer_care",
    defaultMessage : "Close customer care"
  },
  open_customer_care : {
    id : "mdirectory.open_customer_care",
    defaultMessage : "Customer care"
  },
  editCustomerCare : {
    id : "mdirectory.editCustomerCare",
    defaultMessage : "Open customer care and edit exchange"
  },
  client_file : {
    id : "mdirectory.client_file",
    defaultMessage : "Customer details"
  },
  view : {
    id : "mdirectory.view",
    defaultMessage : "Details"
  },
  poi : {
    id : "mdirectory.poi",
    defaultMessage : "{count, plural, one {Point of interest} other {Points of interests}}"
  },
  add_this_call : {
    id : "mdirectory.add_this_call",
    defaultMessage : "Add this call"
  },
  create_new_contact : {
    id : "mdirectory.create_new_contact",
    defaultMessage : "Select or type new contact to create"
  },
  hour_initial : {
    id : "mdirectory.hour_initial",
    defaultMessage : "h"
  },
  minute_initial : {
    id : "mdirectory.minute_initial",
    defaultMessage : "m"
  },
  created_on : {
    id : "mdirectory.created_on",
    defaultMessage : "Created on"
  },
  call_date : {
    id : "mdirectory.call_date",
    defaultMessage : "Call date"
  },
  reason : {
    id : "mdirectory.reason",
    defaultMessage : "Reason"
  },
  ticket_type : {
    id : "mdirectory.ticket_type",
    defaultMessage : "Ticket type"
  },
  date_range : {
    id : "mdirectory.date_range",
    defaultMessage : "Date range"
  },
  ticket_types : {
    id : "mdirectory.ticket_types",
    defaultMessage : "Ticket types"
  },
  reasons : {
    id : "mdirectory.reasons",
    defaultMessage : "Reasons"
  },
  channels : {
    id : "mdirectory.channels",
    defaultMessage : "Channels"
  },
  emailp : {
    id : "mdirectory.emailp",
    defaultMessage : "E-mail"
  },
  addDistributionMeans : {
    id : "mdirectory.addDistributionMeans",
    defaultMessage : "Add a means of communication"
  },
  newRisk : {
    id : "mdirectory.newRisk",
    defaultMessage : "New risk"
  },
  newExpertiseField : {
    id : "mdirectory.newExpertiseField",
    defaultMessage : "New Expertise Field"
  },
  personalizeExpertiseField : {
    id : "mdirectory.personalizeExpertiseField",
    defaultMessage : "Personalize Expertise Field"
  },
  agatClient : {
    id : "mdirectory.agatClient",
    defaultMessage : "Client's AGAT key"
  },
  selectRisk : {
    id : "mdirectory.selectRisk",
    defaultMessage : "Selection of an existing risk"
  },
  selectRisks : {
    id : "mdirectory.selectRisks",
    defaultMessage : "Select an existing risk"
  },
  synchronize_success : {
    id : "mdirectory.synchronize_success",
    defaultMessage : "Synchronization ok"
  },
  synchronize_failed : {
    id : "mdirectory.synchronize_failed",
    defaultMessage : "Synchronization failed"
  },
  expertiseChoice : {
    id : "mdirectory.expertiseChoice",
    defaultMessage : "Choice of expertise model"
  },
  fieldChoice : {
    id : "mdirectory.fieldChoice",
    defaultMessage : "Choice of field"
  },
  renstel : {
    id : "mdirectory.renstel",
    defaultMessage : "Renstel"
  },
  pro_actif : {
    id : "mdirectory.pro_actif",
    defaultMessage : "Pro active"
  },
  interview : {
    id : "mdirectory.interview",
    defaultMessage : "Interview"
  },
  tweet : {
    id : "mdirectory.tweet",
    defaultMessage : "Tweet"
  },
  mailList : {
    id : "mdirectory.mailList",
    defaultMessage : "E-mail list"
  },
  phoneList : {
    id : "mdirectory.phoneList",
    defaultMessage : "Phone list"
  },
  faxList : {
    id : "mdirectory.faxList",
    defaultMessage : "FAX list"
  },
  contactList : {
    id : "mdirectory.contactList",
    defaultMessage : "Contact List"
  },
  contactListInfo : {
    id : "mdirectory.contactListInfo",
    defaultMessage : "List of contacts separated by a comma or semicolon"
  },
  selectGeo : {
    id : "mdirectory.selectGeo",
    defaultMessage : "Select an existing point of interest"
  },
  newFunction : {
    id : "mdirectory.newFunction",
    defaultMessage : "New function"
  },
  purpose : {
    id : "mdirectory.purpose",
    defaultMessage : "Purpose"
  },
  objectsCall : {
    id : "mdirectory.objectsCall",
    defaultMessage : "Objects"
  },
  myTickets : {
    id : "mdirectory.myTickets",
    defaultMessage : "Tickets which I am the author"
  },
  recipientCheckboxInfo : {
    id : "mdirectory.recipientCheckboxInfo",
    defaultMessage : "Warning, only tick if this means of communication is intended for a broadcast of a production."
  },
  smallExchDate : {
    id : "mdirectory.smallExchDate",
    defaultMessage : "Date of exch."
  },
  smallCustomerNameAndContact : {
    id : "mdirectory.smallCustomerNameAndContact",
    defaultMessage : "Customer name : corresp."
  },
  iconRecipient : {
    id : "mdirectory.iconRecipient",
    defaultMessage : "R"
  },
  proactive : {
    id : "mdirectory.proactive",
    defaultMessage : "Proactive"
  },
  customerSearch : {
    id : "mdirectory.customerSearch",
    defaultMessage : "Search on : Customer name and full name, contact name ans information"
  },
  exchangeWithCustomer : {
    id : "mdirectory.exchangeWithCustomer",
    defaultMessage : "Exchange with customer"
  },
  proactiveCustomers : {
    id : "mdirectory.proactiveCustomers",
    defaultMessage : "Proactive customers"
  },
  ignored : {
    id : "mdirectory.ignored",
    defaultMessage : "Ignored"
  },
  ignoredRecipientInfo : {
    id : "mdirectory.ignoredRecipientInfo",
    defaultMessage : "Distribution will take place, but if it is in error it will be ignored in the calculation of the status"
  },
  extranetSeparator : {
    id : "mdirectory.extranetSeparator",
    defaultMessage : "Several extranets can be entered by separating them with a semicolon"
  },
  urlSeparator : {
    id : "mdirectory.urlSeparator",
    defaultMessage : "Several URLs can be entered by separating them with a semicolon"
  },
  invalidUrl : {
    id : "mdirectory.invalidUrl",
    defaultMessage : "Invalid URL"
  },
  externalCredentials : {
    id : "mdirectory.externalCredentials",
    defaultMessage : "External credentials"
  },
  externalUsername : {
    id : "mdirectory.externalUsername",
    defaultMessage : "External username"
  },
  externalEmail : {
    id : "mdirectory.externalEmail",
    defaultMessage : "External email"
  },
  exportCustomerCard : {
    id : "mdirectory.exportCustomerCard",
    defaultMessage : "Export customer card"
  },
  substitutionAddSuccess : {
    id : "mdirectory.substitutionAddSuccess",
    defaultMessage : "Substitution added successfully"
  },
  substitutionAddError : {
    id : "mdirectory.substitutionAddError",
    defaultMessage : "An error occurred while adding the substitution"
  },
  exchangeType : {
    id : "mdirectory.exchangeType",
    defaultMessage : "Exchange type"
  }
})


export default { ...commonMessages, ...messages }
