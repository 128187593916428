import apis from "mreact/restAPI"
import RestClient from "mreact/lib/RestClient"

import locales from "./locales"
import messages from "./messages"

import config from "./package.json"

const {
  versionName, version, softwareName, themes, modules,
  hosts, languages, translationLanguages, defaultCountry, defaultMap, paths, editors, sentry
} = config

RestClient.loginPath = paths.login
apis.modules.baseUrl = paths.modules
apis.permissions.baseUrl = paths.permissions

export default {
  versionName,
  version,
  softwareName,
  webSocketPath : paths.websocket,
  themes,
  modules,
  hosts,
  defaultCountry,
  defaultMap,
  languages,
  translationLanguages,
  locales,
  messages,
  editors,
  sentry
}
