import { messages as mreactMessages } from "mreact/messages"
import { messages as mcommonMessages } from "mcommon/messages"
import { messages as mcoreMessages } from "mcore/messages"
import { messages as mdirectoryMessages } from "mdirectory/messages"
import { messages as muserMessages } from "muser/messages"
import { messages as mdashboardMessages } from "mdashboard/messages"
import { messages as eventaggregatorMessages } from "eventaggregator/messages"
import { messages as xmetMessages } from "xmet/messages"

const duplicates = []

const mergeWithoutDuplicates = ({ ...messages }, ...extraMessages) => {
  const newMessages = extraMessages.shift()

  for (const key in newMessages) {
    if (messages[key]) {
      duplicates.push({ key, translation1 : messages[key], translation2 : newMessages[key] })
    } else messages[key] = newMessages[key]
  }

  if (extraMessages.length) return mergeWithoutDuplicates(messages, ...extraMessages)
  else if (duplicates.length) {
    console.error(duplicates)

    throw new Error("Duplicated translation keys found")

  } else return messages
}

export default mergeWithoutDuplicates(
  mreactMessages,
  mcommonMessages,
  mcoreMessages,
  mdirectoryMessages,
  muserMessages,
  mdashboardMessages,
  eventaggregatorMessages,
  xmetMessages
)
