import RestClient from "mreact/lib/RestClient"


export default class PermissionClient extends RestClient {

  constructor(url = "/public/api/metro/user/grouppermissions/") {

    super(url)

  }

  find(query = {}) {

    if (this.mocker.enabled) {
      return Promise.resolve({ disconnect : true })
    } else {
      return super.find(query)
    }
  }

  async get(id) {
    const values = await this.fetchJSON(this.baseUrl + "?group=" + id.group_id + "&module=" + id.object_id,
      { method : "GET" })

    return { values }

  }


  getModulePermissions(groupID, moduleId) {

    const fetchUrl = new URL(this.baseUrl)

    fetchUrl.searchParams.append("group", groupID)
    fetchUrl.searchParams.append("module", moduleId)

    return this.fetchJSON(fetchUrl, { method : "GET" })

  }

  setModulePermissions(data) {

    const fetchUrl = new URL(this.baseUrl)

    return this.fetchJSON(fetchUrl, { method : "POST", body : JSON.stringify(data) })

  }

}
