import { defineMessages } from "react-intl"
import metromessages from "mcommon/messages"

export const messages = defineMessages({
  java_not_yet_implemented : {
    id : "mdashboard.java_not_yet_implemented",
    defaultMessage : "Calling a java function is not yet implemented"
  },
  unknownTaskTable : {
    id : "mdashboard.unknownTaskTable",
    defaultMessage : "Unknown task table"
  },
  ponctual_mutualization : {
    id : "mdashboard.ponctual_mutualization",
    defaultMessage : "Ponctual mutualization"
  },
  ponctual_inactivity : {
    id : "mdashboard.ponctual_inactivity",
    defaultMessage : "Ponctual inactivity"
  },
  distribution_start_success : {
    id : "mdashboard.distribution_start_success",
    defaultMessage : "Distribution start successfully"
  },
  start_active_distributions : {
    id : "mdashboard.start_active_distributions",
    defaultMessage : "Start active distributions"
  },
  startHour : {
    id : "mdashboard.startHour",
    defaultMessage : "Start time"
  },
  startAndProductionTime : {
    id : "mdashboard.startAndProductionTime",
    defaultMessage : "Start / production time"
  },
  productionTime : {
    id : "mdashboard.productionTime",
    defaultMessage : "Production time"
  },
  startDateHour : {
    id : "mdashboard.startDateHour",
    defaultMessage : "Start date and time"
  },
  productionDateHour : {
    id : "mdashboard.productionDateHour",
    defaultMessage : "Production date and time"
  },
  new_distribution : {
    id : "mdashboard.new_distribution",
    defaultMessage : "New distribution"
  },
  run_again : {
    id : "mdashboard.run_again",
    defaultMessage : "Run again"
  },
  run_again_distribution : {
    id : "mdashboard.run_again_distribution",
    defaultMessage : "Run again distribution"
  },
  run_distribution : {
    id : "mdashboard.run_distribution",
    defaultMessage : "Run distribution"
  },
  ponctual : {
    id : "mdashboard.ponctual",
    defaultMessage : "Ponctual"
  },
  recurrent : {
    id : "mdashboard.recurrent",
    defaultMessage : "Recurrent"
  },
  recurring_mutualization : {
    id : "mdashboard.recurring_mutualization",
    defaultMessage : "Reccuring mutualization"
  },
  add_mutualization : {
    id : "mdashboard.add_mutualization",
    defaultMessage : "Add a mutualization"
  },
  recurring_inactivity : {
    id : "mdashboard.recurring_inactivity",
    defaultMessage : "Reccuring inactivity"
  },
  add_inactivity : {
    id : "mdashboard.add_inactivity",
    defaultMessage : "Add an inactivity"
  },
  task_delegation : {
    id : "mdashboard.task_delegation",
    defaultMessage : "Delegation of tasks :"
  },
  delegateToProfil : {
    id : "mdashboard.delegateToProfil",
    defaultMessage : "Delegate to dashboard"
  },
  general_options : {
    id : "mdashboard.general_options",
    defaultMessage : "General Options"
  },
  recurrence_type : {
    id : "mdashboard.recurrence_type",
    defaultMessage : "Type of recurrence"
  },
  mutualization : {
    id : "mdashboard.mutualization",
    defaultMessage : "Mutualization"
  },
  inactivity : {
    id : "mdashboard.inactivity",
    defaultMessage : "Inactivity"
  },
  mutualization_periods : {
    id : "mdashboard.mutualization_periods",
    defaultMessage : "Periods of mutualization"
  },
  inactivity_frequency : {
    id : "mdashboard.inactivity_frequency",
    defaultMessage : "Frequency of inactivity"
  },
  frequency : {
    id : "mdashboard.frequency",
    defaultMessage : "Frequency"
  },
  mutualization_frequency : {
    id : "mdashboard.mutualization_frequency",
    defaultMessage : "Frequency of mutualization"
  },
  profile : {
    id : "mdashboard.profile",
    defaultMessage : "Dashboard"
  },
  configurated_period : {
    id : "mdashboard.configurated_period",
    defaultMessage : "Configurated period"
  },
  configurated_periods : {
    id : "mdashboard.configurated_periods",
    defaultMessage : "Configurated periods"
  },
  profiles_with_mutualization : {
    id : "mdashboard.profiles_with_mutualization",
    defaultMessage : "Dashboards with mutualization"
  },
  profiles_with_inactivity : {
    id : "mdashboard.profiles_with_inactivity",
    defaultMessage : "Dashboards with inactivity"
  },
  productionCenterEdit : {
    id : "mdashboard.production_center_edit",
    defaultMessage : "Production Center Edit"
  },
  application_period : {
    id : "mdashboard.application_period",
    defaultMessage : "Period of application"
  },
  inactivities_periods : {
    id : "mdashboard.inactivities_periods",
    defaultMessage : "Periods of inactivity"
  },
  edit_mutualization_period : {
    id : "mdashboard.edit_mutualization_period",
    defaultMessage : "Edit a pooling period"
  },
  edit_inactivity_period : {
    id : "mdashboard.edit_inactivity_period",
    defaultMessage : "Edit a period of inactivity"
  },
  task_type : {
    id : "mdashboard.task_type",
    defaultMessage : "Task type"
  },
  task_priority : {
    id : "mdashboard.task_priority",
    defaultMessage : "Task priority"
  },
  task_state : {
    id : "mdashboard.task_state",
    defaultMessage : "Task state"
  },
  task_origin : {
    id : "mdashboard.task_origin",
    defaultMessage : "Origin"
  },
  task_selection : {
    id : "mdashboard.task_selection",
    defaultMessage : "Task selection"
  },
  new_task : {
    id : "mdashboard.new_task",
    defaultMessage : "New task"
  },
  new_irregular_task : {
    id : "mdashboard.new_irregular_task",
    defaultMessage : "New Irregular task"
  },
  nameOrNumber : {
    id : "mdashboard.nameOrNumber",
    defaultMessage : "Name or number"
  },
  group_full_access : {
    id : "mdashboard.group_full_access",
    defaultMessage : "Group with full access"
  },
  group_read_only : {
    id : "mdashboard.group_read_only",
    defaultMessage : "Group with read-only access"
  },
  task_name : {
    id : "mdashboard.task_name",
    defaultMessage : "Task name"
  },
  create : {
    id : "mdashboard.create",
    defaultMessage : "Create"
  },
  take_tasks : {
    id : "mdashboard.take_tasks",
    defaultMessage : "Taking tasks : "
  },
  toTaskBoard : {
    id : "mdashboard.toTaskBoard",
    defaultMessage : "To the task board"
  },
  selectedTasks : {
    id : "mdashboard.selectedTasks",
    defaultMessage : "Selected Task(s)"
  },
  take : {
    id : "mdashboard.take",
    defaultMessage : "Take"
  },
  dependencies : {
    id : "mdashboard.dependencies",
    defaultMessage : "Dependencies"
  },
  appraise_products : {
    id : "mdashboard.appraise_products",
    defaultMessage : "Products to appraise"
  },
  products_and_options : {
    id : "mdashboard.products_and_options",
    defaultMessage : "Products and options"
  },
  profile_and_creation_date : {
    id : "mdashboard.profile_and_creation_date",
    defaultMessage : "Dashboard and date of creation"
  },
  choose : {
    id : "mdashboard.choose",
    defaultMessage : "Choose"
  },
  insert_task : {
    id : "mdashboard.insert_task",
    defaultMessage : "Insert a task"
  },
  insertFailure : {
    id : "mdashboard.insertFailure",
    defaultMessage : "A problem occured while inserting task"
  },
  time_exceeded_by : {
    id : "mdashboard.time_exceeded_by",
    defaultMessage : "exceeded by {minutes}min"
  },
  fromDashboard : {
    id : "mdashboard.from_dashboard",
    defaultMessage : "From {dashboard}"
  },
  toDashboard : {
    id : "mdashboard.to_dashboard",
    defaultMessage : "To {dashboard}"
  },
  urgent_state : {
    id : "mdashboard.urgent_state",
    defaultMessage : "Urgent"
  },
  end_in : {
    id : "mdashboard.end_in",
    defaultMessage : "End in {end} minuts"
  },
  imminent_end : {
    id : "mdashboard.imminent_end",
    defaultMessage : "End in less than 1 minute"
  },
  lastRefresh : {
    id : "mdashboard.lastRefresh",
    defaultMessage : "Last refresh"
  },
  imminent_late : {
    id : "mdashboard.imminent_late",
    defaultMessage : "Late"
  },
  on_time : {
    id : "mdashboard.on_time",
    defaultMessage : "On time"
  },
  end_time_passed : {
    id : "mdashboard.end_time_passed",
    defaultMessage : "End time outdated"
  },
  diffusing : {
    id : "mdashboard.diffusing",
    defaultMessage : "Diffusing ..."
  },
  generating : {
    id : "mdashboard.generating",
    defaultMessage : "Generating ..."
  },
  taskCancelled : {
    id : "mdashboard.taskCancelled",
    defaultMessage : "Task cancelled"
  },
  cancelled : {
    id : "mdashboard.cancelled",
    defaultMessage : "cancelled"
  },
  unknown_status : {
    id : "mdashboard.unknown_status",
    defaultMessage : "Unknown"
  },
  unknown_origin : {
    id : "mdashboard.unknown_origin",
    defaultMessage : "Trigger type unknown"
  },
  unknown_type : {
    id : "mdashboard.unknown_type",
    defaultMessage : "Trigger type unknown"
  },
  distribution_configuration : {
    id : "mdashboard.distribution_configuration",
    defaultMessage : "Recipient management"
  },
  period_production : {
    id : "mdashboard.period_production",
    defaultMessage : "Period of production"
  },
  production_type : {
    id : "mdashboard.production_type",
    defaultMessage : "Type of production"
  },
  production_center : {
    id : "mdashboard.production_center",
    defaultMessage : "Production center"
  },
  productionCenters : {
    id : "mdashboard.productionCenters",
    defaultMessage : "Production centers"
  },
  profil : {
    id : "mdashboard.profil",
    defaultMessage : "Dashboard"
  },
  create_profile : {
    id : "mdashboard.create_profile",
    defaultMessage : "Create a dashboard"
  },
  filter_by : {
    id : "mdashboard.filter_by",
    defaultMessage : "Filter by"
  },
  filter_by_products : {
    id : "mdashboard.filter_by_products",
    defaultMessage : "Filter by products"
  },
  configurate : {
    id : "mdashboard.configurate",
    defaultMessage : "Configurate"
  },
  is_configurate : {
    id : "mdashboard.is_configurate",
    defaultMessage : "Configurate"
  },
  waiting_configuration : {
    id : "mdashboard.waiting_configuration",
    defaultMessage : "Waiting for configuration"
  },
  task_progress_status : {
    id : "mdashboard.task_progress_status",
    defaultMessage : "Task progress status"
  },
  reset_filters : {
    id : "mdashboard.reset_filters",
    defaultMessage : "Reset filters"
  },
  originalTask : {
    id : "mdashboard.originalTask",
    defaultMessage : "Original Task"
  },
  unknownOriginalTask : {
    id : "mdashboard.unknownOriginalTask",
    defaultMessage : "Unknown original Task"
  },
  select_profile : {
    id : "mdashboard.select_profile",
    defaultMessage : "Select a dashboard"
  },
  save_filters : {
    id : "mdashboard.save_filters",
    defaultMessage : "Save filters"
  },
  delete_view : {
    id : "mdashboard.delete_view",
    defaultMessage : "Delete view"
  },
  delete_view_confirm : {
    id : "mdashboard.delete_view_confirm",
    defaultMessage : "Are you certain you want to delete this view? This is not undoable"
  },
  toggle_all : {
    id : "mdashboard.toggle_all",
    defaultMessage : "Toggle all"
  },
  insert : {
    id : "mdashboard.insert",
    defaultMessage : "Insert"
  },
  delegate : {
    id : "mdashboard.delegate",
    defaultMessage : "Delegate"
  },
  edition : {
    id : "mdashboard.edition",
    defaultMessage : "Item edition"
  },
  toCome : {
    id : "mdashboard.toCome",
    defaultMessage : "To come"
  },
  in_creation : {
    id : "mdashboard.in_creation",
    defaultMessage : "In creation"
  },
  in_modification : {
    id : "mdashboard.in_modification",
    defaultMessage : "In modification"
  },
  error_state : {
    id : "mdashboard.error_state",
    defaultMessage : "Failure"
  },
  cancelled_state : {
    id : "mdashboard.cancelled_state",
    defaultMessage : "Cancelled"
  },
  in_progress_distribution : {
    id : "mdashboard.in_progress_distribution",
    defaultMessage : "Distribution in progress"
  },
  in_progress_generation : {
    id : "mdashboard.in_progress_generation",
    defaultMessage : "Generation in progress"
  },
  late_status : {
    id : "mdashboard.late_status",
    defaultMessage : "Late"
  },
  end_near_status : {
    id : "mdashboard.end_near_status",
    defaultMessage : "End near"
  },
  numberShort : {
    id : "mdashboard.numberShort",
    defaultMessage : "N°"
  },
  grouped_by_task_type : {
    id : "mdashboard.grouped_by_task_type",
    defaultMessage : "Task type : "
  },
  grouped_by_trigger_type : {
    id : "mdashboard.grouped_by_trigger_type",
    defaultMessage : "Trigger type : "
  },
  grouped_by_status : {
    id : "mdashboard.grouped_by_status",
    defaultMessage : "Status : "
  },
  grouped_by_priority : {
    id : "mdashboard.grouped_by_priority",
    defaultMessage : "Task priority : "
  },
  original_owner : {
    id : "mdashboard.original_owner",
    defaultMessage : "Original owner : {originalOwner}"
  },
  subTask : {
    id : "mdashboard.subTask",
    defaultMessage : "Sub-task"
  },
  subTasks : {
    id : "mdashboard.subTasks",
    defaultMessage : "Sub-tasks"
  },
  spread : {
    id : "mdashboard.spread",
    defaultMessage : "Spread"
  },
  amend : {
    id : "mdashboard.amend",
    defaultMessage : "Amend"
  },
  not_defined : {
    id : "mdashboard.not_defined",
    defaultMessage : "Not defined"
  },
  day_of_week : {
    id : "mdashboard.day_of_week",
    defaultMessage : "Day of the week + hour"
  },
  noContent : {
    id : "mdashboard.noContent",
    defaultMessage : "No content"
  },
  day_of_month : {
    id : "mdashboard.day_of_month",
    defaultMessage : "Day in month + hour"
  },
  day_of_year : {
    id : "mdashboard.day_of_year",
    defaultMessage : "Day of the year + hour"
  },
  deadLine : {
    id : "mdashboard.deadLine",
    defaultMessage : "Deadline : {deadLine}"
  },
  confirm_process_task : {
    id : "mdashboard.confirm_process_task",
    defaultMessage : "Are you sure you want to activate this task ?"
  },
  launch_now : {
    id : "mdashboard.launch_now",
    defaultMessage : "Launch now"
  },
  sameProfilWarningMsg : {
    id : "mdashboard.sameProfilWarningMsg",
    defaultMessage : "This task is already associated with current dashboard"
  },
  processing : {
    id : "mdashboard.processing",
    defaultMessage : "Processing"
  },
  exploitation : {
    id : "mdashboard.exploitation",
    defaultMessage : "Exploitation"
  },
  unfold : {
    id : "mdashboard.unfold",
    defaultMessage : "Show subtasks"
  },
  fold : {
    id : "mdashboard.fold",
    defaultMessage : "Hide subtasks"
  },
  todo_state : {
    id : "mdashboard.todo_state",
    defaultMessage : "To do"
  },
  created : {
    id : "mdashboard.created",
    defaultMessage : "Incoming"
  },
  lockMsg : {
    id : "mdashboard.lockMsg",
    // eslint-disable-next-line max-len
    defaultMessage : "User {username} is currently working on this sub-task. Are you sure you want to open it? Be careful if you validate, 2 users will be connected at the same time on the sub-task but only one will be saved."
  },
  lockBy : {
    id : "mdashboard.lockBy",
    defaultMessage : "Being modified by {username}."
  },
  selectTaskTableToBegin : {
    id : "mdashboard.selectTaskTableToBegin",
    defaultMessage : "Please select a task table to start working"
  },
  activeTaskTable : {
    id : "mdashboard.activeTaskTable",
    defaultMessage : "Active task table"
  },
  changeTaskTable : {
    id : "mdashboard.changeTaskTable",
    defaultMessage : "Change task table"
  },
  welcome : {
    id : "mdashboard.welcome",
    defaultMessage : "Welcome"
  },
  lastReport : {
    id : "mdashboard.lastReport",
    defaultMessage : "Last report"
  },
  nbReport : {
    id : "mdashboard.nbReport",
    defaultMessage : "Report number"
  },
  finishedOn : {
    id : "mdashboard.finishedOn",
    defaultMessage : "Finished on"
  },
  addEvent : {
    id : "mdashboard.addEvent",
    defaultMessage : "Add an event"
  },
  viewPastEvents : {
    id : "mdashboard.viewPastEvents",
    defaultMessage : "View past events"
  },
  events : {
    id : "mdashboard.events",
    defaultMessage : "Events"
  },
  newEvent : {
    id : "mdashboard.newEvent",
    defaultMessage : "New event"
  },
  reactivateEvent : {
    id : "mdashboard.reactivateEvent",
    defaultMessage : "Do you want to reactivate the event ?"
  },
  createEvent : {
    id : "mdashboard.createEvent",
    defaultMessage : "Create event"
  },
  assignment : {
    id : "mdashboard.assignment",
    defaultMessage : "Assignment"
  },
  assignmentPending : {
    id : "mdashboard.assignmentPending",
    defaultMessage : "Assignment pending"
  },
  notMoved : {
    id : "mdashboard.notMoved",
    defaultMessage : "Not moved"
  },
  geoPosition : {
    id : "mdashboard.geoPosition",
    defaultMessage : "Geographical position"
  },
  hashtag : {
    id : "mdashboard.hashtag",
    defaultMessage : "#"
  },
  no_status_available : {
    id : "mdashboard.no_status_available",
    defaultMessage : "No status available"
  },
  commentsOfTask : {
    id : "mdashboard.commentsOfTask",
    defaultMessage : "Comments of task: {name} (N°{number})"
  },
  commentAdded : {
    id : "mdashboard.commentAdded",
    defaultMessage : "Comment added"
  },
  errorSavingComment : {
    id : "mdashboard.errorSavingComment",
    defaultMessage : "There was an error while saving comment"
  },
  done : {
    id : "mdashboard.done",
    defaultMessage : "Done"
  },
  applyToAllSelectedTasks : {
    id : "mdashboard.applyToAllSelectedTasks",
    defaultMessage : "Apply to all selected tasks"
  },
  start_time : {
    id : "mdashboard.start_time",
    defaultMessage : "start time"
  },
  due_time : {
    id : "mdashboard.due_time",
    defaultMessage : "production time"
  },
  task_status : {
    id : "mdashboard.task_status",
    defaultMessage : "task status"
  },
  originalTaskTable : {
    id : "mdashboard.originalTaskTable",
    defaultMessage : "original task table"
  },
  subTaskStates : {
    id : "mdashboard.subTaskStates",
    defaultMessage : "Sub-task states"
  },
  run : {
    id : "mdashboard.run",
    defaultMessage : "Run"
  },
  commentDeleted : {
    id : "mdashboard.commentDeleted",
    defaultMessage : "Comment deleted"
  },
  commentEdited : {
    id : "mdashboard.commentEdited",
    defaultMessage : "Comment edited"
  },
  errorDeletingComment : {
    id : "mdashboard.errorDeletingComment",
    defaultMessage : "There was an error while deleting comment"
  },
  errorEditingComment : {
    id : "mdashboard.errorEditingComment",
    defaultMessage : "There was an error while editing comment"
  },
  temporaryRecipient : {
    id : "mdashboard.temporaryRecipient",
    defaultMessage : "Temporary recipient"
  },
  schedule : {
    id : "mdashboard.schedule",
    defaultMessage : "Schedule"
  },
  withTestMode : {
    id : "mdashboard.withTestMode",
    defaultMessage : "With test mode"
  },
  withoutTestMode : {
    id : "mdashboard.withoutTestMode",
    defaultMessage : "Without test mode"
  },
  test : {
    id : "mdashboard.test",
    defaultMessage : "Test"
  },
  operational : {
    id : "mdashboard.operational",
    defaultMessage : "Operational"
  },
  testDescription : {
    id : "mdashboard.testDescription",
    defaultMessage : "Test: Doesn't actually distribution"
  },
  operationalDescription : {
    id : "mdashboard.operationalDescription",
    // eslint-disable-next-line max-len
    defaultMessage : "Operational: Only distributions in the operational environment (otherwise, on the dev and integration servers, distributed to the generic Gmail mailbox)"
  },
  normalDescription : {
    id : "mdashboard.normalDescription",
    defaultMessage : "Normal: Distribution all the time"
  },
  amendConfirmation : {
    id : "mdashboard.amendConfirmation",
    defaultMessage : "Are you sure you want to amend task {taskName} ?"
  },
  deleteConfirmation : {
    id : "mdashboard.deleteConfirmation",
    defaultMessage : "Are you sure you want to delete task {taskName} ?"
  },
  report : {
    id : "mdashboard.report",
    defaultMessage : "Report"
  },
  requiredPointOfInterest : {
    id : "mdashboard.requiredPointOfInterest",
    defaultMessage : "Point of interest required"
  },
  hasPOI : {
    id : "mdashboard.hasPOI",
    defaultMessage : "Depends on a point of interest"
  },
  editStartDatetime : {
    id : "mdashboard.editStartDatetime",
    defaultMessage : "Edit start datetime"
  },
  editExtraFields : {
    id : "mdashboard.editExtraFields",
    defaultMessage : "Edit extra fields"
  },
  filterTags : {
    id : "mdashboard.filterTags",
    defaultMessage : "Filter with tags"
  },
  cancelLastNumerotation : {
    id : "mdashboard.cancelLastNumerotation",
    defaultMessage : "Cancel last numerotation"
  },
  clickToReactivate : {
    id : "mdashboard.clickToReactivate",
    defaultMessage : "Click to reactivate"
  },
  isDefaultEvent : {
    id : "mdashboard.isDefaultEvent",
    defaultMessage : "Default event"
  },
  selectionIsTooBig : {
    id : "mdashboard.selectionIsTooBig",
    defaultMessage : "Selection is too big"
  },
  waitingAck : {
    id : "mdashboard.waitingAck",
    defaultMessage : "Waiting ACK"
  },
  late_state_time_left : {
    id : "mdashboard.late_state_time_left",
    defaultMessage : "Late: {minutes}min left"
  },
  urgent_state_time_left : {
    id : "mdashboard.urgent_state_time_left",
    defaultMessage : "Urgent: {minutes}min left"
  },
  amendTimeLimit : {
    id : "mdashboard.amendTimeLimit",
    defaultMessage : "Amend time limit : {limit}"
  },
  supervised : {
    id : "mdashboard.supervised",
    defaultMessage : "Supervised task"
  }
})

export default { ...metromessages, ...messages }
