import RestClient from "mreact/lib/RestClient"

export default class PreferencesAPI extends RestClient {

  constructor(baseUrl = "/public/api/metro/directory/preferences/") {

    super(baseUrl)

    if (!PRODUCTION && RestClient.isOffline) {
      import(/* webpackChunkName: "mock-directory-preferences-api" */ "./mock")
        .then(mock => this.mocker.register(mock.default))
    }

  }

  getPref(user_id) {

    return this.fetchJSON(this.baseUrl + user_id + "/", { method : "GET" })
  }

  async updatePref(body) {
    const response = await this.fetchJSON(this.baseUrl + body.user_id + "/", {
      method : "PUT",
      body : JSON.stringify(body)
    })

    return response
  }

}
