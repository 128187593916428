import upperFirst from "lodash/upperFirst"
import moment from "moment"
import { Iterable } from "immutable"
import cloneDeep from "lodash/cloneDeep"
import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import transform from "lodash/transform"
import snakeCase from "lodash/snakeCase"
import { v4 as uuid } from "uuid"

export function createId() {
  return uuid()
}

export function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const style = color => [
  ["color", color],
  ["font-size", "1.1em"]
].map( ([key, val]) => `${key}:${val}` ).join(";")

export const consoleHeader = (color = "blue", title, ...rest) => [
  `%c🎵 🎶 ${title}`, style(color), rest.join(" - ")
]

export const chooseFile = ({ accept } = {}) => new Promise(resolve => {
  const input = document.createElement("input")

  input.type = "file"
  if (accept) input.accept = accept
  input.addEventListener("change", function onChange() { resolve(this.files[0]) })
  input.click()
})

export const chooseFiles = () => new Promise(resolve => {
  const input = document.createElement("input")

  input.type = "file"
  input.multiple = true
  input.addEventListener("change", function onChange() { resolve(this.files) })
  input.click()
})

export const readFile = (file, readAs = "text") => new Promise((resolve, reject) => {

  if (!(file instanceof File)) throw new Error("file argument incorrect")

  const reader = new FileReader()

  if (!["dataURL", "text"].includes(readAs)) throw new Error("format incorrect")

  reader.onload = e => resolve(e.target.result)
  reader.onerror = e => reject(new Error(e))

  reader["readAs" + upperFirst(readAs)](file)

})

export function getUrlParams() {

  // si les paramètres get sont écrits après le hash, ils ne sont pas pris en compte par window.location.search
  const matches = window.location.href.match(/(\?.*?)(?:#|$)/)
  const search = matches && matches[1]

  return new URLSearchParams(search)
}

export function removeUrlParam(param) {

  const rParam = new RegExp("&?" + param + "(=[^&#]+)?")

  const href = window.location.href.replace(rParam, "")

  window.history.replaceState("without param " + param, "", href)
}

export function getTime(offset, dateFormat) {

  // if (isNaN(offset)) throw new Error("getTime gotta use Number offset parameter !")

  if (offset) {
    // HEURE UTC 00:00 + offset
    const refDate = moment.utc().set("hour", 0).set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0)
      .add(parseInt(offset), "m")

    if (dateFormat) {
      return refDate.format(dateFormat)
    } else {
      return refDate.toISOString().replace(".000Z", "Z")
    }
  } else {
    return "Time"
  }
}

export function getTimeRange(startTime, endTime, stepTime) {

  const refDate = moment.utc().set("hour", 0).set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)

  const startDate = cloneDeep(refDate.add(startTime, "h"))
  const endDate = cloneDeep(refDate.add(endTime, "h"))

  const times = []
  const time = cloneDeep(startDate)

  while (time < endDate) {
    time.add(stepTime, "h")

    const jsonTime = time.toISOString()
      .replace(".000Z", "Z")

    times.push(jsonTime)
  }

  return times
}

export function getTimeRangeValue(timeRange, format_date) {

  const { startTime, endTime, stepTime } = timeRange

  const refDate = moment.utc().set("hour", 0).set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)

  const startDate = cloneDeep(refDate.add(startTime, "h"))
  const endDate = cloneDeep(refDate.add(endTime, "h"))

  const times = []
  const time = cloneDeep(startDate)

  while (time < endDate) {
    time.add(stepTime, "h")
    times.push(time.format(format_date || "LT"))

    if (format_date) {
      times.push(time.format(format_date))
    } else {
      const jsonTime = time.toISOString()
        .replace(".000Z", "Z")

      times.push(jsonTime)
    }
  }

  return times
}

export function round(num, decimals) {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

export function thresholdTo(num, formatValue) {
  return formatValue.find(({ operator, threshold }) => {
    switch (operator) {

    case "equal" : return num === threshold
    case "inf" : return num < threshold
    case "infEgal" : return num <= threshold
    case "sup" : return num > threshold
    case "supEgal" : return num >= threshold
    default : throw new Error(operator + " : unknown operator")

    }
  })?.value
}

export function roundTo(num, roundedTo) {
  // ne pas simplifier cette opération : si roundedTo est en virgule flottante
  // il faut calculer (valeur / (1/roundedTo)) et non (valeur * roundedTo) pour obtenir un résultat arrondi
  return Math.round(num * (1 / roundedTo)) / (1 / roundedTo)
}

/**
 * Permet de retrouver une clé imbriquée dans un objet (à la manière d'immutableJS)
 * @param {Object} obj objet à analyser
 * @param {Array} key clé imbriquée sous forme de tableau
 * @returns {*} la valeur de la clé
 */
export function findKey(obj, key) {
  return obj && key.length ? findKey(obj[key[0]], key.slice(1)) : obj
}

/**
 * Permet de définir la valeur d'une clé imbriquée d'un object
 * @param {Object} obj objet à modifier
 * @param {Array} key clée imbriquée sous forme de tableau
 * @param {*} value valeur de la clé
 * @returns {undefined}
 */
export function setKeyValue(obj, key, value) {

  if (!Array.isArray(key)) throw new Error("key must be an Array")

  if (key.length === 0) throw new Error("key is empty.")

  if (key.length === 1) return obj[key] = value

  if (!obj[key[0]]) obj[key[0]] = (typeof key[1] === "number") ? [] : {}

  return setKeyValue(obj[key[0]], key.slice(1), value)
}

/**
 * Permet d'avoir la couleur de texte la plus adapté en fonction de la couleur de fond
 * @param {String} hexcolor couleur de fond
 * @returns {String} couleur de texte
 */
export function getContrastColor(hexcolor) {
  let thehexcolor = hexcolor

  if (hexcolor.slice(0, 1) === "#") {
    thehexcolor = hexcolor.slice(1)
  }

  // Convert to RGB value
  const r = parseInt(thehexcolor.substr(0, 2), 16)
  const g = parseInt(thehexcolor.substr(2, 2), 16)
  const b = parseInt(thehexcolor.substr(4, 2), 16)

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

  // Check contrast
  return (yiq >= 128) ? "#222" : "white"
}

export function lowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

/**
 * Compare deux objets
 * @param {Object} obj1 premier objet
 * @param {Object} obj2 deuxièmre objet
 * @returns {Object} objet de comparaison
 */
export function diff(obj1, obj2) {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
      }
    })
  }

  return changes(obj1, obj2)
}

/**
 * Calcule les dimensions "naturelles" d'une image
 * @param {String} src source de l'image
 * @returns {Promise} Promesse qui se résoud avec en argument un objet possédant les propriétés width et height
 */
export function getImageSize(src) {
  return new Promise(resolve => {
    const img = new Image()

    img.src = src
    img.onload = () => {
      const { width, height } = img

      resolve({ width, height })
    }
  })
}


const absDimReg = /^\s*(\d+(?:\.\d+)?)(px|cm|mm|in|pt|pc)\s*$/

/**
 * Renvoie si la dimension css est de type absolu
 * @param {String} dim valeur css
 * @returns {Boolean} vrai si absolu
 */
export function isAbsoluteDimension(dim) {
  return absDimReg.test(dim)
}

/**
 * Convertit la valeur css (de type absolu) en pixels
 * @param {String} dim valeur css de la dimension (de type absolue)
 * @returns {Number} valeur en pixels
 */
export function dimensionToPixels(dim) {
  const pxPerIn = 96
  const newDim = (dim?.[0] === "-") ? dim.substring(1) : dim
  const [, value, unit] = absDimReg.exec(newDim) || []

  if (unit == null && value !== "0") throw new Error(dim + " is not an absolute dimension")

  const numberValue = (dim?.[0] === "-") ? (Number(value) * -1) : Number(value)

  switch (unit) {

  case "px" : return numberValue
  case "cm" : return numberValue * pxPerIn / 2.54
  case "mm" : return numberValue * (pxPerIn / 2.54) / 10
  case "in" : return numberValue * pxPerIn
  case "pt" : return numberValue * (pxPerIn / 72)
  case "pc" : return numberValue * (pxPerIn / 72) * 12
  default : throw new Error(unit + " : this case should never happen")

  }
}

/**
 * Retire les accents d'un chaîne de caractères
 * @param {String} str la chaîne à traiter
 * @returns {String} la chaîne sans les accents
 */
export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

/**
 * Transforme une phrase en chaîne slug (pas d'accents, espaces remplacés par des _)
 * @param {String} str chaîne à traiter
 * @returns {String} la chaîne slug
 */
export function slugify(str) {
  return snakeCase(removeAccents(str))
}

export function download(url, options = {}) {
  return fetch(url, options)
    .then(response => {
      if (response.status === 200 && response.ok) return response.blob()
      else throw response.statusText
    })

}

export async function downloadFile(file, filename, mimeType = "text/plain") {
  if ((file instanceof File) || (typeof file === "string")) {
    const link = document.createElement("a")

    let content

    if (file instanceof File) {
      content = await readFile(file, "dataURL")
    } else {
      content = "data:" + mimeType + "," + encodeURIComponent(file)
    }

    link.setAttribute("href", content)
    link.setAttribute("download", filename)
    link.style.display = "none"

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  } else {
    throw new Error("file argument incorrect")
  }
}

/**
 * Vérifie que toutes les clés des objets passés en paramètre
 * sont les mêmes même sans tenir compte de l'ordre
 * @param {Object} obj1 objet à comparer
 * @param {Object} obj2 objet à comparer
 * @returns {Boolean} True/False
 */
export function areKeysEqual(obj1, obj2) {
  const keys = Object.keys(obj1)

  if (keys.length !== Object.keys(obj2).length) return false

  return keys.every(key => key in obj2)
}

export async function loadImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image()

    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", reject)
    image.src = url
  })
}


export async function getDataUrl(url) {
  const img = await loadImage(url)
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  canvas.width = img.width
  canvas.height = img.height

  ctx.drawImage(img, 0, 0)

  return canvas.toDataURL()

}

// https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
function getCircularRemplacer() {
  const seen = new WeakSet()

  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        console.warn("there is a cyclic object value in your data", seen)

        return
      }
      seen.add(value)
    }

    /* eslint-disable-next-line consistent-return */
    return value
  }
}

/**
 * Transforme un objet javascript en chaîne JSON en supprimant si besoin les références circulaires
 * @param {*} data objet à transformer en chaîne
 * @param {String|Number} space insertions de blancs dans la chaîne JSON pour faciliter la lisibilité
 * @returns {String} la chaîne JSON
 */
export function safeJSONstringify(data, space) {
  return JSON.stringify(data, getCircularRemplacer(), space)
}

export function openNewTab(url, name) {
  const newTab = window.open(url, name)

  const mainWindow = window

  newTab.addEventListener("unload", () => mainWindow.focus())

  return newTab
}

export function toJS(value) {
  return Iterable.isIterable(value) ? value.toJS() : value
}

export function wait(ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
