import RestClient from "mreact/lib/RestClient"
import mockContent from "./mock.json"

export default class ModulesAPI extends RestClient {

  constructor(baseUrl) {

    super(baseUrl)

    this.mocker.register({
      path : "",
      handler : () => mockContent
    })

  }

}
