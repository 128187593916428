import { defineMessages } from "react-intl"
import metromessages from "mcommon/messages"

export const messages = defineMessages({
  selectTypeProvider : {
    id : "eventaggregator.selectTypeProvider",
    defaultMessage : "Select a type of provider"
  },
  newEventAggregatorConfiguration : {
    id : "eventaggregator.newEventAggregatorConfiguration",
    defaultMessage : "New event aggregator configuration"
  },
  typeProvider : {
    id : "eventaggregator.typeProvider",
    defaultMessage : "Type of provider"
  },
  aggregationConstraints : {
    id : "eventaggregator.aggregationConstraints",
    defaultMessage : "Aggregation constraints"
  },
  allconditions : {
    id : "eventaggregator.allConditions",
    defaultMessage : "All conditions"
  },
  removeCondition : {
    id : "eventaggregator.removeCondition",
    defaultMessage : "Remove condition"
  },
  newEventAggregatorSubscription : {
    id : "eventaggregator.newEventAggregatorSubscription",
    defaultMessage : "New event aggregator subscription"
  }
})

export default { ...metromessages, ...messages }
