import mreactlocales from "mreact/locales"
import mcommonlocales from "mcommon/locales"
import mcorelocales from "mcore/locales"
import mdirectorylocales from "mdirectory/locales"
import muserlocales from "muser/locales"
import mdashboardlocales from "mdashboard/locales"
import eventaggregatorlocales from "eventaggregator/locales"
import xmetlocales from "xmet/locales"

import merge from "lodash/merge"

let messages = mreactlocales

messages = merge(messages, mcommonlocales)
messages = merge(messages, mcorelocales)
messages = merge(messages, mdirectorylocales)
messages = merge(messages, muserlocales)
messages = merge(messages, mdashboardlocales)
messages = merge(messages, xmetlocales)
messages = merge(messages, eventaggregatorlocales)

export default messages
