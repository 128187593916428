import { defineMessages } from "react-intl"
import commonMessages from "mcommon/messages"

/* eslint max-len:0 */
export const messages = defineMessages({
  productModel : {
    id : "mcore.productModel",
    defaultMessage : "Modèle de produits"
  },
  addSubstitutedValuesOn : {
    id : "mcore.addSubstitutedValuesOn",
    defaultMessage : "Add substituted values on"
  },
  byDefault : {
    id : "mcore.byDefault",
    defaultMessage : "By default"
  },
  unnamed : {
    id : "mcore.unnamed",
    defaultMessage : "Unnamed"
  },
  pointRange : {
    id : "mcore.pointRange",
    defaultMessage : "Point range in hours"
  },
  addGroupedLabels : {
    id : "mcore.addGroupedLabels",
    defaultMessage : "Add grouped labels"
  },
  staggerLines : {
    id : "mcore.staggerLines",
    defaultMessage : "Stagger lines"
  },
  configuringOverrides : {
    id : "mcore.configuringOverrides",
    defaultMessage : "Configuring overrides"
  },
  addDataLabel : {
    id : "mcore.addDataLabel",
    defaultMessage : "Add a data label"
  },
  formattingSeriesGroupedByConfiguration : {
    id : "mcore.formattingSeriesGroupedByConfiguration",
    defaultMessage : "Formatting series grouped by configuration"
  },
  noSeries : {
    id : "mcore.noSeries",
    defaultMessage : "No series"
  },
  warinngSeriesWithDifferentLayouts : {
    id : "mcore.warinngSeriesWithDifferentLayouts",
    defaultMessage : "Warning, some series groups contain series with different configurations!"
  },
  appliedFirstSeriesFormatting : {
    id : "mcore.appliedFirstSeriesFormatting",
    defaultMessage : "If you continue, the formatting of the first series in each group will be applied to all series in the group"
  },
  doYouWantToContinue : {
    id : "mcore.doYouWantToContinue",
    defaultMessage : "Do you want to continue ?"
  },
  dimensionValueOfChosenType : {
    id : "mcore.dimensionValueOfChosenType",
    defaultMessage : "Value of the dimension of the chosen type"
  },
  valueOfAllDimensions : {
    id : "mcore.valueOfAllDimensions",
    defaultMessage : "Value of all dimensions (except datetime) separated by _"
  },
  valueOfAllDimensionsSeparatedByChosenSeparator : {
    id : "mcore.valueOfAllDimensionsSeparatedByChosenSeparator",
    defaultMessage : "Value of all dimensions (except datetime) separated by the chosen separator"
  },
  nameSubstitutionParameter : {
    id : "mcore.nameSubstitutionParameter",
    defaultMessage : "Name of the substitution parameter"
  },
  labelSubstitutionParameter : {
    id : "mcore.labelSubstitutionParameter",
    defaultMessage : "Label of the substitution parameter"
  },
  dimensionValueSubstitutionOfChosenType : {
    id : "mcore.dimensionValueSubstitutionOfChosenType",
    defaultMessage : "Value of the dimension, of the substitution, of the chosen type"
  },
  valueOfAllDimensionsSubstitution : {
    id : "mcore.valueOfAllDimensionsSubstitution",
    defaultMessage : "Value of all dimensions (except datetime), of the substitution, separated by _"
  },
  valueOfAllDimensionsSubstitutionSeparatedByChosenSeparator : {
    id : "mcore.valueOfAllDimensionsSubstitutionSeparatedByChosenSeparator",
    defaultMessage : "Value of all dimensions (except datetime), substitution, separated by the chosen separator"
  },
  addXAxis : {
    id : "mcore.addXAxis",
    defaultMessage : "Add X axis"
  },
  axis : {
    id : "mcore.axis",
    defaultMessage : "Axis"
  },
  warningThisAxisIsNotUsed : {
    id : "mcore.warningThisAxisIsNotUsed",
    defaultMessage : "Warning, this axis is not used in any series!"
  },
  addOverride : {
    id : "mcore.addOverride",
    defaultMessage : "Add an override"
  },
  placement : {
    id : "mcore.placement",
    defaultMessage : "Placement"
  },
  lineSpacing : {
    id : "mcore.lineSpacing",
    defaultMessage : "Line spacing"
  },
  evenBandsColor : {
    id : "mcore.evenBandsColor",
    defaultMessage : "Color of even bands"
  },
  oddBandsColor : {
    id : "mcore.oddBandsColor",
    defaultMessage : "Color of odd bands"
  },
  inMilliseconds : {
    id : "mcore.inMilliseconds",
    defaultMessage : "In milliseconds"
  },
  isCorrectSave : {
    id : "mcore.isCorrectSave",
    defaultMessage : "est correctement enregistré dans le référentiel de Métronome."
  },
  messageErrorReordering : {
    id : "mcore.messageErrorReordering",
    defaultMessage : "Reordering could not be performed."
  },
  messageErrorConditions : {
    id : "mcore.messageErrorConditions",
    defaultMessage : "Invalid condition(s), please complete the missing field(s)"
  },
  messageSuccessConditions : {
    id : "mcore.messageSuccessConditions",
    defaultMessage : "Condition(s) validated"
  },
  notCorrectSave : {
    id : "mcore.notCorrectSave",
    defaultMessage : "Saving the model was not done correctly"
  },
  catalogOnLoad : {
    id : "mcore.catalogOnLoad",
    defaultMessage : "Catalog loading"
  },
  CatalogNotAvailable : {
    id : "mcore.CatalogNotAvailable",
    defaultMessage : "Catalog not available"
  },
  okrLoading : {
    id : "mcore.okrLoading",
    defaultMessage : "Load OKR file"
  },
  vigilance : {
    id : "mcore.vigilance",
    defaultMessage : "Vigilance"
  },
  geosCustomer : {
    id : "mcore.geos_customer",
    defaultMessage : "Customer points of interest"
  },
  nameField : {
    id : "mcore.nameField",
    defaultMessage : "Name field"
  },
  concatenatePDFProducts : {
    id : "mcore.concatenatePDFProducts",
    defaultMessage : "Concatenate PDF products"
  },
  nameConcatenatedPDFFile : {
    id : "mcore.nameConcatenatedPDFFile",
    defaultMessage : "Name of the concatenated PDF file"
  },
  text_styles : {
    id : "mcore.text_styles",
    defaultMessage : "Text styles"
  },
  table_styles : {
    id : "mcore.table_styles",
    defaultMessage : "Table styles"
  },
  tag : {
    id : "mcore.tag",
    defaultMessage : "Tag"
  },
  xmlStylesheet : {
    id : "mcore.xmlStylesheet",
    defaultMessage : "XML Stylesheet"
  },
  tag_type : {
    id : "mcore.tag_type",
    defaultMessage : "Type"
  },
  saveAs : {
    id : "mcore.saveAs",
    defaultMessage : "Save as"
  },
  editPropertiesAndSave : {
    id : "mcore.editPropertiesAndSave",
    defaultMessage : "Edit properties and save"
  },
  dropComponentshere : {
    id : "mcore.dropComponentshere",
    defaultMessage : "Drop components here"
  },
  components : {
    id : "mcore.components",
    defaultMessage : "Components"
  },
  prodVar : {
    id : "mcore.prodVar",
    defaultMessage : "Variable"
  },
  cols : {
    id : "mcore.cols",
    defaultMessage : "Columns"
  },
  fluxControls : {
    id : "mcore.fluxControls",
    defaultMessage : "Flux controls"
  },
  charts : {
    id : "mcore.charts",
    defaultMessage : "Charts"
  },
  tables : {
    id : "mcore.tables",
    defaultMessage : "Tables"
  },
  maps : {
    id : "mcore.maps",
    defaultMessage : "Maps"
  },
  formFields : {
    id : "mcore.formFields",
    defaultMessage : "Form fields"
  },
  fields : {
    id : "mcore.fields",
    defaultMessage : "Fields"
  },
  simpleText : {
    id : "mcore.simpleText",
    defaultMessage : "Simple text"
  },
  complexText : {
    id : "mcore.complexText",
    defaultMessage : "Complex text"
  },
  choice : {
    id : "mcore.choice",
    defaultMessage : "Choice"
  },
  multipleChoice : {
    id : "mcore.multipleChoice",
    defaultMessage : "Multiple Choice"
  },
  expirationDate : {
    id : "mcore.expirationDate",
    defaultMessage : "Expiration date"
  },
  dateAndTime : {
    id : "mcore.dateAndTime",
    defaultMessage : "Date and Time"
  },
  staticText : {
    id : "mcore.staticText",
    defaultMessage : "Static text"
  },
  params : {
    id : "mcore.params",
    defaultMessage : "Parameter"
  },
  paramsDateTime : {
    id : "mcore.paramsDateTime",
    defaultMessage : "Data datetime"
  },
  paramsNumeric : {
    id : "mcore.paramsNumeric",
    defaultMessage : "Data numeric"
  },
  paramsText : {
    id : "mcore.paramsText",
    defaultMessage : "Data text"
  },
  paramsJson : {
    id : "mcore.paramsJson",
    defaultMessage : "Data Json"
  },
  paramsImage : {
    id : "mcore.paramsImage",
    defaultMessage : "Data image"
  },
  defaultText : {
    id : "mcore.defaultText",
    defaultMessage : "Default text"
  },
  keepForFutureBroadcasts : {
    id : "mcore.keepForFutureBroadcasts",
    defaultMessage : "Keep for future broadcasts of the event"
  },
  logos : {
    id : "mcore.logos",
    defaultMessage : "Logos"
  },
  addRow : {
    id : "mcore.addRow",
    defaultMessage : "Add row"
  },
  newDocument : {
    id : "mcore.newDocument",
    defaultMessage : "New document"
  },
  newMap : {
    id : "mcore.newMap",
    defaultMessage : "New map"
  },
  editionMode : {
    id : "mcore.editionMode",
    defaultMessage : "Edition mode"
  },
  previewMode : {
    id : "mcore.previewMode",
    defaultMessage : "Preview mode"
  },
  editDocProperties : {
    id : "mcore.editDocProperties",
    defaultMessage : "Edit document properties"
  },
  generateDoc : {
    id : "mcore.generateDoc",
    defaultMessage : "Generate document"
  },
  editOnDrop : {
    id : "mcore.editOnDrop",
    defaultMessage : "edit on drop component"
  },
  saveDocument : {
    id : "mcore.saveDocument",
    defaultMessage : "Save document"
  },
  saveDataproduct : {
    id : "mcore.saveDataproduct",
    defaultMessage : "Save data product"
  },
  saveExpertise : {
    id : "mcore.saveExpertise",
    defaultMessage : "Save expertise"
  },
  someItemsAlreadyHaveConditions : {
    id : "mcore.someItemsAlreadyHaveConditions",
    defaultMessage : "Some items already have conditions assigned if you continue they will be overwritten."
  },
  itemsAlreadyHaveCondition : {
    id : "mcore.itemsAlreadyHaveCondition",
    defaultMessage : "Items that already have a condition"
  },
  keepSelection : {
    id : "mcore.keepSelection",
    defaultMessage : "Keep selection"
  },
  expandSelection : {
    id : "mcore.expandSelection",
    defaultMessage : "Expand selection"
  },
  choiceOfSelection : {
    id : "mcore.choiceOfSelection",
    defaultMessage : "Choice of selection"
  },
  bar : {
    id : "mcore.bar",
    defaultMessage : "Bar"
  },
  line : {
    id : "mcore.line",
    defaultMessage : "Line"
  },
  spline : {
    id : "mcore.spline",
    defaultMessage : "Spline"
  },
  area : {
    id : "mcore.area",
    defaultMessage : "Area"
  },
  areaspline : {
    id : "mcore.areaspline",
    defaultMessage : "Area Spline"
  },
  column : {
    id : "mcore.column",
    defaultMessage : "Column"
  },
  oneColumn : {
    id : "mcore.oneColumn",
    defaultMessage : "1 column"
  },
  twoColumns : {
    id : "mcore.twoColumns",
    defaultMessage : "2 columns"
  },
  threeColumns : {
    id : "mcore.threeColumns",
    defaultMessage : "3 columns"
  },
  fourColumns : {
    id : "mcore.fourColumns",
    defaultMessage : "4 columns"
  },
  forLoop : {
    id : "mcore.forLoop",
    defaultMessage : "for loop"
  },
  conditions : {
    id : "mcore.conditions",
    defaultMessage : "Conditions"
  },
  tel : {
    id : "mcore.tel",
    defaultMessage : "tel"
  },
  urls : {
    id : "mcore.urls",
    defaultMessage : "urls"
  },
  textarea : {
    id : "mcore.textarea",
    defaultMessage : "textarea"
  },
  checkbox : {
    id : "mcore.checkbox",
    defaultMessage : "Checkbox"
  },
  parametersGroup : {
    id : "mcore.parametersGroup",
    defaultMessage : "Parameters group"
  },
  showGraph : {
    id : "mcore.showGraph",
    defaultMessage : "Show graph"
  },
  showTable : {
    id : "mcore.showTable",
    defaultMessage : "Show Table"
  },
  addParameterGroup : {
    id : "mcore.addParameterGroup",
    defaultMessage : "Add a new parameter group"
  },
  previewDate : {
    id : "mcore.previewDate",
    defaultMessage : "Preview Date"
  },
  dates : {
    id : "mcore.dates",
    defaultMessage : "Dates"
  },
  time : {
    id : "mcore.time",
    defaultMessage : "Time"
  },
  time2 : {
    id : "mcore.time2",
    defaultMessage : "Time 2"
  },
  switch : {
    id : "mcore.switch",
    defaultMessage : "Switch"
  },
  staticField : {
    id : "mcore.staticField",
    defaultMessage : "static"
  },
  static : {
    id : "mcore.static",
    defaultMessage : "Static"
  },
  staticPos : {
    id : "mcore.staticPos",
    defaultMessage : "Static"
  },
  absolutePos : {
    id : "mcore.absolutePos",
    defaultMessage : "Absolute"
  },
  relation : {
    id : "mcore.relation",
    defaultMessage : "relation"
  },
  floatDocument : {
    id : "mcore.floatDocument",
    defaultMessage : "Float document"
  },
  weatherSymbols : {
    id : "mcore.weatherSymbols",
    defaultMessage : "Weather symbols"
  },
  doYouWantACopyOfConf : {
    id : "mcore.doYouWantACopyOfConf",
    defaultMessage : "Do you want to do a copy a of the product's configuration ?"
  },
  copy : {
    id : "mcore.copy",
    defaultMessage : "Copy"
  },
  cut : {
    id : "mcore.cut",
    defaultMessage : "Cut"
  },
  paste : {
    id : "mcore.paste",
    defaultMessage : "Paste"
  },
  format : {
    id : "mcore.format",
    defaultMessage : "Format"
  },
  a4portrait : {
    id : "mcore.a4portrait",
    defaultMessage : "A4 portrait"
  },
  a4landscape : {
    id : "mcore.a4landscape",
    defaultMessage : "A4 landscape"
  },
  a4portraitChartered : {
    id : "mcore.a4portraitChartered",
    defaultMessage : "A4 portrait chartered"
  },
  a4landscapeChartered : {
    id : "mcore.a4landscapeChartered",
    defaultMessage : "A4 landscape chartered"
  },
  contrast : {
    id : "mcore.contrast",
    defaultMessage : "Contrast"
  },
  none2 : {
    id : "mcore.none2",
    defaultMessage : "None"
  },
  useContrastColor : {
    id : "mcore.useContrastColor",
    defaultMessage : "Use contrast color"
  },
  background : {
    id : "mcore.background",
    defaultMessage : "Background"
  },
  padding : {
    id : "mcore.padding",
    defaultMessage : "Padding"
  },
  paddingLeft : {
    id : "mcore.paddingLeft",
    defaultMessage : "Padding left"
  },
  paddingRight : {
    id : "mcore.paddingRight",
    defaultMessage : "Padding right"
  },
  paddingTop : {
    id : "mcore.paddingTop",
    defaultMessage : "Padding top"
  },
  paddingBottom : {
    id : "mcore.paddingBottom",
    defaultMessage : "Padding bottom"
  },
  borderColor : {
    id : "mcore.borderColor",
    defaultMessage : "Border color"
  },
  borderWidth : {
    id : "mcore.borderWidth",
    defaultMessage : "Border width"
  },
  readOnlyDocument : {
    id : "mcore.readOnlyDocument",
    defaultMessage : "Read-only document"
  },
  whatDoYouWantToDo : {
    id : "mcore.whatDoYouWantToDo",
    defaultMessage : "What do you want to do ?"
  },
  openReadonly : {
    id : "mcore.openReadonly",
    defaultMessage : "Open read-only"
  },
  forceEdit : {
    id : "mcore.forceEdit",
    defaultMessage : "Force edit"
  },
  warningForceEdit : {
    id : "mcore.warningForceEdit",
    defaultMessage : "The {user} user will lose their unsaved changes."
  },
  userEditDocumentSince : {
    id : "mcore.userEditDocumentSince",
    defaultMessage : "Another user is editing the document"
  },
  productSaved : {
    id : "mcore.productSaved",
    defaultMessage : "Product saved"
  },
  documentSaved : {
    id : "mcore.documentSaved",
    defaultMessage : "Document saved"
  },
  newProduct : {
    id : "mcore.newProduct",
    defaultMessage : "New Product"
  },
  newTemplate : {
    id : "mcore.newTemplate",
    defaultMessage : "New Template"
  },
  newDataProduct : {
    id : "mcore.newDataProduct",
    defaultMessage : "New Data Product"
  },
  newJSONDataProduct : {
    id : "mcore.newJSONDataProduct",
    defaultMessage : "New JSON Product"
  },
  newTextProduct : {
    id : "mcore.newTextProduct",
    defaultMessage : "New Text Product"
  },
  newCSVDataProduct : {
    id : "mcore.newCSVDataProduct",
    defaultMessage : "New CSV Product"
  },
  newAssemblyProduct : {
    id : "mcore.newAssemblyProduct",
    defaultMessage : "New Assembly Product"
  },
  editProduct : {
    id : "mcore.editProduct",
    defaultMessage : "Edit Product"
  },
  editProdVars : {
    id : "mcore.editProdVars",
    defaultMessage : "Edit production variables"
  },
  expertiseFieldCustomer : {
    id : "mcore.expertiseFieldCustomer",
    defaultMessage : "Expertise field customer"
  },
  theme : {
    id : "mcore.theme",
    defaultMessage : "Theme"
  },
  productTheme : {
    id : "mcore.productTheme",
    defaultMessage : "Product theme"
  },
  substitution : {
    id : "mcore.substitution",
    defaultMessage : "Substitution"
  },
  substitution2 : {
    id : "mcore.substitution2",
    defaultMessage : "Substitution"
  },
  automaticMessage : {
    id : "mcore.automaticMessage",
    defaultMessage : "Log automatically generated"
  },
  example : {
    id : "mcore.example",
    defaultMessage : "Example"
  },
  red : {
    id : "mcore.red",
    defaultMessage : "Red"
  },
  blue : {
    id : "mcore.blue",
    defaultMessage : "blue"
  },
  green : {
    id : "mcore.green",
    defaultMessage : "green"
  },
  task : {
    id : "mcore.task",
    defaultMessage : "Task"
  },
  eventState : {
    id : "mcore.event_state",
    defaultMessage : "Event State"
  },
  addComponents : {
    id : "mcore.addComponents",
    defaultMessage : "Add components"
  },
  expertised : {
    id : "mcore.expertised",
    defaultMessage : "expertised"
  },
  userGroupPermission : {
    id : "mcore.userGroupPermission",
    defaultMessage : "User group permission"
  },
  expertiseSaved : {
    id : "mcore.expertiseSaved",
    defaultMessage : "Expertise saved"
  },
  simpleLineText : {
    id : "mcore.simpleLineText",
    defaultMessage : "simple line text"
  },
  multiLineText : {
    id : "mcore.multiLineText",
    defaultMessage : "multi\nline\ntext"
  },
  note : {
    id : "mcore.note",
    defaultMessage : "Annotation"
  },
  textToExpertise : {
    id : "mcore.textToExpertise",
    defaultMessage : "text to expertise"
  },
  fontSize : {
    id : "mcore.fontSize",
    defaultMessage : "Font size"
  },
  editorTitle : {
    id : "mcore.editorTitle",
    defaultMessage : "Form Editor"
  },
  has_expertise : {
    id : "mcore.has_expertise",
    defaultMessage : "Production with expertise"
  },
  overloadProductVar : {
    id : "mcore.overloadProductVar",
    defaultMessage : "Product variable overload"
  },
  overloadExpertiseVar : {
    id : "mcore.overloadExpertiseVar",
    defaultMessage : "Expertise variable overload"
  },
  template : {
    id : "mcore.template",
    defaultMessage : "Template"
  },
  form : {
    id : "mcore.form",
    defaultMessage : "Form"
  },
  saveProduct : {
    id : "mcore.saveProduct",
    defaultMessage : "Save product"
  },
  saveTemplate : {
    id : "mcore.saveTemplate",
    defaultMessage : "Save template"
  },
  num : {
    id : "mcore.num",
    defaultMessage : "N°"
  },
  placeholderNumber : {
    id : "mcore.placeholderNumber",
    defaultMessage : "Automatically generated if empty"
  },
  fieldDbName : {
    id : "mcore.field_dbname",
    defaultMessage : "Fieldname in Database"
  },
  templateHelp : {
    id : "mcore.templateHelp",
    defaultMessage :
      "Use CTRL + ESPACE to trigger autocompletion. You can also use drag & drop on buttons to the editor."
  },
  listFields : {
    id : "mcore.listFields",
    defaultMessage : "List Fields"
  },
  listExpertises : {
    id : "mcore.listExpertises",
    defaultMessage : "List of expertises"
  },
  templateListField : {
    id : "mcore.templateListField",
    defaultMessage : "List of available fields"
  },
  snippets : {
    id : "mcore.snippets",
    defaultMessage : "Snippets"
  },
  expertisePreview : {
    id : "mcore.expertisePreview",
    defaultMessage : "Preview expertise"
  },
  section : {
    id : "mcore.section",
    defaultMessage : "Section"
  },
  conditionalRendering : {
    id : "mcore.conditionalRendering",
    defaultMessage : "Conditional rendering"
  },
  conditionalRenderingHelp : {
    id : "mcore.conditionalRenderingHelp",
    defaultMessage : "The form section will be displayed only if another field has the value specified below."
  },
  dependentField : {
    id : "mcore.dependentField",
    defaultMessage : "Dependent field"
  },
  dependentFieldValue : {
    id : "mcore.dependentFieldValue",
    defaultMessage : "Value"
  },
  addSection : {
    id : "mcore.addSection",
    defaultMessage : "Add section"
  },
  sectionSettings : {
    id : "mcore.sectionSettings",
    defaultMessage : "Section settings"
  },
  sectionRemove : {
    id : "mcore.sectionRemove",
    defaultMessage : "Section remove"
  },
  field : {
    id : "mcore.field",
    defaultMessage : "Field"
  },
  newField : {
    id : "mcore.newField",
    defaultMessage : "New field"
  },
  newGroup : {
    id : "mcore.newGroup",
    defaultMessage : "New group"
  },
  required : {
    id : "mcore.required",
    defaultMessage : "Required"
  },
  values : {
    id : "mcore.values",
    defaultMessage : "Values"
  },
  helpText : {
    id : "mcore.helpText",
    defaultMessage : "Help text"
  },
  placeholder : {
    id : "mcore.placeholder",
    defaultMessage : "Placeholder"
  },
  minLength : {
    id : "mcore.minLength",
    defaultMessage : "Min length"
  },
  maxLength : {
    id : "mcore.maxLength",
    defaultMessage : "Max length"
  },
  row : {
    id : "mcore.row",
    defaultMessage : "Row"
  },
  nbRows : {
    id : "mcore.nbRows",
    defaultMessage : "Number of rows"
  },
  rows : {
    id : "mcore.rows",
    defaultMessage : "Rows"
  },
  creatable : {
    id : "mcore.creatable",
    defaultMessage : "Creatable"
  },
  clearable : {
    id : "mcore.clearable",
    defaultMessage : "Clearable"
  },
  multi : {
    id : "mcore.multi",
    defaultMessage : "Multiple choice"
  },
  button : {
    id : "mcore.button",
    defaultMessage : "Button"
  },
  addValue : {
    id : "mcore.addValue",
    defaultMessage : "Add value"
  },
  initialValue : {
    id : "mcore.initialValue",
    defaultMessage : "Initial value"
  },
  changedField : {
    id : "mcore.changedField",
    defaultMessage : "Changed field"
  },
  valueToSet : {
    id : "mcore.valueToSet",
    defaultMessage : "Value to set"
  },
  addAction : {
    id : "mcore.addAction",
    defaultMessage : "Add action"
  },
  reset : {
    id : "mcore.reset",
    defaultMessage : "Reset"
  },
  modelCustomer : {
    id : "mcore.modelCustomer",
    defaultMessage : "Customer"
  },
  Customer : {
    id : "mcore.Customer",
    defaultMessage : "Customer"
  },
  customerContact : {
    id : "mcore.customerContact",
    defaultMessage : "Customer contact"
  },
  customerContactAddress : {
    id : "mcore.customerContactAddress",
    defaultMessage : "Customer contact address"
  },
  refreshPreview : {
    id : "mcore.refreshPreview",
    defaultMessage : "Refresh preview"
  },
  series : {
    id : "mcore.series",
    defaultMessage : "Series"
  },
  xaxis : {
    id : "mcore.xaxis",
    defaultMessage : "xAxis"
  },
  yaxis : {
    id : "mcore.yaxis",
    defaultMessage : "yAxis"
  },
  subtitle : {
    id : "mcore.subtitle",
    defaultMessage : "SubTitle"
  },
  langAndDecimalOperator : {
    id : "mcore.langAndDecimalOperator",
    defaultMessage : "Language and decimal operator"
  },
  comma : {
    id : "mcore.comma",
    defaultMessage : "Comma"
  },
  dot : {
    id : "mcore.dot",
    defaultMessage : "Dot"
  },
  middle : {
    id : "mcore.middle",
    defaultMessage : "Middle"
  },
  verticalAlign : {
    id : "mcore.verticalAlign",
    defaultMessage : "Vertical alignment"
  },
  horizontalAlign : {
    id : "mcore.horizontalAlign",
    defaultMessage : "Horizontal alignment"
  },
  align : {
    id : "mcore.align",
    defaultMessage : "Align"
  },
  decimalPoint : {
    id : "mcore.decimalPoint",
    defaultMessage : "DecimalPoint"
  },
  titleDescription : {
    id : "mcore.titleDescription",
    defaultMessage : "Title & Description"
  },
  floating : {
    id : "mcore.floating",
    defaultMessage : "Floating"
  },
  data : {
    id : "mcore.data",
    defaultMessage : "Data"
  },
  datadatetime : {
    id : "mcore.datadatetime",
    defaultMessage : "DateTime data"
  },
  datanumeric : {
    id : "mcore.datanumeric",
    defaultMessage : "Numerical data"
  },
  operationDatanumeric : {
    id : "mcore.operationDatanumeric",
    defaultMessage : "Operation Numerical data"
  },
  datatext : {
    id : "mcore.datatext",
    defaultMessage : "Text data"
  },
  dataimage : {
    id : "mcore.dataimage",
    defaultMessage : "Image data"
  },
  destination : {
    id : "mcore.destination",
    defaultMessage : "Destination"
  },
  addserie : {
    id : "mcore.addserie",
    defaultMessage : "Add serie"
  },
  serie : {
    id : "mcore.serie",
    defaultMessage : "Serie"
  },
  serieParameter : {
    id : "mcore.serieParameter",
    defaultMessage : "Serie parameter"
  },
  newserie : {
    id : "mcore.newserie",
    defaultMessage : "New serie"
  },
  position : {
    id : "mcore.position",
    defaultMessage : "Position"
  },
  geoposition : {
    id : "mcore.geoposition",
    defaultMessage : "Geographical position"
  },
  negativecolor : {
    id : "mcore.negativecolor",
    defaultMessage : "negativeColor"
  },
  colorForNegativeValues : {
    id : "mcore.colorForNegativeValues",
    defaultMessage : "Color for negative values"
  },
  colorForPositiveValues : {
    id : "mcore.colorForPositiveValues",
    defaultMessage : "Color for positive values"
  },
  warningSubstitutionColorPrevailsOverColor : {
    id : "mcore.warningSubstitutionColorPrevailsOverColor",
    defaultMessage : "Warning, the substitution color prevails over this color"
  },
  datalabels : {
    id : "mcore.datalabels",
    defaultMessage : "datalabels"
  },
  lineoptions : {
    id : "mcore.lineoptions",
    defaultMessage : "Line Options"
  },
  linewidth : {
    id : "mcore.linewidth",
    defaultMessage : "Line Width"
  },
  dashStyle : {
    id : "mcore.dashStyle",
    defaultMessage : "DashStyle"
  },
  symbol : {
    id : "mcore.symbol",
    defaultMessage : "Symbol"
  },
  circle : {
    id : "mcore.circle",
    defaultMessage : "Circle"
  },
  square : {
    id : "mcore.square",
    defaultMessage : "Square"
  },
  triangle : {
    id : "mcore.triangle",
    defaultMessage : "Triangle"
  },
  triangledown : {
    id : "mcore.triangledown",
    defaultMessage : "Triangle-Down"
  },
  diamond : {
    id : "mcore.diamond",
    defaultMessage : "Diamond"
  },
  Solid : {
    id : "mcore.Solid",
    defaultMessage : "Solid"
  },
  ShortDash : {
    id : "mcore.ShortDash",
    defaultMessage : "ShortDash"
  },
  ShortDot : {
    id : "mcore.ShortDot",
    defaultMessage : "ShortDot"
  },
  ShortDashDot : {
    id : "mcore.ShortDashDot",
    defaultMessage : "ShortDashDot"
  },
  ShortDashDotDot : {
    id : "mcore.ShortDashDotDot",
    defaultMessage : "ShortDashDotDot"
  },
  Dot : {
    id : "mcore.Dot",
    defaultMessage : "Dot"
  },
  Dash : {
    id : "mcore.Dash",
    defaultMessage : "Dash"
  },
  DashDot : {
    id : "mcore.DashDot",
    defaultMessage : "DashDot"
  },
  LongDash : {
    id : "mcore.LongDash",
    defaultMessage : "LongDash"
  },
  opposite : {
    id : "mcore.opposite",
    defaultMessage : "Opposite"
  },
  entity : {
    id : "mcore.entity",
    defaultMessage : "Entity"
  },
  LongDashDotDot : {
    id : "mcore.LongDashDotDot",
    defaultMessage : "LongDashDotDot"
  },
  timeranges : {
    id : "mcore.timeranges",
    defaultMessage : "Timeranges"
  },
  fontsize : {
    id : "mcore.fontsize",
    defaultMessage : "FontSize"
  },
  labels : {
    id : "mcore.labels",
    defaultMessage : "Labels"
  },
  softMin : {
    id : "mcore.softMin",
    defaultMessage : "Soft Min"
  },
  softMax : {
    id : "mcore.softMax",
    defaultMessage : "Soft Max"
  },
  second : {
    id : "mcore.second",
    defaultMessage : "Second"
  },
  year : {
    id : "mcore.year",
    defaultMessage : "Year"
  },
  ticks : {
    id : "mcore.ticks",
    defaultMessage : "Ticks"
  },
  addyaxis : {
    id : "mcore.addyaxis",
    defaultMessage : "Add yAxis"
  },
  plotbands : {
    id : "mcore.plotbands",
    defaultMessage : "PlotBands"
  },
  plotband : {
    id : "mcore.plotband",
    defaultMessage : "PlotBand"
  },
  to : {
    id : "mcore.to",
    defaultMessage : "To"
  },
  from : {
    id : "mcore.from",
    defaultMessage : "From"
  },
  addplotband : {
    id : "mcore.addplotband",
    defaultMessage : "Add PlotBand"
  },
  manual : {
    id : "mcore.manual",
    defaultMessage : "Manual"
  },
  textcolor : {
    id : "mcore.textcolor",
    defaultMessage : "Text Color"
  },
  past : {
    id : "mcore.past",
    defaultMessage : "Past"
  },
  future : {
    id : "mcore.future",
    defaultMessage : "Future"
  },
  periodselection : {
    id : "mcore.periodselection",
    defaultMessage : "Period Selection"
  },
  endtime : {
    id : "mcore.endtime",
    defaultMessage : "End Time Offset"
  },
  starttime : {
    id : "mcore.starttime",
    defaultMessage : "Start Time Offset"
  },
  steptime : {
    id : "mcore.steptime",
    defaultMessage : "Step between each offsets"
  },
  maxHeight : {
    id : "mcore.maxHeight",
    defaultMessage : "Maximum height"
  },
  maxWidth : {
    id : "mcore.maxWidth",
    defaultMessage : "Maximum width"
  },
  maxSize : {
    id : "mcore.maxSize",
    defaultMessage : "Maximum recorded image size"
  },
  minorticks : {
    id : "mcore.minorticks",
    defaultMessage : "Minor Ticks"
  },
  tickInterval : {
    id : "mcore.tickInterval",
    defaultMessage : "Interval"
  },
  hourTickInterval : {
    id : "mcore.hourTickInterval",
    defaultMessage : "Interval in hours"
  },
  tickAmount : {
    id : "mcore.tickAmount",
    defaultMessage : "Tick Amount"
  },
  length : {
    id : "mcore.length",
    defaultMessage : "Length"
  },
  tickPositions : {
    id : "mcore.tickPositions",
    defaultMessage : "Tick Positions"
  },
  plotline : {
    id : "mcore.plotline",
    defaultMessage : "Plot Line"
  },
  plotlines : {
    id : "mcore.plotlines",
    defaultMessage : "Plot Lines"
  },
  addplotline : {
    id : "mcore.addplotline",
    defaultMessage : "Add Plot Line"
  },
  minRange : {
    id : "mcore.minRange",
    defaultMessage : "Min Range"
  },
  startOnTick : {
    id : "mcore.startOnTick",
    defaultMessage : "Force Axis to start on tick"
  },
  endOnTick : {
    id : "mcore.endOnTick",
    defaultMessage : "Force Axis to end on tick"
  },
  groupPadding : {
    id : "mcore.groupPadding",
    defaultMessage : "Padding between each value groups (Column)"
  },
  pointPadding : {
    id : "mcore.pointPadding",
    defaultMessage : "Padding between each bar (Column) "
  },
  spacingTop : {
    id : "mcore.spacingTop",
    defaultMessage : "Spacing Top"
  },
  spacingBottom : {
    id : "mcore.spacingBottom",
    defaultMessage : "Spacing Bottom"
  },
  spacingLeft : {
    id : "mcore.spacingLeft",
    defaultMessage : "Spacing Left"
  },
  spacingRight : {
    id : "mcore.spacingRight",
    defaultMessage : "Spacing Right"
  },
  pointPlacement : {
    id : "mcore.pointPlacement",
    defaultMessage : "Point Placement"
  },
  on : {
    id : "mcore.on",
    defaultMessage : "On"
  },
  highchartsEditor : {
    id : "mcore.highchartsEditor",
    defaultMessage : "Charts editors"
  },
  missingDataError : {
    id : "mcore.missingDataError",
    defaultMessage : "No data"
  },
  addImage : {
    id : "mcore.addImage",
    defaultMessage : "Add image"
  },
  addText : {
    id : "mcore.addText",
    defaultMessage : "Add text"
  },
  addLegend : {
    id : "mcore.addLegend",
    defaultMessage : "Add legend"
  },
  prevPage : {
    id : "mcore.prevPage",
    defaultMessage : "Previous page"
  },
  nextPage : {
    id : "mcore.nextPage",
    defaultMessage : "Next page"
  },
  clearProduct : {
    id : "mcore.clearProduct",
    defaultMessage : "Clear product"
  },
  confirmClearProduct : {
    id : "mcore.confirmClearProduct",
    defaultMessage : "Are you sure you want to clear this product ?"
  },
  infos : {
    id : "mcore.infos",
    defaultMessage : "Infos"
  },
  layerOn : {
    id : "mcore.layerOn",
    defaultMessage : "Layer on {item}"
  },
  header : {
    id : "mcore.header",
    defaultMessage : "Header"
  },
  footer : {
    id : "mcore.footer",
    defaultMessage : "Footer"
  },
  overlays : {
    id : "mcore.overlays",
    defaultMessage : "Overlays"
  },
  productName : {
    id : "mcore.productName",
    defaultMessage : "Product name"
  },
  productDescript : {
    id : "mcore.productDescript",
    defaultMessage : "Product description"
  },
  addLayer : {
    id : "mcore.addLayer",
    defaultMessage : "Add Layer"
  },
  style : {
    id : "mcore.style",
    defaultMessage : "Style"
  },
  timeline : {
    id : "mcore.timeline",
    defaultMessage : "Timeline"
  },
  timelineType : {
    id : "mcore.timelineType",
    defaultMessage : "Timeline type"
  },
  displayHeader : {
    id : "mcore.displayHeader",
    defaultMessage : "Display header"
  },
  displayFooter : {
    id : "mcore.displayFooter",
    defaultMessage : "Display footer"
  },
  displayBackground : {
    id : "mcore.displayBackground",
    defaultMessage : "Opaque Map background"
  },
  separators : {
    id : "mcore.separators",
    defaultMessage : "Separators"
  },
  addSeparator : {
    id : "mcore.addSeparator",
    defaultMessage : "Add separator"
  },
  removeSeparator : {
    id : "mcore.removeSeparator",
    defaultMessage : "Remove separator"
  },
  origin : {
    id : "mcore.origin",
    defaultMessage : "Origin"
  },
  verticalOrigin : {
    id : "mcore.verticalOrigin",
    defaultMessage : "Vertical origin"
  },
  horizontalOrigin : {
    id : "mcore.horizontalOrigin",
    defaultMessage : "Horizontal origin"
  },
  content : {
    id : "mcore.content",
    defaultMessage : "Content"
  },
  textContent : {
    id : "mcore.textContent",
    defaultMessage : "Text content"
  },
  confirmPrivateDomain : {
    id : "mcore.confirmPrivateDomain",
    defaultMessage : "You are going to lose your custom domain. Do you really want to continue ?"
  },
  customDomain : {
    id : "mcore.customDomain",
    defaultMessage : "Custom domain"
  },
  domainType : {
    id : "mcore.domainType",
    defaultMessage : "Domain type"
  },
  downloadProduct : {
    id : "mcore.downloadProduct",
    defaultMessage : "Download product"
  },
  prodvars : {
    id : "mcore.prodvars",
    defaultMessage : "ProdVars"
  },
  prodvarsDescript : {
    id : "mcore.prodvarsDescript",
    defaultMessage : "Define the parameters that can be dynamically overloaded in production."
  },
  output_format : {
    id : "mcore.outputformat",
    defaultMessage : "Output Format"
  },
  elevationWarning : {
    id : "mcore.elevationWarning",
    defaultMessage : `Look out, the changes will generate a reset of the elevation previously set in the product.
 Are you sure you want to continue ?`
  },
  newDomain : {
    id : "mcore.new_domain",
    defaultMessage : "New Domain"
  },
  productCategory : {
    id : "mcore.productCategory",
    defaultMessage : "Category"
  },
  productDescription : {
    id : "mcore.productDescription",
    defaultMessage : "Description"
  },
  savedProduct : {
    id : "mcore.savedProduct",
    defaultMessage : "Product saved with success"
  },
  chart : {
    id : "mcore.chart",
    defaultMessage : "Chart"
  },
  addChart : {
    id : "mcore.addChart",
    defaultMessage : "Add chart"
  },
  alignColumns : {
    id : "mcore.alignColumns",
    defaultMessage : "Align columns"
  },
  proximate : {
    id : "mcore.proximate",
    defaultMessage : "Proximate"
  },
  layoutLegendItems : {
    id : "mcore.layoutLegendItems",
    defaultMessage : "Layout of items"
  },
  margin : {
    id : "mcore.margin",
    defaultMessage : "Margin"
  },
  applyShadow : {
    id : "mcore.applyShadow",
    defaultMessage : "Apply a shadow"
  },
  reverseOrderElements : {
    id : "mcore.reverseOrderElements",
    defaultMessage : "Reverse the order of the elements"
  },
  distanceBetweenEachItem : {
    id : "mcore.distanceBetweenEachItem",
    defaultMessage : "Distance between each item"
  },
  itemMarginBottom : {
    id : "mcore.itemMarginBottom",
    defaultMessage : "Item margin bottom"
  },
  itemMarginTop : {
    id : "mcore.itemMarginTop",
    defaultMessage : "Item margin top"
  },
  itemWidth : {
    id : "mcore.itemWidth",
    defaultMessage : "Item width"
  },
  paddingBetweenSymbolAndText : {
    id : "mcore.paddingBetweenSymbolAndText",
    defaultMessage : "Padding between symbol and text"
  },
  borderRadiusSymbol : {
    id : "mcore.borderRadiusSymbol",
    defaultMessage : "Border radius of the symbol"
  },
  marginBetweenTitleAndPlotArea : {
    id : "mcore.marginBetweenTitleAndPlotArea",
    defaultMessage : "Margin between the title and the plot area"
  },
  marginLeft : {
    id : "mcore.marginLeft",
    defaultMessage : "Margin left"
  },
  marginRight : {
    id : "mcore.marginRight",
    defaultMessage : "Margin right"
  },
  marginTop : {
    id : "mcore.marginTop",
    defaultMessage : "Margin top"
  },
  marginBottom : {
    id : "mcore.marginBottom",
    defaultMessage : "Margin bottom"
  },
  infoDataLabelsRotation : {
    id : "mcore.infoDataLabelsRotation",
    defaultMessage : "On a rotated data label, backgrounds, borders, and padding will be lost."
  },
  infoMarginBetweenTitleAndPlotArea : {
    id : "mcore.infoMarginBetweenTitleAndPlotArea",
    defaultMessage : "The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area."
  },
  infoSymbolRadius : {
    id : "mcore.infoSymbolRadius",
    defaultMessage : "The border radius of the symbol for series types that use a rectangle in the legend. The default is half its height."
  },
  infoSquareSymbolLegend : {
    id : "mcore.infoSquareSymbolLegend",
    defaultMessage : "When this is true, the legend symbol width will be the same as the symbol height, which in turn defaults to the font size of the legend items."
  },
  infoLayoutLegendItems : {
    id : "mcore.infoLayoutLegendItems",
    defaultMessage : "When proximate, the legend items will be placed as close as possible to the graphs they're representing, except in inverted charts or when the legend position doesn't allow it."
  },
  infoVerticalAlignLegend : {
    id : "mcore.infoVerticalAlignLegend",
    defaultMessage : "Doesn't apply when the layout of the legend items is proximate."
  },
  borderRadius : {
    id : "mcore.borderRadius",
    defaultMessage : "Border radius"
  },
  seriesProduct : {
    id : "mcore.seriesProduct",
    defaultMessage : "Series Product"
  },
  use_same_analysis_time : {
    id : "mcore.use_same_analysis_time",
    defaultMessage : "Use same analysis time"
  },
  due_date : {
    id : "mcore.due_date",
    defaultMessage : "Due date"
  },
  table : {
    id : "mcore.table",
    defaultMessage : "Table"
  },
  tab : {
    id : "mcore.tab",
    defaultMessage : "Tab"
  },
  tabs : {
    id : "mcore.tabs",
    defaultMessage : "Tabs"
  },
  tabName : {
    id : "mcore.tabName",
    defaultMessage : "Tab name"
  },
  font : {
    id : "mcore.font",
    defaultMessage : "Font"
  },
  themeFont : {
    id : "mcore.themeFont",
    defaultMessage : "Theme font"
  },
  border : {
    id : "mcore.border",
    defaultMessage : "Border"
  },
  cell : {
    id : "mcore.cell",
    defaultMessage : "Cell"
  },
  padd : {
    id : "mcore.padd",
    defaultMessage : "Padd"
  },
  textAlign : {
    id : "mcore.textAlign",
    defaultMessage : "Text align"
  },
  textCase : {
    id : "mcore.textCase",
    defaultMessage : "Text case"
  },
  borderCollapse : {
    id : "mcore.borderCollapse",
    defaultMessage : "Border Collapse"
  },
  bold : {
    id : "mcore.bold",
    defaultMessage : "Bold"
  },
  italic : {
    id : "mcore.italic",
    defaultMessage : "Italic"
  },
  underline : {
    id : "mcore.underline",
    defaultMessage : "Underline"
  },
  borders : {
    id : "mcore.borders",
    defaultMessage : "Borders"
  },
  headerX : {
    id : "mcore.headerX",
    defaultMessage : "X-axis"
  },
  headerY : {
    id : "mcore.headerY",
    defaultMessage : "Y-axis"
  },
  HStripes : {
    id : "mcore.HStripes",
    defaultMessage : "H Stripes"
  },
  ColLin : {
    id : "mcore.ColLin",
    defaultMessage : "Column lines"
  },
  RowLin : {
    id : "mcore.RowLin",
    defaultMessage : "Row lines"
  },
  tableEditor : {
    id : "mcore.tableEditor",
    defaultMessage : "Table editor"
  },
  dataSelect : {
    id : "mcore.dataSelect",
    defaultMessage : "Data select"
  },
  axeX : {
    id : "mcore.axeX",
    defaultMessage : "Lines"
  },
  axeXValues : {
    id : "mcore.axeXValues",
    defaultMessage : "Lines values"
  },
  axeY : {
    id : "mcore.axeY",
    defaultMessage : "Columns"
  },
  axeYValues : {
    id : "mcore.axeYValues",
    defaultMessage : "Columns values"
  },
  selectedChoiceTheme : {
    id : "mcore.selectedChoiceTheme",
    defaultMessage : "Type of table style"
  },
  customStyle : {
    id : "mcore.customStyle",
    defaultMessage : "custom style"
  },
  definedStyle : {
    id : "mcore.definedStyle",
    defaultMessage : "defined style"
  },
  defined : {
    id : "mcore.defined",
    defaultMessage : "Defined theme"
  },
  addParameter : {
    id : "mcore.addParameter",
    defaultMessage : "Add parameter"
  },
  addPoint : {
    id : "mcore.addPoint",
    defaultMessage : "Add point"
  },
  createPoint : {
    id : "mcore.createPoint",
    defaultMessage : "Création d'un point"
  },
  content_type : {
    id : "mcore.content_type",
    defaultMessage : "Model"
  },
  newExtrafield : {
    id : "mcore.newExtrafield",
    defaultMessage : "New"
  },
  extrafieldSaved : {
    id : "mcore.extrafieldSaved",
    defaultMessage : "Extrafield saved"
  },
  editModel : {
    id : "mcore.editModel",
    defaultMessage : "Edit"
  },
  catalogDescription : {
    id : "mcore.catalogDescription",
    defaultMessage : "Drag and drop a type of field in a form group to add a new field."
  },
  conditionalDescription : {
    id : "mcore.conditionalDescription",
    defaultMessage : "The form group will be displayed only if another field has the values specified below."
  },
  conditionValue : {
    id : "mcore.conditionValue",
    defaultMessage : "Value"
  },
  groupSettings : {
    id : "mcore.groupSettings",
    defaultMessage : "Group settings"
  },
  removeGroup : {
    id : "mcore.removeGroup",
    defaultMessage : "Remove group"
  },
  moveGroupUp : {
    id : "mcore.moveGroupUp",
    defaultMessage : "Move group up"
  },
  moveGroupDown : {
    id : "mcore.moveGroupDown",
    defaultMessage : "Move group down"
  },
  latitude : {
    id : "mcore.latitude",
    defaultMessage : "latitude"
  },
  longitude : {
    id : "mcore.longitude",
    defaultMessage : "longitude"
  },
  point : {
    id : "mcore.point",
    defaultMessage : "Point"
  },
  polygon : {
    id : "mcore.polygon",
    defaultMessage : "Polygon"
  },
  createBeforeEditing : {
    id : "mcore.createBeforeEditing",
    defaultMessage : "Save the distribution to enable this part"
  },
  customerTitle : {
    id : "mcore.customerTitle",
    defaultMessage : "Customer list / subscriptions"
  },
  customerEditTitle : {
    id : "mcore.customerEditTitle",
    defaultMessage : "New customer / subscription"
  },
  productTitle : {
    id : "mcore.productTitle",
    defaultMessage : "Product models list"
  },
  productEditTitle : {
    id : "mcore.productEditTitle",
    defaultMessage : "Add a product model"
  },
  distributionHandler : {
    id : "mcore.distributionHandler",
    defaultMessage : "Distribution handler"
  },
  distributionTitle : {
    id : "mcore.distributionTitle",
    defaultMessage : "Distributions list"
  },
  distributionEditTitle : {
    id : "mcore.distributionEditTitle",
    defaultMessage : "Add a distribution"
  },
  triggerTitle : {
    id : "mcore.triggerTitle",
    defaultMessage : "Triggers list"
  },
  triggerEditTitle : {
    id : "mcore.triggerEditTitle",
    defaultMessage : "Add a trigger"
  },
  info : {
    id : "mcore.info",
    defaultMessage : "Configuration"
  },
  optionsField : {
    id : "mcore.options",
    defaultMessage : "Options"
  },
  fieldProduct : {
    id : "mcore.modelProduct",
    defaultMessage : "Model Product"
  },
  productId : {
    id : "mcore.productId",
    defaultMessage : "Model Product id: "
  },
  fieldConfiguration : {
    id : "mcore.fieldConfiguration",
    defaultMessage : "Field configuration"
  },
  substitutionConfiguration : {
    id : "mcore.substitutionConfiguration",
    defaultMessage : "Substitution configuration"
  },
  configPossibleBeforeInsert : {
    id : "mcore.configPossibleBeforeInsert",
    defaultMessage : "Configuration is possible before insertion"
  },
  for : {
    id : "mcore.for",
    defaultMessage : " for"
  },
  taskTablePreferences : {
    id : "mcore.taskTablePreferences",
    defaultMessage : "Task table preference"
  },
  propsName : {
    id : "mcore.propsName",
    defaultMessage : "Object properties name"
  },
  property : {
    id : "mcore.property",
    defaultMessage : "Property"
  },
  start_time_duration : {
    id : "mcore.start_time_duration",
    defaultMessage : "Start time"
  },
  alert_time_duration : {
    id : "mcore.alert_time_duration",
    defaultMessage : "Alert time"
  },
  due_time_duration : {
    id : "mcore.due_time_duration",
    defaultMessage : "Due time"
  },
  end_time_duration : {
    id : "mcore.end_time_duration",
    defaultMessage : "End time"
  },
  start_time_explanation : {
    id : "mcore.start_time_explanation",
    defaultMessage : "User can start working on the task"
  },
  alert_time_explanation : {
    id : "mcore.alert_time_explanation",
    defaultMessage : "Task is marked as urgent in Dashboard"
  },
  due_time_explanation : {
    id : "mcore.due_time_explanation",
    defaultMessage : "Task must be done for this time"
  },
  end_time_explanation : {
    id : "mcore.end_time_explanation",
    defaultMessage : "After this time, the task is cancelled (a rescue task can start)"
  },
  alert_time_duration_explanation : {
    id : "mcore.alert_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>ALERT</b> and <b>DUE</b> time"
  },
  due_time_duration_explanation : {
    id : "mcore.due_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>START</b> and <b>DUE</b> time"
  },
  production_time_duration_explanation : {
    id : "mcore.production_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>PRODUCTION</b> and <b>DUE</b> time"
  },
  end_time_duration_explanation : {
    id : "mcore.end_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>START</b> and <b>END</b> time"
  },
  is_datechange_allowed : {
    id : "mcore.is_datechange_allowed",
    defaultMessage : "is datechange allowed"
  },
  rruleText : {
    id : "mcore.rruleText",
    defaultMessage : "Recurrence"
  },
  rrule : {
    id : "mcore.rrule",
    defaultMessage : "Rule"
  },
  triggerJour : {
    id : "mcore.triggerJour",
    defaultMessage : "day"
  },
  triggerJours : {
    id : "mcore.triggerJours",
    defaultMessage : "days"
  },
  triggerStart : {
    id : "mcore.triggerStart",
    defaultMessage : "Date start"
  },
  triggerEnd : {
    id : "mcore.triggerEnd",
    defaultMessage : "Date end"
  },
  everyDays : {
    id : "mcore.everyDays",
    defaultMessage : "Every day"
  },
  everyMonths : {
    id : "mcore.everyMonths",
    defaultMessage : "Every month"
  },
  daysBeforeEndOfMonth : {
    id : "mcore.daysBeforeEndOfMonth",
    defaultMessage : "days before end of month"
  },
  daysOfWeek : {
    id : "mcore.daysOfWeek",
    defaultMessage : "Week days (select one or more)"
  },
  daysOfMonth : {
    id : "mcore.daysOfMonth",
    defaultMessage : "Month days (select one or most day)"
  },
  monthsOfYear : {
    id : "mcore.monthsOfYear",
    defaultMessage : "Year months (select one or more)"
  },
  ofMonth : {
    id : "mcore.ofMonth",
    defaultMessage : "of month"
  },
  theLast : {
    id : "mcore.theLast",
    defaultMessage : "The last"
  },
  the : {
    id : "mcore.the",
    defaultMessage : "The"
  },
  holidays : {
    id : "mcore.holidays",
    defaultMessage : "Exclude holidays"
  },
  beforeHolidays : {
    id : "mcore.beforeHolidays",
    defaultMessage : "Only the eves of holidays"
  },
  everyMP : {
    id : "mcore.everyMP",
    defaultMessage : "Every"
  },
  everyFP : {
    id : "mcore.everyFP",
    defaultMessage : "Every"
  },
  days : {
    id : "mcore.days",
    defaultMessage : "days"
  },
  months : {
    id : "mcore.months",
    defaultMessage : "months"
  },
  actualSelection : {
    id : "mcore.actualSelection",
    defaultMessage : "Selection"
  },
  times : {
    id : "mcore.times",
    defaultMessage : "Times (hh:mm)"
  },
  minutely : {
    id : "mcore.minutely",
    defaultMessage : "Minutely"
  },
  hourly : {
    id : "mcore.hourly",
    defaultMessage : "Hourly"
  },
  daily : {
    id : "mcore.daily",
    defaultMessage : "Daily"
  },
  monthly : {
    id : "mcore.monthly",
    defaultMessage : "Monthly"
  },
  first : {
    id : "mcore.first",
    defaultMessage : "first"
  },
  third : {
    id : "mcore.third",
    defaultMessage : "third"
  },
  fourth : {
    id : "mcore.fourth",
    defaultMessage : "fourth"
  },
  reccurence : {
    id : "mcore.reccurence",
    defaultMessage : "Reccurence"
  },
  reccurences : {
    id : "mcore.reccurences",
    defaultMessage : "Reccurences"
  },
  frequencies : {
    id : "mcore.frequencies",
    defaultMessage : "Frequencies"
  },
  listOfMinutes : {
    id : "mcore.listOfMinutes",
    defaultMessage : "Minutes (select one or more)"
  },
  listOfHours : {
    id : "mcore.listOfHours",
    defaultMessage : "Hours (select one or more)"
  },
  betweenThe : {
    id : "mcore.betweenThe",
    defaultMessage : "Between the"
  },
  andThe : {
    id : "mcore.andThe",
    defaultMessage : "and the"
  },
  minuteshours : {
    id : "mcore.minuteshours",
    defaultMessage : "Hours/Minutes"
  },
  messageHoursInGMT : {
    id : "mcore.messageHoursInGMT",
    defaultMessage : "Warning, times are in GMT"
  },
  confirmLaunchItem : {
    id : "mcore.confirm_launch_item",
    defaultMessage : "Are you sure you want to launch this production ?"
  },
  launchSuccess : {
    id : "mcore.launch_success",
    defaultMessage : "Item successfully launched"
  },
  productsConfiguration : {
    id : "mcore.productsConfiguration",
    defaultMessage : "Products configuration"
  },
  productConfiguration : {
    id : "mcore.productConfiguration",
    defaultMessage : "Product configuration"
  },
  typeOfTrigger : {
    id : "mcore.type-of-trigger",
    defaultMessage : "Type of trigger"
  },
  shareable : {
    id : "mcore.shareable",
    defaultMessage : "Shareable"
  },
  taskType : {
    id : "mcore.taskType",
    defaultMessage : "Task type"
  },
  manualTaskTriggerModel : {
    id : "mcore.manualTaskTriggerModel",
    defaultMessage : "Manual task trigger"
  },
  lock_distribution : {
    id : "mcore.lock_distribution",
    defaultMessage : "Locked"
  },
  applicationOrder : {
    id : "mcore.applicationOrder",
    defaultMessage : "The rules are processed in order: the first applicable rule will be used (only)"
  },
  includeDistributions : {
    id : "mcore.includeDistributions",
    defaultMessage : "include distributions"
  },
  includeSubscriptions : {
    id : "mcore.includeSubscriptions",
    defaultMessage : "include subscriptions"
  },
  includeTriggers : {
    id : "mcore.includeTriggers",
    defaultMessage : "include triggers"
  },
  includeCustomers : {
    id : "mcore.includeCustomers",
    defaultMessage : "include customers"
  },
  fieldsToImport : {
    id : "mcore.fieldsToImport",
    defaultMessage : "fields to import"
  },
  selectAllOptions : {
    id : "mcore.selectAllOptions",
    defaultMessage : "select all"
  },
  selectCustomer : {
    id : "mcore.selectCustomer",
    defaultMessage : "Select customer"
  },
  rescueDashboardTaskConfig : {
    id : "mcore.rescueDashboardTaskConfig",
    defaultMessage : "Rescue configuration"
  },
  rescue : {
    id : "mcore.rescue",
    defaultMessage : "Rescue"
  },
  productionName : {
    id : "mcore.productionName",
    defaultMessage : "Production Name"
  },
  productionNumber : {
    id : "mcore.productionNumber",
    defaultMessage : "Production Number"
  },
  customersAndSubscriptions : {
    id : "mcore.customersAndSubscriptions",
    defaultMessage : "Customers & subscriptions"
  },
  customerSubscription : {
    id : "mcore.customer-subscription",
    defaultMessage : "Customer/Subscription"
  },
  parentCategory : {
    id : "mcore.parent",
    defaultMessage : "Parent category"
  },
  nameTooShort : {
    id : "mcore.name_too_short",
    defaultMessage : "Name is too short"
  },
  productType : {
    id : "mcore.productType",
    defaultMessage : "Product Type"
  },
  noProductFound : {
    id : "mcore.no_product_found",
    defaultMessage : "No product found"
  },
  rules : {
    id : "mcore.rules",
    defaultMessage : "Substitution rule"
  },
  rule : {
    id : "mcore.rule",
    defaultMessage : "Rule"
  },
  textfr : {
    id : "mcore.textfr",
    defaultMessage : "Text FR"
  },
  texten : {
    id : "mcore.texten",
    defaultMessage : "Text EN"
  },
  variationPositive : {
    id : "mcore.variationPositive",
    defaultMessage : "increase greater than"
  },
  variationNegative : {
    id : "mcore.variationNegative",
    defaultMessage : "decrease greater than"
  },
  modalTitle : {
    id : "mcore.modalTitle",
    defaultMessage : "Configuration of substitution's palette"
  },
  selectParameter : {
    id : "mcore.selectParameter",
    defaultMessage : "Select one or more parameters"
  },
  valid : {
    id : "mcore.valid",
    defaultMessage : "Submit"
  },
  distribsSupervision : {
    id : "mcore.distribsSupervision",
    defaultMessage : "Distributions supervision"
  },
  recipientDescription : {
    id : "mcore.recipientDescription",
    defaultMessage : "Recipient"
  },
  sendDate : {
    id : "mcore.sendDate",
    defaultMessage : "Send date"
  },
  productionTaskId : {
    id : "mcore.productionTaskId",
    defaultMessage : "Production task id"
  },
  distributionTaskId : {
    id : "mcore.distributionTaskId",
    defaultMessage : "Distribution task id"
  },
  start : {
    id : "mcore.start",
    defaultMessage : "Start"
  },
  end : {
    id : "mcore.end",
    defaultMessage : "End"
  },
  hasUpdated : {
    id : "mcore.hasUpdated",
    defaultMessage : "Updated"
  },
  hasCreated : {
    id : "mcore.hasCreated",
    defaultMessage : "Created"
  },
  hasDeleted : {
    id : "mcore.hasDeleted",
    defaultMessage : "Deleted"
  },
  expertiseTask : {
    id : "mcore.expertiseTask",
    defaultMessage : "Expertise Task"
  },
  generationTask : {
    id : "mcore.generationTask",
    defaultMessage : "Generation Task"
  },
  baseTask : {
    id : "mcore.baseTask",
    defaultMessage : "Base Task"
  },
  distributionTask : {
    id : "mcore.distributionTask",
    defaultMessage : "Distribution Task"
  },
  tasksSupervision : {
    id : "mcore.tasksSupervision",
    defaultMessage : "Tasks supervision"
  },
  startDateTime : {
    id : "mcore.startDateTime",
    defaultMessage : "Start datetime"
  },
  generationDuration : {
    id : "mcore.generationDuration",
    defaultMessage : "Duration"
  },
  taskDuration : {
    id : "mcore.taskDuration",
    defaultMessage : "Task duration (in minutes)"
  },
  lastUpdateDateTime : {
    id : "mcore.lastUpdateDateTime",
    defaultMessage : "Last update"
  },
  triggerType : {
    id : "mcore.triggerType",
    defaultMessage : "Trigger type"
  },
  distributionName : {
    id : "mcore.distributionName",
    defaultMessage : "Distribution name"
  },
  generations : {
    id : "mcore.generations",
    defaultMessage : "Generations"
  },
  generationCount : {
    id : "mcore.generationCount",
    defaultMessage : "{count, number} {count, plural, one {generation} other {generations} }"
  },
  distributionCount : {
    id : "mcore.distributionCount",
    defaultMessage : "{count, number} {count, plural, one {distribution} other {distributions} }"
  },
  scheduled : {
    id : "mcore.scheduled",
    defaultMessage : "Scheduled"
  },
  onlyPastDates : {
    id : "mcore.only_past_dates",
    defaultMessage : "Past dates only"
  },
  letterSpacing : {
    id : "mcore.letterSpacing",
    defaultMessage : "Letter spacing"
  },
  wordSpacing : {
    id : "mcore.wordSpacing",
    defaultMessage : "Word spacing"
  },
  decoration : {
    id : "mcore.decoration",
    defaultMessage : "Decoration"
  },
  variant : {
    id : "mcore.variant",
    defaultMessage : "Variant"
  },
  transform : {
    id : "mcore.transform",
    defaultMessage : "Transform"
  },
  noProduct : {
    id : "mcore.noproduct",
    defaultMessage : "No selected product"
  },
  addProduct : {
    id : "mcore.addproduct",
    defaultMessage : "Add a product"
  },
  selectProduct : {
    id : "mcore.selectProduct",
    defaultMessage : "Select the product to add ..."
  },
  noRecipient : {
    id : "mcore.norecipient",
    defaultMessage : "No selected recipient"
  },
  addRecipient : {
    id : "mcore.addrecipient",
    defaultMessage : "Add a recipient"
  },
  saveSpecificRecipient : {
    id : "mcore.saveSpecificRecipient",
    defaultMessage : "This recipient will only be registered on this form"
  },
  editRecipient : {
    id : "mcore.editRecipient",
    defaultMessage : "Edit a recipient"
  },
  selectRecipient : {
    id : "mcore.selectRecipient",
    defaultMessage : "Select the recipient to add ..."
  },
  copyForEdit : {
    id : "mcore.copyForEdit",
    defaultMessage : "Copy component to edit"
  },
  toto : {
    id : "mcore.toto",
    defaultMessage : "Toto"
  },
  substitutionValue : {
    id : "mcore.substitutionValue",
    defaultMessage : "Value to substitute :"
  },
  substitutionCompareValue : {
    id : "mcore.substitutionCompareValue",
    defaultMessage : "Value to compare :"
  },
  substitutionCompare : {
    id : "mcore.substitutionCompare",
    defaultMessage : "Compared to"
  },
  substituedValue : {
    id : "mcore.substituedValue",
    defaultMessage : "Substitued value"
  },
  varParam : {
    id : "mcore.varParam",
    defaultMessage : "Parameter"
  },
  varParamList : {
    id : "mcore.varParamList",
    defaultMessage : "List of parameters"
  },
  varTimeList : {
    id : "mcore.varTimeList",
    defaultMessage : "List of dates"
  },
  varTimeRange : {
    id : "mcore.varTimeRange",
    defaultMessage : "Date Interval"
  },
  varPointList : {
    id : "mcore.varPointList",
    defaultMessage : "List of geographical positions"
  },
  areas : {
    id : "mcore.areas",
    defaultMessage : "Areas"
  },
  newVarProd : {
    id : "mcore.newVarProd",
    defaultMessage : "New variable"
  },
  defaultValue : {
    id : "mcore.defaultValue",
    defaultMessage : "Default value"
  },
  defaultListValue : {
    id : "mcore.defaultListValue",
    defaultMessage : "Default value ( separator : \" , \" )"
  },
  placeholderListValue : {
    id : "mcore.placeholderListValue",
    defaultMessage : "Value1,Value2,Value3"
  },
  personalize : {
    id : "mcore.personalize",
    defaultMessage : "Personalize"
  },
  defaultDataValue : {
    id : "mcore.defaultDataValue",
    defaultMessage : "#Undefined"
  },
  media : {
    id : "mcore.media",
    defaultMessage : "Media"
  },
  medias : {
    id : "mcore.medias",
    defaultMessage : "Media"
  },
  mediaCategory : {
    id : "mcore.mediaCategory",
    defaultMessage : "Media category"
  },
  mediaImageWidth : {
    id : "mcore.mediaImageWidth",
    defaultMessage : "Width (px)"
  },
  mediaImageHeight : {
    id : "mcore.mediaImageHeight",
    defaultMessage : "Height (px)"
  },
  mediaImageCrop : {
    id : "mcore.mediaImageCrop",
    defaultMessage : "Crop image"
  },
  mediaImageCropBtnReset : {
    id : "mcore.mediaImageCropBtnReset",
    defaultMessage : "Cancel"
  },
  mediaImageCropBtnSave : {
    id : "mcore.mediaImageCropBtnSave",
    defaultMessage : "Save"
  },
  mediaImageCropBtnSaveNew : {
    id : "mcore.mediaImageCropBtnSaveNew",
    defaultMessage : "Save As New"
  },
  fieldName : {
    id : "mcore.fieldName",
    defaultMessage : "Field name"
  },
  newExpertiseModel : {
    id : "mcore.newExpertiseModel",
    defaultMessage : "new expertise model"
  },
  expertiseModel : {
    id : "mcore.expertiseModel",
    defaultMessage : "Expertise model"
  },
  expertiseData : {
    id : "mcore.expertiseData",
    defaultMessage : "Expertise data"
  },
  fieldcreate : {
    id : "mcore.fieldcreate",
    defaultMessage : "Data fields creation"
  },
  fieldconfig : {
    id : "mcore.fieldconfig",
    defaultMessage : "Interface configuration"
  },
  interfacesList : {
    id : "mcore.interfacesList",
    defaultMessage : "List of interfaces"
  },
  newInterface : {
    id : "mcore.newInterface",
    defaultMessage : "New interface"
  },
  noItemAvailable : {
    id : "mcore.noItemAvailable",
    defaultMessage : "No item available"
  },
  noExportForThisProduct : {
    id : "mcore.noExportForThisProduct",
    defaultMessage : "No export for this product"
  },
  formatting : {
    id : "mcore.formatting",
    defaultMessage : "Formatting"
  },
  horizontalVariable : {
    id : "mcore.horizontalVariable",
    defaultMessage : "Horizontal variable"
  },
  verticalVariable : {
    id : "mcore.verticalVariable",
    defaultMessage : "Vertical variable"
  },
  tabVariable : {
    id : "mcore.tabVariable",
    defaultMessage : "Tab variable"
  },
  unknownModelAction : {
    id : "mcore.unknownModelAction",
    defaultMessage : "Unknown model or action"
  },
  contentVariable : {
    id : "mcore.contentVariable",
    defaultMessage : "Content variable"
  },
  layout : {
    id : "mcore.layout",
    defaultMessage : "Layout"
  },
  expertiseVariable : {
    id : "mcore.expertiseVariable",
    defaultMessage : "Expertise variable"
  },
  expertiseInterface : {
    id : "mcore.expertiseInterface",
    defaultMessage : "Expertise Interface"
  },
  newExpertiseParameter : {
    id : "mcore.newExpertiseParameter",
    defaultMessage : "New expertise parameter"
  },
  newDataParameter : {
    id : "mcore.newDataParameter",
    defaultMessage : "New data parameter"
  },
  newDataService : {
    id : "mcore.newDataService",
    defaultMessage : "New category"
  },
  newExternalFlow : {
    id : "mcore.newExternalFlow",
    defaultMessage : "New external flow"
  },
  newExternalwms : {
    id : "mcore.newExternalwms",
    defaultMessage : "New external WMS"
  },
  expVariableList : {
    id : "mcore.expVariableList",
    defaultMessage : "List of expertise variable"
  },
  displayParamInfo : {
    id : "mcore.displayParamInfo",
    defaultMessage : "Disabled parameters will not be displayed in the expertise interface"
  },
  interface : {
    id : "mcore.interface",
    defaultMessage : "Interface"
  },
  possibleValues : {
    id : "mcore.possibleValues",
    defaultMessage : "Possible values"
  },
  customerCustom : {
    id : "mcore.customerCustom",
    defaultMessage : "Customizable by the customer"
  },
  initThreshold : {
    id : "mcore.initThreshold",
    defaultMessage : "Threshold initialization value"
  },
  addInitialization : {
    id : "mcore.addInitialization",
    defaultMessage : "Add initialization"
  },
  fieldInitialization : {
    id : "mcore.fieldInitialization",
    defaultMessage : "Field initialization"
  },
  parameterInitialization : {
    id : "mcore.parameterInitialization",
    defaultMessage : "Parameter initialization"
  },
  dependField : {
    id : "mcore.dependField",
    defaultMessage : "Dependent field of"
  },
  valBetween : {
    id : "mcore.valBetween",
    defaultMessage : "Value between:"
  },
  nbCharMin : {
    id : "mcore.nbCharMin",
    defaultMessage : "Minimum number of characters"
  },
  nbCharMax : {
    id : "mcore.nbCharMax",
    defaultMessage : "Maximum number of characters"
  },
  nbLine : {
    id : "mcore.nbLine",
    defaultMessage : "Number of lines"
  },
  expertiseTaskName : {
    id : "mcore.expertiseTaskName",
    defaultMessage : "Name of the expertise task"
  },
  deleteBtn : {
    id : "mcore.deleteBtn",
    defaultMessage : "Delete"
  },
  modelManager : {
    id : "mcore.modelManager",
    defaultMessage : "Browse in Media Manager"
  },
  titleImageCrop : {
    id : "mcore.titleImageCrop",
    defaultMessage : "Title Image"
  },
  access : {
    id : "mcore.access",
    defaultMessage : "Accessibility"
  },
  publicAccess : {
    id : "mcore.publicAccess",
    defaultMessage : "Public"
  },
  privateAccess : {
    id : "mcore.privateAccess",
    defaultMessage : "Private"
  },
  riskEdit : {
    id : "mcore.riskEdit",
    defaultMessage : "Risk Edit"
  },
  varDimension : {
    id : "mcore.varDimension",
    defaultMessage : "Dimension variable"
  },
  varCustomer : {
    id : "mcore.varCustomer",
    defaultMessage : "Customer variable"
  },
  varCustomerImage : {
    id : "mcore.varCustomerImage",
    defaultMessage : "Customer image variable"
  },
  varCondition : {
    id : "mcore.varCondition",
    defaultMessage : "Condition"
  },
  varDate : {
    id : "mcore.varDate",
    defaultMessage : "Date variable"
  },
  varParameter : {
    id : "mcore.varParameter",
    defaultMessage : "Parameter variable"
  },
  varGeo : {
    id : "mcore.varGeo",
    defaultMessage : "Geo variable"
  },
  typeList : {
    id : "mcore.typeList",
    defaultMessage : "List of values"
  },
  placeholderTriggerType : {
    id : "mcore.placeholderTriggerType",
    defaultMessage : "Select a trigger type"
  },
  modelProductionCenter : {
    id : "mcore.modelProductionCenter",
    defaultMessage : "ProductionCenter"
  },
  modelDomain : {
    id : "mcore.modelDomain",
    defaultMessage : "Domain"
  },
  borderStyle : {
    id : "mcore.borderStyle",
    defaultMessage : "Border style"
  },
  center : {
    id : "mcore.center",
    defaultMessage : "Center"
  },
  alignment : {
    id : "mcore.alignment",
    defaultMessage : "Alignment"
  },
  rest : {
    id : "mcore.rest",
    defaultMessage : "rest"
  },
  labelPosition : {
    id : "mcore.labelPosition",
    defaultMessage : "Label position"
  },
  labelWidth : {
    id : "mcore.labelWidth",
    defaultMessage : "Label width"
  },
  labelContent : {
    id : "mcore.labelContent",
    defaultMessage : "Label content"
  },
  displayLabel : {
    id : "mcore.displayLabel",
    defaultMessage : "Display label"
  },
  fieldList : {
    id : "mcore.fieldList",
    defaultMessage : "Field list"
  },
  parameterList : {
    id : "mcore.parameterList",
    defaultMessage : "Parameter list"
  },
  groupFieldsInTable : {
    id : "mcore.groupFieldsInTable",
    defaultMessage : "Group fields in 1 or 2 dimensions array"
  },
  if : {
    id : "mcore.if",
    defaultMessage : "If"
  },
  activeIf : {
    id : "mcore.activeIf",
    defaultMessage : "Active if :"
  },
  atLeastOne : {
    id : "mcore.atLeastOne",
    defaultMessage : "At least one"
  },
  interfaceTitle : {
    id : "mcore.interfaceTitle",
    defaultMessage : "Interface title"
  },
  repetitionMode : {
    id : "mcore.repetitionMode",
    defaultMessage : "Repetition mode"
  },
  blocks : {
    id : "mcore.blocks",
    defaultMessage : "Blocks"
  },
  inline : {
    id : "mcore.inline",
    defaultMessage : "Inline"
  },
  direction : {
    id : "mcore.direction",
    defaultMessage : "Direction"
  },
  horizontal : {
    id : "mcore.horizontal",
    defaultMessage : "Horizontal"
  },
  vertical : {
    id : "mcore.vertical",
    defaultMessage : "Vertical"
  },
  container : {
    id : "mcore.container",
    defaultMessage : "Container"
  },
  containers : {
    id : "mcore.containers",
    defaultMessage : "Containers"
  },
  addContainer : {
    id : "mcore.addContainer",
    defaultMessage : "Add container"
  },
  document : {
    id : "mcore.document",
    defaultMessage : "Document"
  },
  loopComponent : {
    id : "mcore.loopComponent",
    defaultMessage : "Loop component"
  },
  ifComponent : {
    id : "mcore.ifComponent",
    defaultMessage : "If component"
  },
  formField : {
    id : "mcore.formField",
    defaultMessage : "Form field"
  },
  tableField : {
    id : "mcore.tableField",
    defaultMessage : "Table field"
  },
  customerImage : {
    id : "mcore.customerImage",
    defaultMessage : "Customer logo"
  },
  noPictureFound : {
    id : "mcore.noPictureFound",
    defaultMessage : "No Image found"
  },
  messageSelector : {
    id : "mcore.messageSelector",
    defaultMessage : "Click to select an image"
  },
  alignLeft : {
    id : "mcore.alignLeft",
    defaultMessage : "Align left"
  },
  alignCenter : {
    id : "mcore.alignCenter",
    defaultMessage : "Align center"
  },
  alignRight : {
    id : "mcore.alignRight",
    defaultMessage : "Align Right"
  },
  alignTop : {
    id : "mcore.alignTop",
    defaultMessage : "Align Top"
  },
  alignMiddle : {
    id : "mcore.alignMiddle",
    defaultMessage : "Align middle"
  },
  alignBottom : {
    id : "mcore.alignBottom",
    defaultMessage : "Align Bottom"
  },
  metadata : {
    id : "mcore.metadata",
    defaultMessage : "Metadata"
  },
  metadatas : {
    id : "mcore.metadatas",
    defaultMessage : "Metadata"
  },
  metadataType : {
    id : "mcore.metadataType",
    defaultMessage : "Metadata type"
  },
  weatherForecaster : {
    id : "mcore.weatherForecaster",
    defaultMessage : "Weather forecaster"
  },
  sourceGroup : {
    id : "mcore.sourceGroup",
    defaultMessage : "Group"
  },
  typeText : {
    id : "mcore.typeText",
    defaultMessage : "Text"
  },
  typeDateTime : {
    id : "mcore.typeDateTime",
    defaultMessage : "DateTime"
  },
  typeNumber : {
    id : "mcore.typeNumber",
    defaultMessage : "Number"
  },
  typeEnum : {
    id : "mcore.typeEnum",
    defaultMessage : "List of values"
  },
  typeTime : {
    id : "mcore.typeTime",
    defaultMessage : "Time"
  },
  modelTitleImport : {
    id : "mcore.modelTitleImport",
    defaultMessage : "Media Import"
  },
  titleNavBar1 : {
    id : "mcore.titleNavBar1",
    defaultMessage : "Browse in Media Manager"
  },
  titleNavBar2 : {
    id : "mcore.titleNavBar2",
    defaultMessage : "Upload images"
  },
  btnAddManage : {
    id : "mcore.btnAddManage",
    defaultMessage : "Add / Manage"
  },
  page : {
    id : "mcore.page",
    defaultMessage : "Page"
  },
  addPage : {
    id : "mcore.addPage",
    defaultMessage : "Add page"
  },
  insertPage : {
    id : "mcore.insertPage",
    defaultMessage : "Insert page"
  },
  insertBlankPage : {
    id : "mcore.insertBlankPage",
    defaultMessage : "Insert blank page"
  },
  duplicateCurrentPage : {
    id : "mcore.duplicateCurrentPage",
    defaultMessage : "Duplicate current page"
  },
  removePage : {
    id : "mcore.removePage",
    defaultMessage : "Remove page"
  },
  defaultPageWidth : {
    id : "mcore.defaultPageWidth",
    defaultMessage : "Default page width"
  },
  defaultPageHeight : {
    id : "mcore.defaultPageHeight",
    defaultMessage : "Default page height"
  },
  sectionsLayout : {
    id : "mcore.layoutSections",
    defaultMessage : "Sections layout"
  },
  documentLayout : {
    id : "mcore.documentLayout",
    defaultMessage : "Document layout"
  },
  mainContainer : {
    id : "mcore.mainContainer",
    defaultMessage : "Main container"
  },
  rotate : {
    id : "mcore.rotate",
    defaultMessage : "Rotate"
  },
  resetDefaultValues : {
    id : "mcore.resetDefaultValues",
    defaultMessage : "Reset default values"
  },
  applicableConfig : {
    id : "mcore.applicableConfig",
    defaultMessage : "Configuration applicable to "
  },
  editableArea : {
    id : "mcore.editableArea",
    defaultMessage : "Editable area"
  },
  undefinedLabel : {
    id : "mcore.undefinedLabel",
    defaultMessage : "Undefined label"
  },
  repeat : {
    id : "mcore.repeat",
    defaultMessage : "repeat"
  },
  noRepeat : {
    id : "mcore.noRepeat",
    defaultMessage : "No repeat"
  },
  repeatVertically : {
    id : "mcore.repeatVertically",
    defaultMessage : "Repeat vertically"
  },
  repeatHorizontally : {
    id : "mcore.repeatHorizontally",
    defaultMessage : "Repeat horizontally"
  },
  cover : {
    id : "mcore.cover",
    defaultMessage : "Cover content"
  },
  contain : {
    id : "mcore.contain",
    defaultMessage : "Adjust to content"
  },
  loopOnVariable : {
    id : "mcore.loopOnVariable",
    defaultMessage : "Loop on variable"
  },
  variableProduction : {
    id : "mcore.variableProduction",
    defaultMessage : "Variable of production"
  },
  variableLoop : {
    id : "mcore.variableLoop",
    defaultMessage : "Variable of loop"
  },
  paddLR : {
    id : "mcore.paddLR",
    defaultMessage : "Espacement G/D"
  },
  paddTB : {
    id : "mcore.paddTB",
    defaultMessage : "Espacement H/B"
  },
  parameterConfigMulti : {
    id : "mcore.parameterConfigMulti",
    defaultMessage : "Loop on parameters"
  },
  timeConfigMulti : {
    id : "mcore.timeConfigMulti",
    defaultMessage : "Loop on times"
  },
  geoConfigMulti : {
    id : "mcore.geoConfigMulti",
    defaultMessage : "Loop on geo positions"
  },
  riskConfigMulti : {
    id : "mcore.riskConfigMulti",
    defaultMessage : "Loop on risks"
  },
  valueConfigMulti : {
    id : "mcore.valueConfigMulti",
    defaultMessage : "Loop on values"
  },
  loopType : {
    id : "mcore.loopType",
    defaultMessage : "Loop type"
  },
  labelButtonConfigDistribution : {
    id : "mcore.labelButtonConfigDistribution",
    defaultMessage : "Edit"
  },
  messageErrorExternalFlowUrlIsEmpty : {
    id : "mcore.messageErrorExternalFlowUrlIsEmpty",
    defaultMessage : "Error, URL value is empty"
  },
  messageErrorExternalFlowFtpIsEmpty : {
    id : "mcore.messageErrorExternalFlowFtpIsEmpty",
    defaultMessage : "Error, FTP file path value is empty"
  },
  messageErrorExternalFlowSettingDatePattern : {
    id : "mcore.messageErrorExternalFlowSettingDatePattern",
    defaultMessage : "Error while setting URL or FTP file with date pattern"
  },
  messageErrorExternalFlowParamEmpty : {
    id : "mcore.messageErrorExternalFlowParamEmpty",
    defaultMessage : "Error, you must define at least one parameter"
  },
  messageErrorExternalFlowDateFormatNotEntered : {
    id : "mcore.messageErrorExternalFlowDateFormatNotEntered",
    defaultMessage : "Error, for Dimension and Parameter with format \"Date\", you must define a date format"
  },
  messageErrorExternalFlowConfigMatching : {
    id : "mcore.messageErrorExternalFlowConfigMatching",
    defaultMessage : "Error, specific configuration entered not match with preview content"
  },
  messageErrorFetchFTP : {
    id : "mcore.messageErrorFetchFTP",
    defaultMessage : "Error while retrieving files stored on the server"
  },
  removeLastLines : {
    id : "mcore.removeLastLines",
    defaultMessage : "Remove the last X Lines"
  },
  removeFirstLines : {
    id : "mcore.removeFirstLines",
    defaultMessage : "Remove the first X Lines"
  },
  activeCustomizable : {
    id : "mcore.activeCustomizable",
    defaultMessage : "Enable rule customization"
  },
  saveBeforeGenerate : {
    id : "mcore.saveBeforeGenerate",
    defaultMessage : "You must save before you can generate"
  },
  noConfiguration : {
    id : "mcore.noConfiguration",
    defaultMessage : "No valid configuration defined for this field"
  },
  textfield : {
    id : "mcore.textfield",
    defaultMessage : "Text field"
  },
  radio : {
    id : "mcore.radio",
    defaultMessage : "Radio"
  },
  datepicker : {
    id : "mcore.datepicker",
    defaultMessage : "Date picker"
  },
  timepicker : {
    id : "mcore.timepicker",
    defaultMessage : "Time picker"
  },
  serviceIV : {
    id : "mcore.serviceIV",
    defaultMessage : "Service"
  },
  textPlain : {
    id : "mcore.textPlain",
    defaultMessage : "text/plain"
  },
  upload : {
    id : "mcore.upload",
    defaultMessage : "Upload"
  },
  dueTimes : {
    id : "mcore.dueTimes",
    defaultMessage : "Due times ( [ minuts ] or [ hours ] h [ minuts ] )"
  },
  h1 : {
    id : "mcore.title1",
    defaultMessage : "Title 1"
  },
  h2 : {
    id : "mcore.title2",
    defaultMessage : "Title 2"
  },
  h3 : {
    id : "mcore.title3",
    defaultMessage : "Title 3"
  },
  h4 : {
    id : "mcore.title4",
    defaultMessage : "Title 4"
  },
  h5 : {
    id : "mcore.title5",
    defaultMessage : "Title 5"
  },
  h6 : {
    id : "mcore.title6",
    defaultMessage : "Title 6"
  },
  paragraph : {
    id : "mcore.paragraph",
    defaultMessage : "Paragraph"
  },
  rotate0deg : {
    id : "mcore.rotate0deg",
    defaultMessage : "0°"
  },
  rotate45deg : {
    id : "mcore.rotate45deg",
    defaultMessage : "45°"
  },
  rotate90deg : {
    id : "mcore.rotate90deg",
    defaultMessage : "90°"
  },
  rotate135deg : {
    id : "mcore.rotate135deg",
    defaultMessage : "135°"
  },
  rotate180deg : {
    id : "mcore.rotate180deg",
    defaultMessage : "180°"
  },
  rotate225deg : {
    id : "mcore.rotate225deg",
    defaultMessage : "225°"
  },
  rotate270deg : {
    id : "mcore.rotate270deg",
    defaultMessage : "270°"
  },
  rotate315deg : {
    id : "mcore.rotate315deg",
    defaultMessage : "315°"
  },
  dataSheet : {
    id : "mcore.dataSheet",
    defaultMessage : "dataSheet"
  },
  tableContainer : {
    id : "mcore.tableContainer",
    defaultMessage : "table"
  },
  cellContainer : {
    id : "mcore.cellContainer",
    defaultMessage : "cell"
  },
  emptyCell : {
    id : "mcore.emptyCell",
    defaultMessage : "empty"
  },
  addRowAbove : {
    id : "mcore.addRowAbove",
    defaultMessage : "Add row above"
  },
  addRowBelow : {
    id : "mcore.addRowBelow",
    defaultMessage : "Add row below"
  },
  addColBefore : {
    id : "mcore.addColBefore",
    defaultMessage : "Add column before"
  },
  addColAfter : {
    id : "mcore.addColAfter",
    defaultMessage : "Add column after"
  },
  removeCol : {
    id : "mcore.removeCol",
    defaultMessage : "Remove column"
  },
  removeRow : {
    id : "mcore.removeRow",
    defaultMessage : "Remove row"
  },
  mergeCells : {
    id : "mcore.mergeCells",
    defaultMessage : "Merge cells"
  },
  unmergeCells : {
    id : "mcore.unmergeCells",
    defaultMessage : "Unmerge cells"
  },
  initializeWithEmptyText : {
    id : "mcore.initializeWithEmptyText",
    defaultMessage : "Initialize with empty text"
  },
  textTypes : {
    id : "mcore.textTypes",
    defaultMessage : "Text types"
  },
  specialTextTypes : {
    id : "mcore.specialTextTypes",
    defaultMessage : "Special types"
  },
  repeatColumn : {
    id : "mcore.repeatColumn",
    defaultMessage : "Repeat column"
  },
  showRepeatedCols : {
    id : "mcore.showRepeatedCols",
    defaultMessage : "Show repeated columns"
  },
  repeatRow : {
    id : "mcore.repeatRow",
    defaultMessage : "Repeat row"
  },
  showRepeatedRows : {
    id : "mcore.showRepeatedRows",
    defaultMessage : "Show repeated rows"
  },
  fillColor : {
    id : "mcore.fillColor",
    defaultMessage : "Fill color"
  },
  mergeRepeatedCells : {
    id : "mcore.mergeRepeatedCells",
    defaultMessage : "Merge repeated cells"
  },
  preset : {
    id : "mcore.preset",
    defaultMessage : "Preset"
  },
  conditionVariable : {
    id : "mcore.conditionVariable",
    defaultMessage : "Condition variable"
  },
  true : {
    id : "mcore.true",
    defaultMessage : "True"
  },
  false : {
    id : "mcore.false",
    defaultMessage : "False"
  },
  dataValue : {
    id : "mcore.dataValue",
    defaultMessage : "Data value"
  },
  definedCondition : {
    id : "mcore.definedCondition",
    defaultMessage : "Condition to be defined"
  },
  tagAttribute : {
    id : "mcore.tagAttribute",
    defaultMessage : "Tag Attribute"
  },
  characterData : {
    id : "mcore.characterData",
    defaultMessage : "Character Data"
  },
  tagNew : {
    id : "mcore.tagNew",
    defaultMessage : "New Tag"
  },
  newTheme : {
    id : "mcore.newTheme",
    defaultMessage : "New Theme"
  },
  newAttribute : {
    id : "mcore.newAttribute",
    defaultMessage : "New Attribute"
  },
  overloadableInProd : {
    id : "mcore.overloadableInProd",
    defaultMessage : "Overloadable in Production config"
  },
  listValues : {
    id : "mcore.listValues",
    defaultMessage : "List of values"
  },
  newVariable : {
    id : "mcore.newVariable",
    defaultMessage : "New Variable"
  },
  editVariable : {
    id : "mcore.editVariable",
    defaultMessage : "Edit variable"
  },
  variables : {
    id : "mcore.variables",
    defaultMessage : "Variables"
  },
  index : {
    id : "mcore.index",
    defaultMessage : "Index"
  },
  nb : {
    id : "mcore.nb",
    defaultMessage : "Nb"
  },
  loopsAndCondition : {
    id : "mcore.loopsAndCondition",
    defaultMessage : "Loops and condition"
  },
  parameterName : {
    id : "mcore.parameterName",
    defaultMessage : "Parameter name"
  },
  parameterLabel : {
    id : "mcore.parameterLabel",
    defaultMessage : "Parameter label"
  },
  geoName : {
    id : "mcore.geoName",
    defaultMessage : "Geographical position name"
  },
  geoLabel : {
    id : "mcore.geoLabel",
    defaultMessage : "Geographical position label"
  },
  conditionalDisplay : {
    id : "mcore.conditionalDisplay",
    defaultMessage : "Conditional display"
  },
  defaultValueRequired : {
    id : "mcore.defaultValueRequired",
    defaultMessage : "Default value is required"
  },
  dimensionsRequired : {
    id : "mcore.dimensionsRequired",
    defaultMessage : "Dimensions is required"
  },
  varText : {
    id : "mcore.varText",
    defaultMessage : "Text variable"
  },
  varValue : {
    id : "mcore.varValue",
    defaultMessage : "Value variable"
  },
  feature : {
    id : "mcore.feature",
    defaultMessage : "Feature"
  },
  features : {
    id : "mcore.features",
    defaultMessage : "Features"
  },
  featureId : {
    id : "mcore.featureId",
    defaultMessage : "Feature id"
  },
  noFeatureKey : {
    id : "mcore.noFeatureKey",
    defaultMessage : "No feature key found in file"
  },
  invalidFile : {
    id : "mcore.invalidFile",
    defaultMessage : "Invalid file"
  },
  extra : {
    id : "mcore.extra",
    defaultMessage : "Extra"
  },
  main : {
    id : "mcore.main",
    defaultMessage : "Main"
  },
  draft : {
    id : "mcore.draft",
    defaultMessage : "Draft"
  },
  onlyConfigurableOptions : {
    id : "mcore.onlyConfigurableOptions",
    defaultMessage : "Only configurable options"
  },
  saveDraft : {
    id : "mcore.saveDraft",
    defaultMessage : "Save the draft"
  },
  saveModel : {
    id : "mcore.saveModel",
    defaultMessage : "Save the model"
  },
  aggregationMethod : {
    id : "mcore.aggregationMethod",
    defaultMessage : "Spatial aggregation method"
  },
  dr : {
    id : "mcore.dr",
    defaultMessage : "RD"
  },
  centralZone : {
    id : "mcore.centralZone",
    defaultMessage : "Central zone"
  },
  centralWeight : {
    id : "mcore.centralWeight",
    defaultMessage : "Central zone weight"
  },
  outerWeight : {
    id : "mcore.outerWeight",
    defaultMessage : "Outer weight central zone"
  },
  altitudeCondition : {
    id : "mcore.altitudeCondition",
    defaultMessage : "Altitude condition"
  },
  mountainThreshold : {
    id : "mcore.mountainThreshold",
    defaultMessage : "Altitude threshold (m)"
  },
  underAltitude : {
    id : "mcore.underAltitude",
    defaultMessage : "Definition below the altitude"
  },
  overAltitude : {
    id : "mcore.overAltitude",
    defaultMessage : "Definition above the altitude"
  },
  comment : {
    id : "mcore.comment",
    defaultMessage : "Comment"
  },
  hazardAttached : {
    id : "mcore.hazardAttached",
    defaultMessage : "Hazard(s) attached"
  },
  legendText : {
    id : "mcore.legendText",
    defaultMessage : "Legend Text"
  },
  probability : {
    id : "mcore.probability",
    defaultMessage : "Calculate the risk probability"
  },
  eventsParameter : {
    id : "mcore.eventsParameter",
    defaultMessage : "Parameter of elementary events"
  },
  ev : {
    id : "mcore.ev",
    defaultMessage : "Ev"
  },
  comparison : {
    id : "mcore.comparison",
    defaultMessage : "Comparison"
  },
  lowerThreshold : {
    id : "mcore.lowerThreshold",
    defaultMessage : "Lower threshold"
  },
  upperThreshold : {
    id : "mcore.upperThreshold",
    defaultMessage : "Upper threshold"
  },
  nbEvent : {
    id : "mcore.nbEvent",
    defaultMessage : "Number of elementary events"
  },
  aggregation : {
    id : "mcore.aggregation",
    defaultMessage : "Aggregation"
  },
  spatialAggregation : {
    id : "mcore.spatialAggregation",
    defaultMessage : "Spatial aggregation"
  },
  upStream : {
    id : "mcore.upStream",
    defaultMessage : "Upstream"
  },
  timeLineEditor : {
    id : "mcore.timeLineEditor",
    defaultMessage : "Time Line Editor"
  },
  downStream : {
    id : "mcore.downStream",
    defaultMessage : "Downstream"
  },
  algorithmChoice : {
    id : "mcore.algorithmChoice",
    defaultMessage : "Choice of algorithm"
  },
  synchronize : {
    id : "mcore.synchronize",
    defaultMessage : "Synchronize"
  },
  prometheeThreshold : {
    id : "mcore.prometheeThreshold",
    defaultMessage : "Threshold"
  },
  incomplete : {
    id : "mcore.incomplete",
    defaultMessage : "Incomplete"
  },
  prometheeValid : {
    id : "mcore.prometheeValid",
    defaultMessage : "Valid"
  },
  error_name_required : {
    id : "mcore.error_name_required",
    defaultMessage : "You must enter a name"
  },
  error_higher_value : {
    id : "mcore.error_higher_value",
    defaultMessage : "Must be higher than the lower threshold"
  },
  or : {
    id : "mcore.or",
    defaultMessage : "Or"
  },
  prometheeModel : {
    id : "mcore.prometheeModel",
    defaultMessage : "Promethee model"
  },
  newPrometheeModel : {
    id : "mcore.newPrometheeModel",
    defaultMessage : "New Promethee model"
  },
  newPrometheeComponent : {
    id : "mcore.newPrometheeComponent",
    defaultMessage : "New Promethee component"
  },
  prometheeSubstitutionValues : {
    id : "mcore.prometheeSubstitutionValues",
    defaultMessage : "Substitution values"
  },
  saveComponent : {
    id : "mcore.saveComponent",
    defaultMessage : "Save the component"
  },
  error_component_draft : {
    id : "mcore.error_component_draft",
    defaultMessage : "You must enter a name, at least a hazard, a period and a geo"
  },
  nbMaximumLevel : {
    id : "mcore.nbMaximumLevel",
    defaultMessage : "Maximum number of levels"
  },
  productComponents : {
    id : "mcore.productComponents",
    defaultMessage : "Product components"
  },
  gridComponents : {
    id : "mcore.gridComponents",
    defaultMessage : "Grid components"
  },
  paiComponents : {
    id : "mcore.paiComponents",
    defaultMessage : "PAI components"
  },
  variableLabel : {
    id : "mcore.variableName",
    defaultMessage : "Variable {label}"
  },
  newComponent : {
    id : "mcore.newComponent",
    defaultMessage : "New component"
  },
  geomap : {
    id : "mcore.geomap",
    defaultMessage : "Geo map"
  },
  zoom : {
    id : "mcore.zoom",
    defaultMessage : "Zoom"
  },
  zIndex : {
    id : "mcore.zIndex",
    defaultMessage : "Z-index"
  },
  georef : {
    id : "mcore.georef",
    defaultMessage : "Georeferencing"
  },
  draggableProp : {
    id : "mcore.draggableProp",
    defaultMessage : "Draggable property"
  },
  offset : {
    id : "mcore.offset",
    defaultMessage : "offset"
  },
  refX : {
    id : "mcore.refX",
    defaultMessage : "X reference"
  },
  refY : {
    id : "mcore.refY",
    defaultMessage : "Y reference"
  },
  valueSubstitutionRules : {
    id : "mcore.valueSubstitutionRules",
    defaultMessage : "Substitution rules by value"
  },
  variationSubstitutionRules : {
    id : "mcore.variationSubstitutionRules",
    defaultMessage : "Substitution rules by variation"
  },
  nbParameters : {
    id : "mcore.nbParameters",
    defaultMessage : "Number of parameters"
  },
  labelAndColor : {
    id : "mcore.labelAndColor",
    defaultMessage : "Label and color"
  },
  expertiseFields : {
    id : "mcore.expertiseFields",
    defaultMessage : "Expertise fields"
  },
  flexContainer : {
    id : "mcore.flexContainer",
    defaultMessage : "Flex container"
  },
  rotation : {
    id : "mcore.rotation",
    defaultMessage : "Rotation"
  },
  moveBackward : {
    id : "mcore.moveBackward",
    defaultMessage : "Move backward"
  },
  moveToBack : {
    id : "mcore.moveToBack",
    defaultMessage : "Move to back"
  },
  moveForward : {
    id : "mcore.moveForward",
    defaultMessage : "Move forward"
  },
  moveToFront : {
    id : "mcore.moveToFront",
    defaultMessage : "Move to front"
  },
  used : {
    id : "mcore.used",
    defaultMessage : "Used"
  },
  addConfig : {
    id : "mcore.addConfig",
    defaultMessage : "Add a configuration"
  },
  periodName : {
    id : "mcore.periodName",
    defaultMessage : "Period name"
  },
  productionTimeUntil : {
    id : "mcore.productionTimeUntil",
    defaultMessage : "Production time until : "
  },
  periodStart : {
    id : "mcore.periodStart",
    defaultMessage : "Period start"
  },
  periodStop : {
    id : "mcore.periodStop",
    defaultMessage : "Period stop"
  },
  parameterType : {
    id : "mcore.parameterType",
    defaultMessage : "Parameter type"
  },
  parameterDependencies : {
    id : "mcore.parameterDependencies",
    defaultMessage : "Parameter dependencies"
  },
  stepTimeIncrement : {
    id : "mcore.stepTimeIncrement",
    defaultMessage : "Step time increment"
  },
  version : {
    id : "mcore.version",
    defaultMessage : "Version"
  },
  linksToExtranets : {
    id : "mcore.linksToExtranets",
    defaultMessage : "Links to extranets"
  },
  linkToExtranet : {
    id : "mcore.linkToExtranet",
    defaultMessage : "Link to extranet"
  },
  linksToCustomerFiles : {
    id : "mcore.linksToCustomerFiles",
    defaultMessage : "Links to customer files"
  },
  linkToCustomerFile : {
    id : "mcore.linkToCustomerFile",
    defaultMessage : "Link to customer file"
  },
  linkToActions : {
    id : "mcore.linkToActions",
    defaultMessage : "Link to actions"
  },
  linkedTags : {
    id : "mcore.linkedTags",
    defaultMessage : "Linked tags"
  },
  encoding : {
    id : "mcore.encoding",
    defaultMessage : "Encoding"
  },
  standalone : {
    id : "mcore.standalone",
    defaultMessage : "Standalone"
  },
  documentNode : {
    id : "mcore.documentNode",
    defaultMessage : "Document node"
  },
  periodConfig : {
    id : "mcore.periodConfig",
    defaultMessage : "Period configuration"
  },
  error_config_period : {
    id : "mcore.error_config_period",
    defaultMessage : "All data must be completed to validate the period"
  },
  error_hour_period : {
    id : "mcore.error_hour_period",
    defaultMessage : "Period start time must be less than end time"
  },
  phenomenonDescribed : {
    id : "mcore.phenomenonDescribed",
    defaultMessage : "Phenomenon described"
  },
  weather : {
    id : "mcore.weather",
    defaultMessage : "Weather"
  },
  nebulosity : {
    id : "mcore.nebulosity",
    defaultMessage : "Nebulosity"
  },
  rainfall : {
    id : "mcore.rainfall",
    defaultMessage : "Rainfall"
  },
  storm : {
    id : "mcore.storm",
    defaultMessage : "Thunderstorm"
  },
  visi : {
    id : "mcore.visi",
    defaultMessage : "Visibility"
  },
  wind : {
    id : "mcore.wind",
    defaultMessage : "Wind"
  },
  tempe : {
    id : "mcore.tempe",
    defaultMessage : "Temperature"
  },
  error_component_draft_text : {
    id : "mcore.error_component_draft_text",
    defaultMessage : "You must enter a name, at least a period and a geo"
  },
  applicableConditions : {
    id : "mcore.applicableConditions",
    defaultMessage : "Applicable conditions: "
  },
  toDefined : {
    id : "mcore.toDefined",
    defaultMessage : " to defined"
  },
  andCondition : {
    id : "mcore.andCondition",
    defaultMessage : " && "
  },
  configurateRules : {
    id : "mcore.configurateRules",
    defaultMessage : "Configurate rules for "
  },
  issueDate : {
    id : "mcore.issueDate",
    defaultMessage : "Issue date"
  },
  addConfiguration : {
    id : "mcore.addConfiguration",
    defaultMessage : "Add configuration"
  },
  displayProductionIntoSummaryTable : {
    id : "mcore.displayProductionIntoSummaryTable",
    defaultMessage : "Display into synthesis array"
  },
  attachmentService : {
    id : "mcore.attachmentService",
    defaultMessage : "Attachment service"
  },
  grouped_by : {
    id : "mcore.grouped_by",
    defaultMessage : "Grouped by"
  },
  currentPageDim : {
    id : "mcore.currentPageDim",
    defaultMessage : "Current page dimensions"
  },
  groupElements : {
    id : "mcore.groupElements",
    defaultMessage : "Group Elements"
  },
  ungroupElements : {
    id : "mcore.ungroupElements",
    defaultMessage : "Ungroup Elements"
  },
  svgContainer : {
    id : "mcore.svgContainer",
    defaultMessage : "Container"
  },
  dimensionParameter : {
    id : "mcore.dimensionParameter",
    defaultMessage : "Parameter"
  },
  dimensionPoint : {
    id : "mcore.dimensionPoint",
    defaultMessage : "Point"
  },
  dimensionTime : {
    id : "mcore.dimensionTime",
    defaultMessage : "Date"
  },
  dimensionTime2 : {
    id : "mcore.dimensionTime2",
    defaultMessage : "2nd Date"
  },
  dimensionRisk : {
    id : "mcore.dimensionRisk",
    defaultMessage : "Risk"
  },
  pageNumber : {
    id : "mcore.pageNumber",
    defaultMessage : "Page number"
  },
  pageCount : {
    id : "mcore.pageCount",
    defaultMessage : "Page count"
  },
  taskCreation : {
    id : "mcore.taskCreation",
    defaultMessage : "Task creation"
  },
  actionsTask : {
    id : "mcore.actionsTask",
    defaultMessage : "Sub-actions"
  },
  triggersTask : {
    id : "mcore.triggersTask",
    defaultMessage : "Triggers task"
  },
  newActionTask : {
    id : "mcore.newActionTask",
    defaultMessage : "New sub-action"
  },
  newTrigger : {
    id : "mcore.newTrigger",
    defaultMessage : "New trigger"
  },
  noTriggerConfigured : {
    id : "mcore.noTriggerConfigured",
    defaultMessage : "No trigger configured"
  },
  nbActions : {
    id : "mcore.nbActions",
    defaultMessage : "Number of sub-actions"
  },
  nbTriggers : {
    id : "mcore.nbTriggers",
    defaultMessage : "Number of triggers"
  },
  nbExpertises : {
    id : "mcore.nbExpertises",
    defaultMessage : "Number of expertise configs"
  },
  validation : {
    id : "mcore.validation",
    defaultMessage : "Validation"
  },
  listActions : {
    id : "mcore.listActions",
    defaultMessage : "List of sub-actions"
  },
  associatedParameters : {
    id : "mcore.associatedParameters",
    defaultMessage : "Associated parameters"
  },
  newProductModel : {
    id : "mcore.newProductModel",
    defaultMessage : "New product model"
  },
  basicExample : {
    id : "mcore.basicExample",
    defaultMessage : "Basic example"
  },
  withExpertise : {
    id : "mcore.withExpertise",
    defaultMessage : "With expertise"
  },
  periods : {
    id : "mcore.periods",
    defaultMessage : "Period(s)"
  },
  geoAreas : {
    id : "mcore.geoAreas",
    defaultMessage : "Geographic area(s)"
  },
  displayThreshold : {
    id : "mcore.displayThreshold",
    defaultMessage : "Display into synthesis array"
  },
  thresholdList : {
    id : "mcore.thresholdList",
    defaultMessage : "Threshold List"
  },
  noteThreshold : {
    id : "mcore.noteThreshold",
    defaultMessage : "Note : Thresholds defined here not intended to be able to filter the types of production"
  },
  listFilterZone : {
    id : "mcore.listFilterZone",
    defaultMessage : "List of filter zones"
  },
  releaseDate : {
    id : "mcore.releaseDate",
    defaultMessage : "Release date"
  },
  titleDocumentList : {
    id : "mcore.titleDocumentList",
    defaultMessage : "List of documents"
  },
  linkToDocument : {
    id : "mcore.linkToDocument",
    defaultMessage : "Link to document"
  },
  productionDocumentation : {
    id : "mcore.productionDocumentation",
    defaultMessage : "Production documentation"
  },
  toggleTree : {
    id : "mcore.toggleTree",
    defaultMessage : "display/hide tree components"
  },
  generalConfig : {
    id : "mcore.generalConfig",
    defaultMessage : "General configuration"
  },
  specificConfig : {
    id : "mcore.specificConfig",
    defaultMessage : "Specific configuration"
  },
  recoveryMode : {
    id : "mcore.recoveryMode",
    defaultMessage : "Recovery Mode"
  },
  configurationURL : {
    id : "mcore.configurationURL",
    defaultMessage : "URL configuration"
  },
  configurationFTP : {
    id : "mcore.configurationFTP",
    defaultMessage : "FTP configuration"
  },
  configurationHTML : {
    id : "mcore.configurationHTML",
    defaultMessage : "HTML configuration"
  },
  configurationTXT : {
    id : "mcore.configurationTXT",
    defaultMessage : "TXT configuration"
  },
  asText : {
    id : "mcore.asText",
    defaultMessage : "As text"
  },
  configurationCSV : {
    id : "mcore.configurationCSV",
    defaultMessage : "CSV configuration"
  },
  configurationJSON : {
    id : "mcore.configurationJSON",
    defaultMessage : "JSON configuration"
  },
  configurationXML : {
    id : "mcore.configurationXML",
    defaultMessage : "XML configuration"
  },
  configurationIMAGE : {
    id : "mcore.configurationIMAGE",
    defaultMessage : "Image configuration"
  },
  urlDependsDate : {
    id : "mcore.urlDependsDate",
    defaultMessage : "URL depends on date"
  },
  isDimensionDate : {
    id : "mcore.isDimensionDate",
    defaultMessage : "Date is a dimension"
  },
  urlToFile : {
    id : "mcore.urlToFile",
    defaultMessage : "URL to file"
  },
  urlDatePattern : {
    id : "mcore.urlDatePattern",
    defaultMessage : "URL: Date pattern"
  },
  ftpDependsDate : {
    id : "mcore.ftpDependsDate",
    defaultMessage : "Filename depends on date"
  },
  ftpPath : {
    id : "mcore.ftpPath",
    defaultMessage : "FTP path"
  },
  ftpDeleteFilesAfter : {
    id : "mcore.ftpDeleteFilesAfter",
    defaultMessage : "Delete files after:"
  },
  labelDays7 : {
    id : "mcore.labelDays7",
    defaultMessage : "7 days"
  },
  labelDays14 : {
    id : "mcore.labelDays14",
    defaultMessage : "14 days"
  },
  labelDays21 : {
    id : "mcore.labelDays21",
    defaultMessage : "21 days"
  },
  ftpPathSelect : {
    id : "mcore.ftpPathSelect",
    defaultMessage : "Select"
  },
  fileSelected : {
    id : "mcore.fileSelected",
    defaultMessage : "File selected"
  },
  getFile : {
    id : "mcore.getFile",
    defaultMessage : "Get file"
  },
  validPeriod : {
    id : "mcore.validPeriod",
    defaultMessage : "Duration before expiration"
  },
  fixedHeight : {
    id : "mcore.fixedHeight",
    defaultMessage : "Fixed height"
  },
  path : {
    id : "mcore.path",
    defaultMessage : "Path"
  },
  configurationDimParams : {
    id : "mcore.configurationDimParams",
    defaultMessage : "Dimensions and parameters configuration"
  },
  configurationType : {
    id : "mcore.configurationType",
    defaultMessage : "Configuration Type"
  },
  object : {
    id : "mcore.object",
    defaultMessage : "Object"
  },
  objectName : {
    id : "mcore.objectName",
    defaultMessage : "Object: Name"
  },
  objectAttributName : {
    id : "mcore.objectAttributName",
    defaultMessage : "Object: Attribut name"
  },
  objectAttributValue : {
    id : "mcore.objectAttributValue",
    defaultMessage : "Object: Attribut value"
  },
  jsonComponent : {
    id : "mcore.jsonComponent",
    defaultMessage : "JSON Component"
  },
  attribute : {
    id : "mcore.attribute",
    defaultMessage : "Attribute"
  },
  attributeKey : {
    id : "mcore.attributeKey",
    defaultMessage : "Attribute: Key"
  },
  key : {
    id : "mcore.key",
    defaultMessage : "Key"
  },
  allKeys : {
    id : "mcore.allKeys",
    defaultMessage : "All keys"
  },
  xmlTagName : {
    id : "mcore.xmlTagName",
    defaultMessage : "XMLTag Name"
  },
  xmlTagExtractType : {
    id : "mcore.xmlTagExtractType",
    defaultMessage : "XMLTag: Extract type"
  },
  xmlTagAttributeName : {
    id : "mcore.xmlTagAttributeName",
    defaultMessage : "XMLTag: Attribute name"
  },
  paramsKey : {
    id : "mcore.paramsKey",
    defaultMessage : "Parameter: Key"
  },
  formatDate : {
    id : "mcore.formatDate",
    defaultMessage : "Format: Date"
  },
  externalFlowError1 : {
    id : "mcore.externalFlowError1",
    defaultMessage : "Unable to determine the nature of the file"
  },
  externalFlowError2 : {
    id : "mcore.externalFlowError2",
    defaultMessage : "The selected \"Type\" and the contents of the recovered file do not match"
  },
  externalFlowError3 : {
    id : "mcore.externalFlowError3",
    defaultMessage : "An error occurred while loading: {errorReason}"
  },
  noThreshold : {
    id : "mcore.noThreshold",
    defaultMessage : "-- No threshold --"
  },
  noZone : {
    id : "mcore.noZone",
    defaultMessage : "-- No zone --"
  },
  fixedWidth : {
    id : "mcore.fixedWidth",
    defaultMessage : "Fixed width"
  },
  labelValue : {
    id : "mcore.labelValue",
    defaultMessage : "Label"
  },
  labelValue2 : {
    id : "mcore.labelValue2",
    defaultMessage : "Label 2"
  },
  optionsDisplay : {
    id : "mcore.optionsDisplay",
    defaultMessage : "Options display"
  },
  colorContainer : {
    id : "mcore.colorContainer",
    defaultMessage : "Color container"
  },
  oppositeCondition : {
    id : "mcore.oppositeCondition",
    defaultMessage : "Opposite condition"
  },
  nbService : {
    id : "mcore.nbService",
    defaultMessage : "Number of associated Mdata services"
  },
  customerEdition : {
    id : "mcore.customerEdition",
    defaultMessage : "Customer edition"
  },
  customerCreation : {
    id : "mcore.customerCreation",
    defaultMessage : "Customer creation"
  },
  configure : {
    id : "mcore.configure",
    defaultMessage : "Configure"
  },
  modelConfig : {
    id : "mcore.modelConfig",
    defaultMessage : "Model's configuration"
  },
  configView : {
    id : "mcore.configView",
    defaultMessage : "View the configuration"
  },
  configValidate : {
    id : "mcore.configValidate",
    defaultMessage : "Validate configuration"
  },
  prometheeSendComponent : {
    id : "mcore.prometheeSendComponent",
    defaultMessage : "Components to send to Promethee"
  },
  dupplicatedConfig : {
    id : "mcore.dupplicatedConfig",
    defaultMessage : "Two configurations are the same, please change one."
  },
  backList : {
    id : "mcore.backList",
    defaultMessage : "Go back to the events list"
  },
  valueType : {
    id : "mcore.valueType",
    defaultMessage : "Value type"
  },
  fake : {
    id : "mcore.fake",
    defaultMessage : "Fake"
  },
  real : {
    id : "mcore.real",
    defaultMessage : "Real"
  },
  splitValues : {
    id : "mcore.splitValues",
    defaultMessage : "type 'Enter' or ';' between each value"
  },
  downloadPreviewFile : {
    id : "mcore.downloadPreviewFile",
    defaultMessage : "Download a preview file"
  },
  value2 : {
    id : "mcore.value2",
    defaultMessage : "2nd value"
  },
  forecasterName : {
    id : "mcore.forecasterName",
    defaultMessage : "Forecaster's name"
  },
  forecasterEmail : {
    id : "mcore.forecasterEmail",
    defaultMessage : "Forecaster's e-mail"
  },
  productionCenterName : {
    id : "mcore.productionCenterName",
    defaultMessage : "Production center's name"
  },
  otherProductionCenterProp : {
    id : "mcore.otherProductionCenterProp",
    defaultMessage : "Other production center property"
  },
  substitutedDataByColor : {
    id : "mcore.substitutedDataByColor",
    defaultMessage : "Substituted data by color"
  },
  substitutedDataByColorCode : {
    id : "mcore.substitutedDataByColorCode",
    defaultMessage : "Substituted data by color code"
  },
  substitutedDataByText : {
    id : "mcore.substitutedDataByText",
    defaultMessage : "Substituted data by text"
  },
  substitutedDataByImage : {
    id : "mcore.substitutedDataByImage",
    defaultMessage : "Substituted data by image"
  },
  substitutionField : {
    id : "mcore.substitutionField",
    defaultMessage : "Substitution field"
  },
  defaultConfiguration : {
    id : "mcore.defaultConfiguration",
    defaultMessage : "Default configuration"
  },
  customConfiguration : {
    id : "mcore.customConfiguration",
    defaultMessage : "Custom configuration"
  },
  dayAt : {
    id : "mcore.dayAt",
    defaultMessage : "days at"
  },
  dateStart : {
    id : "mcore.dateStart",
    defaultMessage : "START DATE"
  },
  dateEnd : {
    id : "mcore.dateEnd",
    defaultMessage : "END DATE"
  },
  dateType : {
    id : "mcore.dateType",
    defaultMessage : "Date type"
  },
  savePeriod : {
    id : "mcore.savePeriod",
    defaultMessage : "Save period"
  },
  prodTime : {
    id : "mcore.prodTime",
    defaultMessage : "Production Time"
  },
  until : {
    id : "mcore.until",
    defaultMessage : "Until"
  },
  dayRef : {
    id : "mcore.dayRef",
    defaultMessage : "Reference day +"
  },
  hourRef : {
    id : "mcore.hourRef",
    defaultMessage : "Reference hour +"
  },
  promethee : {
    id : "mcore.promethee",
    defaultMessage : "Promethee"
  },
  prod : {
    id : "mcore.prod",
    defaultMessage : "Production"
  },
  slippery : {
    id : "mcore.slippery",
    defaultMessage : "Slippery"
  },
  local : {
    id : "mcore.local",
    defaultMessage : "Local"
  },
  intervalBetweenHours : {
    id : "mcore.intervalBetweenHours",
    defaultMessage : "Time interval between different hours (in minutes)"
  },
  error_period_duplicated : {
    id : "mcore.error_period_duplicated",
    defaultMessage : "Error in saving, this period already exists"
  },
  initConfig : {
    id : "mcore.initConfig",
    defaultMessage : "Initialization of the configuration by"
  },
  thumbnailError : {
    id : "mcore.thumbnailError",
    defaultMessage : "Impossible to create thumbnail"
  },
  modifyInitParams : {
    id : "mcore.modifyInitParams",
    defaultMessage : "Modify parameters initialization"
  },
  initButton : {
    id : "mcore.initButton",
    defaultMessage : "Init Button"
  },
  bordered : {
    id : "mcore.bordered",
    defaultMessage : "Bordered"
  },
  limitInitializationToValuesOfFollowingVariables : {
    id : "mcore.limitInitializationToValuesOfFollowingVariables",
    defaultMessage : "Limit initialization to values of following variables"
  },
  addVariable : {
    id : "mcore.addVariable",
    defaultMessage : "Add variable"
  },
  addColumn : {
    id : "mcore.addColumn",
    defaultMessage : "Add column"
  },
  warningOverloadedByLoops : {
    id : "mcore.warningOverloadedByLoops",
    defaultMessage : "Warning : if in a loop, values will be overwritten by loop value"
  },
  error_period_time : {
    id : "mcore.error_period_time",
    defaultMessage : "Start date must be less than end date and the application ranges must be in ascending order"
  },
  periodChoicePreview : {
    id : "mcore.periodChoicePreview",
    defaultMessage : "Preview / Choice of periods"
  },
  goBackPeriodList : {
    id : "mcore.goBackPeriodList",
    defaultMessage : "Go back to the periods list"
  },
  useDimensionsOfExpertiseField : {
    id : "mcore.useDimensionsOfExpertiseField",
    defaultMessage : "Use the dimensions of the expertise field"
  },
  minAggregate : {
    id : "mcore.minAggregate",
    defaultMessage : "MIN"
  },
  maxAggregate : {
    id : "mcore.maxAggregate",
    defaultMessage : "MAX"
  },
  averageAggregate : {
    id : "mcore.averageAggregate",
    defaultMessage : "AVERAGE"
  },
  shortcut : {
    id : "mcore.shortcut",
    defaultMessage : "Shortcut"
  },
  shortcutNew : {
    id : "mcore.shortcutNew",
    defaultMessage : "New shortcut"
  },
  code : {
    id : "mcore.code",
    defaultMessage : "Code"
  },
  addProperty : {
    id : "mcore.addProperty",
    defaultMessage : "Add Property"
  },
  draw : {
    id : "mcore.draw",
    defaultMessage : "Draw"
  },
  shape : {
    id : "mcore.shape",
    defaultMessage : "Shape"
  },
  shapes : {
    id : "mcore.shapes",
    defaultMessage : "Shapes"
  },
  freeHandPath : {
    id : "mcore.freeHandPath",
    defaultMessage : "Free hand path"
  },
  ellipse : {
    id : "mcore.ellipse",
    defaultMessage : "Ellipse"
  },
  rectangle : {
    id : "mcore.rectangle",
    defaultMessage : "Rectangle"
  },
  polyline : {
    id : "mcore.polyline",
    defaultMessage : "Polyline"
  },
  strokeStyle : {
    id : "mcore.strokeStyle",
    defaultMessage : "Stroke style"
  },
  dynamic : {
    id : "mcore.dynamic",
    defaultMessage : "Dynamic"
  },
  dynamicColor : {
    id : "mcore.dynamicColor",
    defaultMessage : "Dynamic color"
  },
  dynamicColorCell : {
    id : "mcore.dynamicColorCell",
    defaultMessage : "Dynamic color cell"
  },
  showCtrlPoints : {
    id : "mcore.showCtrlPoints",
    defaultMessage : "Show control points"
  },
  currentPageWidth : {
    id : "mcore.currentPageWidth",
    defaultMessage : "Current page width"
  },
  currentPageHeight : {
    id : "mcore.currentPageHeight",
    defaultMessage : "Current page height"
  },
  newProperty : {
    id : "mcore.newProperty",
    defaultMessage : "New Property"
  },
  displayCoordType : {
    id : "mcore.displayCoordType",
    defaultMessage : "Display coordinates type"
  },
  displayDMS : {
    id : "mcore.displayDMS",
    defaultMessage : "Display in degrees minutes seconds"
  },
  richtext : {
    id : "mcore.richtext",
    defaultMessage : "RichText"
  },
  singleLineText : {
    id : "mcore.singleLineText",
    defaultMessage : "Single line text"
  },
  multiLineRichText : {
    id : "mcore.multiLineRichText",
    defaultMessage : "Multiline rich text"
  },
  displayBold : {
    id : "mcore.displayBold",
    defaultMessage : "Display bold option"
  },
  displayItalic : {
    id : "mcore.displayItalic",
    defaultMessage : "Display italic option"
  },
  displayUnderline : {
    id : "mcore.displayUnderline",
    defaultMessage : "Display underline option"
  },
  fillHeight : {
    id : "mcore.fillHeight",
    defaultMessage : "Fill available height"
  },
  allowCrop : {
    id : "mcore.allowCrop",
    defaultMessage : "Allow image cropping"
  },
  addImageOnGraph : {
    id : "mcore.addImageOnGraph",
    defaultMessage : "Display on graph field"
  },
  rawJson : {
    id : "mcore.rawJson",
    defaultMessage : "Raw Json"
  },
  keyValue : {
    id : "mcore.keyValue",
    defaultMessage : "Key Value"
  },
  keyChoice : {
    id : "mcore.keyChoice",
    defaultMessage : "Key choice"
  },
  modelConfigText : {
    id : "mcore.modelConfigText",
    defaultMessage : "Text model"
  },
  titleModelConfigRisk : {
    id : "mcore.titleModelConfigRisk",
    defaultMessage : "Risk model configuration for level "
  },
  ofHazard : {
    id : "mcore.ofHazard",
    defaultMessage : " of hazard "
  },
  modelConfigRisk : {
    id : "mcore.modelConfigRisk",
    defaultMessage : "Risk model"
  },
  nbHours : {
    id : "mcore.nbHours",
    defaultMessage : "Number of hours"
  },
  autoFillOptions : {
    id : "mcore.autoFillOptions",
    defaultMessage : "Automatic filling of options"
  },
  noFillOptions : {
    id : "mcore.noFillOptions",
    defaultMessage : "No automatic filling"
  },
  visualizeInterface : {
    id : "mcore.visualizeInterface",
    defaultMessage : " Visualize Interface"
  },
  allGeosPeriods : {
    id : "mcore.allGeosPeriods",
    defaultMessage : "A configuration has already been entered, it's not possible to select all zones / all periods"
  },
  numberDaysRetention : {
    id : "mcore.NumberDaysRetention",
    defaultMessage : "Number of days of Retention"
  },
  hostName : {
    id : "mcore.hostName",
    defaultMessage : " Hostname"
  },
  storageInfo : {
    id : "mcore.StorageInfo",
    defaultMessage : " Storage information"
  },
  storagePolicyEdition : {
    id : "mcore.storagePolicyEdition",
    defaultMessage : "Storage policy edition"
  },
  storagePolicy : {
    id : "mcore.storagePolicy",
    defaultMessage : "Storage policy"
  },
  storagePolicyNew : {
    id : "mcore.storagePolicyNew",
    defaultMessage : "New Storage policy"
  },
  order : {
    id : "mcore.order",
    defaultMessage : "Order"
  },
  newCondition : {
    id : "mcore.newCondition",
    defaultMessage : "New condition"
  },
  distributionConditions : {
    id : "mcore.distributionConditions",
    defaultMessage : "distribution conditions"
  },
  risk_between_alti : {
    id : "mcore.risk_between_alti",
    defaultMessage : "Risks described between altitudes"
  },
  unit_m : {
    id : "mcore.unit_m",
    defaultMessage : "m"
  },
  detailled_analysis_text : {
    id : "mcore.detailled_analysis_text",
    defaultMessage : "Detailed analysis text"
  },
  tags_descriptive_zone : {
    id : "mcore.tags_descriptive_zone",
    defaultMessage : "Tags of descriptive zones"
  },
  description_cardinal_point : {
    id : "mcore.description_cardinal_point",
    defaultMessage : "Description according to cardinal points"
  },
  description_alti : {
    id : "mcore.description_alti",
    defaultMessage : "Description according to altitude"
  },
  displayColumnsTitles : {
    id : "mcore.displayColumnsTitles",
    defaultMessage : "Display columns titles"
  },
  templateNotDefined : {
    id : "mcore.templateNotDefined",
    defaultMessage : "No mask"
  },
  marker : {
    id : "mcore.marker",
    defaultMessage : "Marker"
  },
  loopOn : {
    id : "mcore.loopOn",
    defaultMessage : "Loop on"
  },
  b4s : {
    id : "mcore.b4s",
    defaultMessage : "b4s"
  },
  snowHeightNorth : {
    id : "mcore.snowHeightNorth",
    defaultMessage : "Hauteur de neige nord"
  },
  x : {
    id : "mcore.x",
    defaultMessage : "x"
  },
  y : {
    id : "mcore.y",
    defaultMessage : "y"
  },
  snowHeightSouth : {
    id : "mcore.snowHeightSouth",
    defaultMessage : "Hauteur de neige sud"
  },
  minSvgHeight : {
    id : "mcore.minSvgHeight",
    defaultMessage : "Minimum svg height"
  },
  maxSvgHeight : {
    id : "mcore.maxSvgHeight",
    defaultMessage : "Maximum svg height"
  },
  saveEntry : {
    id : "mcore.saveEntry",
    defaultMessage : "Save entry"
  },
  charactersCounter : {
    id : "mcore.charactersCounter",
    defaultMessage : "{counter} characters"
  },
  newActionProd : {
    id : "mcore.newActionProd",
    defaultMessage : "New action"
  },
  newAction : {
    id : "mcore.newAction",
    defaultMessage : "New action"
  },
  editAction : {
    id : "mcore.editAction",
    defaultMessage : "Edit action"
  },
  productionSentMTTwoDaysAgo : {
    id : "mcore.productionSentMTTwoDaysAgo",
    defaultMessage : "Production sent more than 48h ago"
  },
  productionSentLTTwoDaysAgo : {
    id : "mcore.productionSentLTTwoDaysAgo",
    defaultMessage : "Production sent less than 48h ago"
  },
  productionSentLTOneDayAgo : {
    id : "mcore.productionSentLTOneDayAgo",
    defaultMessage : "Production sent less than 24h ago"
  },
  productionSentLTOneHourAgo : {
    id : "mcore.productionSentLTOneHourAgo",
    defaultMessage : "Production sent less than 1h ago"
  },
  noLogoSelected : {
    id : "mcore.noLogoSelected",
    defaultMessage : "No logo selected"
  },
  noActionRegistered : {
    id : "mcore.noActionRegistered",
    defaultMessage : "No action registered"
  },
  applySameValue : {
    id : "mcore.applySameValue",
    defaultMessage : "Apply same value"
  },
  applySameVariation : {
    id : "mcore.applySameVariation",
    defaultMessage : "Apply same variation"
  },
  applyInverseVariation : {
    id : "mcore.applyInverseVariation",
    defaultMessage : "Apply inverse variation"
  },
  applyValue : {
    id : "mcore.applyValue",
    defaultMessage : "Apply a value"
  },
  deleteValue : {
    id : "mcore.deleteValue",
    defaultMessage : "Delete value"
  },
  applyValidationRule : {
    id : "mcore.applyValidationRule",
    defaultMessage : "Apply a validation rule"
  },
  removeValidationRule : {
    id : "mcore.removeValidationRule",
    defaultMessage : "Remove a validation rule"
  },
  validationRule : {
    id : "mcore.validationRule",
    defaultMessage : "Validation rule"
  },
  sourceParameter : {
    id : "mcore.sourceParameter",
    defaultMessage : "Source parameter"
  },
  destinationParameter : {
    id : "mcore.destinationParameter",
    defaultMessage : "Destination parameter"
  },
  applicableOptions : {
    id : "mcore.applicableOptions",
    defaultMessage : "Only rules defined with equal conditions are used in options."
  },
  separator : {
    id : "mcore.separator",
    defaultMessage : "Separator"
  },
  noCondition : {
    id : "mcore.noCondition",
    defaultMessage : "No condition configured"
  },
  initializeWith : {
    id : "mcore.initializeWith",
    defaultMessage : "Initialize with"
  },
  selectDefaultValue : {
    id : "mcore.selectDefaultValue",
    defaultMessage : "Select a default value"
  },
  manualTaskVariables : {
    id : "mcore.manualTaskVariables",
    defaultMessage : "Configurable Variables when inserting the task"
  },
  listGeos : {
    id : "mcore.listGeos",
    defaultMessage : "Geos list"
  },
  ParamCumulativeRain : {
    id : "mcore.ParamCumulativeRain",
    defaultMessage : "Parameter cumulative rain"
  },
  ParamAP : {
    id : "mcore.ParamAP",
    defaultMessage : "Parameter AP"
  },
  defaultUnit : {
    id : "mcore.defaultUnit",
    defaultMessage : "Default unit"
  },
  internalDistribution : {
    id : "mcore.internalDistribution",
    defaultMessage : "Internal distribution"
  },
  internal : {
    id : "mcore.internal",
    defaultMessage : "Internal"
  },
  external : {
    id : "mcore.external",
    defaultMessage : "External"
  },
  error_recipient_field_empty : {
    id : "mcore.error_recipient_field_empty",
    defaultMessage : "You must select at least one recipient and one product"
  },
  switchButton : {
    id : "mcore.switchButton",
    defaultMessage : "Button On/Off"
  },
  protocolEmailBody : {
    id : "mcore.protocolEmailBody",
    defaultMessage : "Hello, please find attached the documents ordered."
  },
  protocolEmailSubject : {
    id : "mcore.protocolEmailSubject",
    defaultMessage : "Provision of weather information"
  },
  apiCall : {
    id : "mcore.apiCall",
    defaultMessage : "Call to an API"
  },
  badFormattedTags : {
    id : "mcore.badFormattedTags",
    defaultMessage : "Be careful, some tags have not been created or assigned because they do not have the right format"
  },
  badComparison : {
    id : "mcore.badComparison",
    defaultMessage : "Be careful, the value entered does not respect the comparison conditions"
  },
  Geo : {
    id : "mcore.Geo",
    defaultMessage : "Geographical position"
  },
  ParamDimensionValue : {
    id : "mcore.ParamDimensionValue",
    defaultMessage : "Risk"
  },
  zoneTitleRequired : {
    id : "mcore.zoneTitleRequired",
    defaultMessage : "Please select the property that will become the title of the zone."
  },
  noFeatureIdentifier : {
    id : "mcore.noFeatureIdentifier",
    defaultMessage : "The point at position {position} could not be imported because it does not have property."
  },
  noFeaturesIdentifier : {
    id : "mcore.noFeaturesIdentifier",
    defaultMessage : "The points in positions {positions} could not be imported because they do not have property."
  },
  viewProduct : {
    id : "mcore.viewProduct",
    defaultMessage : "View the product(s)"
  },
  productionData : {
    id : "mcore.productionData",
    defaultMessage : "Production datas"
  },
  productionCounter : {
    id : "mcore.productionCounter",
    defaultMessage : "Production report number"
  },
  eventData : {
    id : "mcore.eventData",
    defaultMessage : "Event datas"
  },
  reportNumber : {
    id : "mcore.reportNumber",
    defaultMessage : "Report number"
  },
  onetab : {
    id : "mcore.onetab",
    defaultMessage : "1 tab"
  },
  twotabs : {
    id : "mcore.twotabs",
    defaultMessage : "2 tabs"
  },
  threetabs : {
    id : "mcore.threetabs",
    defaultMessage : "3 tabs"
  },
  fourtabs : {
    id : "mcore.fourtabs",
    defaultMessage : "4 tabs"
  },
  addTab : {
    id : "mcore.addTab",
    defaultMessage : "Add tab"
  },
  drawSelectionToCopy : {
    id : "mcore.drawSelectionToCopy",
    defaultMessage : "Clic or draw selection to copy fields"
  },
  drawSelectionToPaste : {
    id : "mcore.drawSelectionToPaste",
    defaultMessage : "Clic or draw selection to paste fields"
  },
  duplicateValuesToDimensions : {
    id : "mcore.duplicateValuesToDimensions",
    defaultMessage : "Duplicate to dimensions"
  },
  noSharedDimensionFound : {
    id : "mcore.noSharedDimensionFound",
    defaultMessage : "No shared dimension value found between these fields"
  },
  duplicateOneOrMoreValues : {
    id : "mcore.duplicateOneOrMoreValues",
    defaultMessage : "Duplicate one or more values"
  },
  duplicateValues : {
    id : "mcore.duplicateValues",
    defaultMessage : "Duplicate {count, plural, one {value} other {values}}"
  },
  useRealTimeValue : {
    id : "mcore.useRealTimeValue",
    defaultMessage : "Use the data being entered"
  },
  ifAmendment : {
    id : "mcore.ifAmendment",
    defaultMessage : "If amendment"
  },
  choosenProductions : {
    id : "mcore.choosenProductions",
    defaultMessage : "Choosen productions"
  },
  present : {
    id : "mcore.present",
    defaultMessage : "Present"
  },
  dataPresent : {
    id : "mcore.dataPresent",
    defaultMessage : "Data present"
  },
  elevationResetDisclaimer : {
    id : "mcore.elevationResetDisclaimer",
    defaultMessage : "Beware, modifications will reset the previous elevation parameters within the product. Are you sure you wish to proceed ?"
  },
  prometheePeriod : {
    id : "mcore.prometheePeriod",
    defaultMessage : "Promethee period"
  },
  applicationCriteria : {
    id : "mcore.applicationCriteria",
    defaultMessage : "Application criteria"
  },
  newCriteria : {
    id : "mcore.newCriteria",
    defaultMessage : "New criteria"
  },
  whenInitialValue : {
    id : "mcore.whenInitialValue",
    defaultMessage : "When initial value"
  },
  whenNewValue : {
    id : "mcore.whenNewValue",
    defaultMessage : "when new value"
  },
  valueToApply : {
    id : "mcore.valueToApply",
    defaultMessage : "Value to apply"
  },
  productionIdentifier : {
    id : "mcore.productionIdentifier",
    defaultMessage : "Production identifier"
  },
  counterPeriodicity : {
    id : "mcore.counterPeriodicity",
    defaultMessage : "Counter periodicity"
  },
  infinite : {
    id : "mcore.infinite",
    defaultMessage : "Infinite"
  },
  initializationInProgress : {
    id : "mcore.initializationInProgress",
    defaultMessage : "Initialization in progress"
  },
  processingInProgress : {
    id : "mcore.processingInProgress",
    defaultMessage : "Processing"
  },
  PID : {
    id : "mcore.PID",
    defaultMessage : "Risk"
  },
  requiredMapField : {
    id : "mcore.requiredMapField",
    defaultMessage : "The \"Product name\" and \"Category\" fields are required"
  },
  copiedValues : {
    id : "mcore.copiedValues",
    defaultMessage : "Copied {count, plural, one {value} other {values}}"
  },
  operationAdd : {
    id : "mcore.operationAdd",
    defaultMessage : "Addition"
  },
  operationRemove : {
    id : "mcore.operationRemove",
    defaultMessage : "Substraction"
  },
  operation : {
    id : "mcore.operation",
    defaultMessage : "Operation"
  },
  zoneType : {
    id : "mcore.zoneType",
    defaultMessage : "Zone type"
  },
  numberOfDecimalPlaces : {
    id : "mcore.numberOfDecimalPlaces",
    defaultMessage : "Number of decimal places"
  },
  typeLineString : {
    id : "mcore.typeLineString",
    defaultMessage : "line"
  },
  typePoint : {
    id : "mcore.typePoint",
    defaultMessage : "point"
  },
  typePolygon : {
    id : "mcore.typePolygon",
    defaultMessage : "Polygon"
  },
  formIsErroneous : {
    id : "mcore.formIsErroneous",
    defaultMessage : "The form has errors."
  },
  validAndQuit : {
    id : "mcore.validAndContinue",
    defaultMessage : "Validate and continue entering"
  },
  validAndSave : {
    id : "mcore.validAndSave",
    defaultMessage : "Validate and save the distribution"
  },
  autogrow : {
    id : "mcore.autogrow",
    defaultMessage : "Automatic growth"
  },
  formattingOptions : {
    id : "mcore.formattingOptions",
    defaultMessage : "Formatting options"
  },
  newMetadata : {
    id : "mcore.newMetadata",
    defaultMessage : "New metadata"
  },
  expertiseConfiguration : {
    id : "mcore.expertiseConfiguration",
    defaultMessage : "Expertise configuration"
  },
  expertisesConfiguration : {
    id : "mcore.expertisesConfiguration",
    defaultMessage : "Expertises configuration"
  },
  subscriptionPeriod : {
    id : "mcore.subscriptionPeriod",
    defaultMessage : "Subscription validity period"
  },
  pageBreak : {
    id : "mcore.pageBreak",
    defaultMessage : "Page break"
  },
  controlStructures : {
    id : "mcore.controlStructures",
    defaultMessage : "Control structures"
  },
  configurationOf : {
    id : "mcore.configurationOf",
    defaultMessage : "Configuration of "
  },
  firstConfigurationVariable : {
    id : "mcore.firstConfigurationVariable",
    defaultMessage : "1st generation value defined in the product configuration :"
  },
  othersGenerationValues : {
    id : "mcore.othersGenerationValues",
    defaultMessage : "Others generation variable :"
  },
  offsetValue : {
    id : "mcore.offsetValue",
    defaultMessage : "Offset"
  },
  noGenerationVariable : {
    id : "mcore.noGenerationVariable",
    defaultMessage : "No generation variable available"
  },
  multiGenerationVariable : {
    id : "mcore.multiGenerationVariable",
    defaultMessage : "Multi generation variable"
  },
  generationVariables : {
    id : "mcore.generationVariable",
    defaultMessage : "Choice of generation variables"
  },
  multiGenerationConfiguration : {
    id : "mcore.multiGenerationConfiguration",
    defaultMessage : "Configuration of multi generation"
  },
  variableValues : {
    id : "mcore.variableValues",
    defaultMessage : "Variable value"
  },
  file : {
    id : "mcore.file",
    defaultMessage : "File"
  },
  generationOptions : {
    id : "mcore.generationOptions",
    defaultMessage : "Generation options"
  },
  noConfigurationOption : {
    id : "mcore.noConfigurationOption",
    defaultMessage : "No options to configure"
  },
  fixValueType : {
    id : "mcore.fixValueType",
    defaultMessage : "Fixed value"
  },
  variableValueType : {
    id : "mcore.variableValueType",
    defaultMessage : "Date format variable"
  },
  seasonalTask : {
    id : "mcore.seasonalTask",
    defaultMessage : "Seasonal task"
  },
  additionalRecipients : {
    id : "mcore.additionalRecipient",
    defaultMessage : "Additional recipients"
  },
  temporaryRecipientsAdded : {
    id : "mcore.temporaryRecipientsAdded",
    defaultMessage : "Temporary recipients added"
  },
  documentViewer : {
    id : "mcore.documentViewer",
    defaultMessage : "Viewer"
  },
  fileInformation : {
    id : "mcore.fileInformation",
    defaultMessage : "File information"
  },
  fileSize : {
    id : "mcore.fileSize",
    defaultMessage : "File size"
  },
  generatedMetadata : {
    id : "mcore.generatedMetadata",
    defaultMessage : "Generated metadata"
  },
  noViewerAvailable : {
    id : "mcore.noViewerAvailable",
    defaultMessage : "No viewer installed for document type: {type}"
  },
  productionTaskNumber : {
    id : "mcore.productionTaskNumber",
    defaultMessage : "Production task number"
  },
  mimeType : {
    id : "mcore.mimeType",
    defaultMessage : "MIME type"
  },
  docAndPreviewUnavailable : {
    id : "mcore.docAndPreviewUnavailable",
    defaultMessage : "Neither the document nor a preview are available"
  },
  docUnavailableButPreviewIs : {
    id : "mcore.docUnavailableButPreviewIs",
    defaultMessage : "The generated document is not available. Please find a preview shown below."
  },
  documentRawContent : {
    id : "mcore.documentRawContent",
    defaultMessage : "Document raw text content available"
  },
  generationInProgress : {
    id : "mcore.generationInProgress",
    defaultMessage : "Generation is in progress"
  },
  variationCondition : {
    id : "mcore.variationCondition",
    defaultMessage : "Condition on data variation (P2 - P1)"
  },
  restartProduction : {
    id : "mcore.restartProduction",
    defaultMessage : "Generate all documents"
  },
  restartGenerationTask : {
    id : "mcore.restartGenerationTask",
    defaultMessage : "Generate selected document"
  },
  initialize : {
    id : "mcore.initialize",
    defaultMessage : "Initialize"
  },
  allValuesOrSelect : {
    id : "mcore.allValuesOrSelect",
    defaultMessage : "All values or select..."
  },
  generationOptionsInformation : {
    id : "mcore.generationOptionsInformation",
    defaultMessage : "Select if necessary the generation values, by default (if empty) the generation will be done for all the values"
  },
  filterBy : {
    id : "mcore.filterBy",
    defaultMessage : "Filter by"
  },
  filteredBy : {
    id : "mcore.filteredBy",
    defaultMessage : "Filtered by"
  },
  distributionLink : {
    id : "mcore.distributionLink",
    defaultMessage : "Link to distribution supervision"
  },
  viewSendFile : {
    id : "mcore.viewSendFile",
    defaultMessage : "View the list of documents"
  },
  lastIssue : {
    id : "mcore.lastIssue",
    defaultMessage : "Last issue"
  },
  conditionType : {
    id : "mcore.conditionType",
    defaultMessage : "Condition type"
  },
  conditionInformation : {
    id : "mcore.conditionInformation",
    defaultMessage : "If several dimension values ​​are chosen, the condition must be met for at least one value"
  },
  comparator : {
    id : "mcore.comparator",
    defaultMessage : "Comparator"
  },
  sameDimensionInformation : {
    id : "mcore.sameDimensionInformation",
    defaultMessage : "For proper operation the 2 parameters must have the same dimensions"
  },
  sameDimensions : {
    id : "mcore.sameDimensions",
    defaultMessage : "Dimensions identical to the previous parameter"
  },
  errorGeneration : {
    id : "mcore.errorGeneration",
    defaultMessage : "Error while generating the document"
  },
  generationErrors : {
    id : "mcore.generationErrors",
    defaultMessage : "Generation errors"
  },
  previewErrors : {
    id : "mcore.previewErrors",
    defaultMessage : "Preview errors"
  },
  fillGeoWithSubstitutionColor : {
    id : "mcore.fillGeoWithSubstitutionColor",
    defaultMessage : "Fill area with substitution color"
  },
  colorBox : {
    id : "mcore.colorBox",
    defaultMessage : "Color box with substitution color"
  },
  resetView : {
    id : "mcore.resetView",
    defaultMessage : "Reset view"
  },
  saveView : {
    id : "mcore.saveView",
    defaultMessage : "Save view"
  },
  selectedArea : {
    id : "mcore.selectedArea",
    defaultMessage : "Selected area"
  },
  selectedAreas : {
    id : "mcore.selectedAreas",
    defaultMessage : "Selected areas"
  },
  otherSelectedArea : {
    id : "mcore.otherSelectedArea",
    defaultMessage : "Other selected area"
  },
  otherSelectedAreas : {
    id : "mcore.otherSelectedAreas",
    defaultMessage : "Other selected areas"
  },
  mainArea : {
    id : "mcore.mainArea",
    defaultMessage : "Main area"
  },
  approveDocument : {
    id : "mcore.approveDocument",
    defaultMessage : "Approve selected document"
  },
  displayErrorsOnDocument : {
    id : "mcore.displayErrorsOnDocument",
    defaultMessage : "Display errors on the document"
  },
  mapHeight : {
    id : "mcore.mapHeight",
    defaultMessage : "Map height"
  },
  layerName : {
    id : "mcore.layerName",
    defaultMessage : "Layer name"
  },
  transparency : {
    id : "mcore.transparency",
    defaultMessage : "Transparency"
  },
  dataDisplay : {
    id : "mcore.dataDisplay",
    defaultMessage : "Data display"
  },
  tooltip : {
    id : "mcore.tooltip",
    defaultMessage : "Tooltip"
  },
  statusBar : {
    id : "mcore.statusBar",
    defaultMessage : "Status bar"
  },
  inputMode : {
    id : "mcore.inputMode",
    defaultMessage : "Input mode"
  },
  inputByArea : {
    id : "mcore.inputByArea",
    defaultMessage : "Input by area"
  },
  inputByParameter : {
    id : "mcore.inputByParameter",
    defaultMessage : "Input by parameter"
  },
  generationErrorReport : {
    id : "mcore.generationErrorReport",
    defaultMessage : "Generation's error report"
  },
  localHours : {
    id : "mcore.localHours",
    defaultMessage : "local hours"
  },
  viewGenerationErrors : {
    id : "mcore.viewGenerationErrors",
    defaultMessage : "View generation errors"
  },
  viewPreviewErrors : {
    id : "mcore.viewPreviewErrors",
    defaultMessage : "View preview errors"
  },
  generatedDocument : {
    id : "mcore.generatedDocument",
    defaultMessage : "Generated document"
  },
  dynamicPreview : {
    id : "mcore.dynamicPreview",
    defaultMessage : "Dynamic preview"
  },
  bgStriped : {
    id : "mcore.bgStriped",
    defaultMessage : "Color every other row"
  },
  noValue : {
    id : "mcore.noValue",
    defaultMessage : "No value"
  },
  initMenu : {
    id : "mcore.initMenu",
    defaultMessage : "Individual field initialization"
  },
  facultativeData : {
    id : "mcore.facultativeData",
    defaultMessage : "Facultative data"
  },
  additionalDisplayMode : {
    id : "mcore.additionalDisplayMode",
    defaultMessage : "Additional display mode"
  },
  mixed : {
    id : "mcore.mixed",
    defaultMessage : "Mixed"
  },
  mixedDefaultColumns : {
    id : "mcore.mixedModeColumns",
    defaultMessage : "Mixed mode default columns"
  },
  geosLabel : {
    id : "mcore.geosLabel",
    defaultMessage : "Geos label"
  },
  addGeosLabel : {
    id : "mcore.addGeosLabel",
    defaultMessage : "Add geos label"
  },
  geosGroup : {
    id : "mcore.geosGroup",
    defaultMessage : "Group geos"
  },
  lastUpdatedBy : {
    id : "mcore.lastUpdatedBy",
    defaultMessage : "Last updated by"
  },
  px : {
    id : "mcore.px",
    defaultMessage : "px"
  },
  ratio : {
    id : "mcore.ratio",
    defaultMessage : "ratio"
  },
  fixPopover : {
    id : "mcore.fixPopover",
    defaultMessage : "Fix popover"
  },
  hidePopover : {
    id : "mcore.hidePopover",
    defaultMessage : "Hide popover"
  },
  standard : {
    id : "mcore.standard",
    defaultMessage : "Standard"
  },
  alwaysDisplay : {
    id : "mcore.alwaysDisplay",
    defaultMessage : "Always display"
  },
  localFile : {
    id : "mcore.localFile",
    defaultMessage : "Local file"
  },
  configurationLocal : {
    id : "mcore.configurationLocal",
    defaultMessage : "Local file configuration"
  },
  replacedData : {
    id : "mcore.replacedData",
    defaultMessage : "Replaced data"
  },
  validateAndQuit : {
    id : "mcore.validateAndQuit",
    defaultMessage : "Validate and quit"
  },
  dataSaved : {
    id : "mcore.dataSaved",
    defaultMessage : "Data has been saved"
  },
  record : {
    id : "mcore.record",
    defaultMessage : "Record"
  },
  expertiseValidated : {
    id : "mcore.expertiseValidated",
    defaultMessage : "Expertise validated"
  },
  disableForTags : {
    id : "mcore.disableForTags",
    defaultMessage : "Disable for tag(s)"
  },
  createTasks : {
    id : "mcore.createTasks",
    defaultMessage : "Create tasks"
  },
  createTasksSuccess : {
    id : "mcore.createTasksSuccess",
    defaultMessage : "Tasks creation was executed successfully"
  },
  fileTags : {
    id : "mcore.fileTags",
    defaultMessage : "The following tags can be added in the file name:"
  },
  transmetTags : {
    id : "mcore.transmetTags",
    defaultMessage : "Date in TRANSMET format DDHHMM"
  },
  variableTags : {
    id : "mcore.variableTags",
    defaultMessage : "Variable part of the name (if a file contains a time for example that we cannot know)"
  },
  dateTags : {
    id : "mcore.dateTags",
    defaultMessage : "If the name of the file depends on a date, use the tags %Y %m %d... Example: file_%Y%m%d.txt"
  },
  linkInfoTags : {
    id : "mcore.linkInfoTags",
    defaultMessage : "Complete list of tags : "
  },
  productionPeriod : {
    id : "mcore.productionPeriod",
    defaultMessage : "Start production if the event takes place"
  },
  outOfPeriod : {
    id : "mcore.outOfPeriod",
    defaultMessage : "Outside of the monitoring period"
  },
  inPeriod : {
    id : "mcore.inPeriod",
    defaultMessage : "During the monitoring period"
  },
  inAndOutPeriod : {
    id : "mcore.inAndOutPeriod",
    defaultMessage : "During and outside the monitoring period"
  },
  viewer : {
    id : "mcore.viewer",
    defaultMessage : "Viewer"
  },
  createFailure : {
    id : "mcore.createFailure",
    defaultMessage : "An error occured when trying to create this item"
  },
  displayGeoLabel : {
    id : "mcore.displayGeoLabel",
    defaultMessage : "Display geo label"
  },
  totalTime : {
    id : "mcore.totalTime",
    defaultMessage : "Total elapsed time"
  },
  holdTime : {
    id : "mcore.holdTime",
    defaultMessage : "Hold time"
  },
  generationTime : {
    id : "mcore.generationTime",
    defaultMessage : "Generation time"
  },
  approveErrorDocuments : {
    id : "mcore.approveErrorDocuments",
    defaultMessage : "Approve all documents in error"
  },
  externalFlowError4 : {
    id : "mcore.externalFlowError4",
    defaultMessage : "File not found."
  },
  coupledWithSourceParam : {
    id : "mcore.coupledWithSourceParam",
    defaultMessage : "Coupled with source parameter"
  },
  uncoupledWithSourceParam : {
    id : "mcore.uncoupledWithSourceParam",
    defaultMessage : "Uncoupled with source parameter"
  },
  coupledWithDestParam : {
    id : "mcore.coupledWithDestParam",
    defaultMessage : "Coupled with destination parameter"
  },
  uncoupledWithDestParam : {
    id : "mcore.uncoupledWithDestParam",
    defaultMessage : "Uncoupled with destination parameter"
  },
  uncouple : {
    id : "mcore.uncouple",
    defaultMessage : "Uncouple"
  },
  onlyForPreview : {
    id : "mcore.onlyForPreview",
    defaultMessage : "Only for Preview"
  },
  warningIsDimensionDateFalse : {
    id : "mcore.warningIsDimensionDateFalse",
    defaultMessage : "If not checked, today's date will be used."
  },
  recouple : {
    id : "mcore.recouple",
    defaultMessage : "Recouple"
  },
  pattern : {
    id : "mcore.pattern",
    defaultMessage : "Pattern"
  },
  badPattern : {
    id : "mcore.badPattern",
    defaultMessage : "Bad pattern"
  },
  rowCountExceeded : {
    id : "mcore.rowCountExceeded",
    defaultMessage : "Text must not exceed {rowCount} lines"
  },
  possibleDateTags : {
    id : "mcore.possibleDateTags",
    defaultMessage : "Use this kind of tags : %Y(year) %m(month) %d(day) %H(hours) %M(minutes) %S(secondes). Example : %d/%m/%y"
  },
  distributionDone : {
    id : "mcore.distributionDone",
    defaultMessage : "Distribution done"
  },
  distributionError : {
    id : "mcore.distributionError",
    defaultMessage : "Distribution in error"
  },
  distributionPartialError : {
    id : "mcore.distributionPartialError",
    defaultMessage : "Distribution partially done"
  },
  noAlert : {
    id : "mcore.noAlert",
    defaultMessage : "No alert"
  },
  productNotSupported : {
    id : "mcore.productNotSupported",
    defaultMessage : "Product not supported"
  },
  newIdentifier : {
    id : "mcore.newIdentifier",
    defaultMessage : "New identifier"
  },
  difmetResponse : {
    id : "mcore.difmetResponse",
    defaultMessage : "Difmet response received "
  },
  deleteSelectedPOI : {
    id : "mcore.deleteSelectedPOI",
    defaultMessage : "Delete selected POI(s)"
  },
  confirmDeleteItems : {
    id : "mcore.confirmDeleteItems",
    defaultMessage : "Are you sure you want to delete the selected point(s) ?"
  },
  deleteAllItems : {
    id : "mcore.deleteAllItems",
    defaultMessage : "Are you sure you want to delete all points in this list ?"
  },
  editItems : {
    id : "mcore.editItems",
    defaultMessage : "Edition of selected point(s)"
  },
  editSelectedPOI : {
    id : "mcore.editSelectedPOI",
    defaultMessage : "Edit selected POI(s)"
  },
  relativeValue : {
    id : "mcore.relativeValue",
    defaultMessage : "Relative"
  },
  absoluteValue : {
    id : "mcore.absoluteValue",
    defaultMessage : "Absolute"
  },
  flexible : {
    id : "mcore.flexible",
    defaultMessage : "Flexible"
  },
  strokeOpacity : {
    id : "mcore.strokeOpacity",
    defaultMessage : "Stroke opacity"
  },
  fillOpacity : {
    id : "mcore.fillOpacity",
    defaultMessage : "Fill opacity"
  },
  backgroundOpacity : {
    id : "mcore.backgroundOpacity",
    defaultMessage : "Background opacity"
  },
  duplicated : {
    id : "mcore.duplicated",
    defaultMessage : "duplicated"
  },
  sumAggregate : {
    id : "mcore.sumAggregate",
    defaultMessage : "SUM"
  },
  convertToUppercase : {
    id : "mcore.convertToUppercase",
    defaultMessage : "Convert to uppercase"
  },
  rescue_alert_time_duration_explanation : {
    id : "mcore.rescue_alert_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>ALERT</b> and <b>DUE</b> time"
  },
  rescue_due_time_duration_explanation : {
    id : "mcore.rescue_due_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>START</b> and <b>DUE</b> time"
  },
  rescue_production_time_duration_explanation : {
    id : "mcore.rescue_production_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>RESCUE PRODUCTION</b> and <b>DUE</b> time"
  },
  rescue_alert_time_duration : {
    id : "mcore.rescue_alert_time_duration",
    defaultMessage : "Alert time"
  },
  rescue_due_time_duration : {
    id : "mcore.rescue_due_time_duration",
    defaultMessage : "Due time"
  },
  rescue_end_time_duration : {
    id : "mcore.rescue_end_time_duration",
    defaultMessage : "End time"
  },
  serieType : {
    id : "mcore.serieType",
    defaultMessage : "Serie type"
  },
  errorReadingFile : {
    id : "mcore.errorReadingFile",
    defaultMessage : "Error during file reading"
  },
  fontNotFound : {
    id : "mcore.fontNotFound",
    defaultMessage : "Required font NotoSerif-Regular-normal.ttf not found. Some characters may be not encoded. Please contact an administrator."
  },
  linkSeparator : {
    id : "mcore.linkSeparator",
    defaultMessage : "Several urls can be entered by separating them with a semicolon"
  },
  showTimeList : {
    id : "mcore.showTimeList",
    defaultMessage : "Show time list"
  },
  timeListPlace : {
    id : "mcore.timeListPlace",
    defaultMessage : "Time list place"
  },
  up : {
    id : "mcore.up",
    defaultMessage : "Up"
  },
  down : {
    id : "mcore.down",
    defaultMessage : "Down"
  },
  digitalForecastUnavailable : {
    id : "mcore.digitalForecastUnavailable",
    defaultMessage : "Digital Forecast service is unavailable."
  },
  packageInContainer : {
    id : "mcore.packageInContainer",
    defaultMessage : "Package in container"
  },
  show : {
    id : "mcore.show",
    defaultMessage : "Show"
  },
  hiddenElement : {
    id : "mcore.hiddenElement",
    defaultMessage : "Hidden element"
  },
  dateVariable : {
    id : "mcore.dateVariable",
    defaultMessage : "Date variable"
  },
  conditionDateVariable : {
    id : "mcore.conditionDateVariable",
    defaultMessage : "Condition on date variable"
  },
  conditionDateValue : {
    id : "mcore.conditionDateValue",
    defaultMessage : "Condition on date value"
  },
  maxOf : {
    id : "mcore.maxOf",
    defaultMessage : "MAXIMUM of"
  },
  minOf : {
    id : "mcore.minOf",
    defaultMessage : "MINIMUM of"
  },
  averageOf : {
    id : "mcore.averageOf",
    defaultMessage : "AVERAGE of"
  },
  sumOf : {
    id : "mcore.sumOf",
    defaultMessage : "SUM of"
  },
  oppositeAllConditions : {
    id : "mcore.oppositeAllConditions",
    defaultMessage : "Conditions met if at least one of the following conditions is not verified : "
  },
  oppositeOneCondition : {
    id : "mcore.oppositeOneCondition",
    defaultMessage : "Conditions met if none of the following conditions are true : "
  },
  allConditions : {
    id : "mcore.allConditions",
    defaultMessage : "Conditions met if all of the following conditions are true : "
  },
  oneCondition : {
    id : "mcore.oneCondition",
    defaultMessage : "Conditions met if at least one of the following conditions is true : "
  },
  definedConditionRule : {
    id : "mcore.definedConditionRule",
    defaultMessage : "Defined condition : "
  },
  onVariation : {
    id : "mcore.onVariation",
    defaultMessage : "on variation"
  },
  activeCondition : {
    id : "mcore.activeCondition",
    defaultMessage : "active condition"
  },
  activeConditions : {
    id : "mcore.activeConditions",
    defaultMessage : "active conditions"
  },
  noDefinedCondition : {
    id : "mcore.noDefinedCondition",
    defaultMessage : "no defined condition"
  },
  exportError : {
    id : "mcore.exportError",
    defaultMessage : "Results could not be exported"
  },
  metadataGeo : {
    id : "mcore.metadataGeo",
    defaultMessage : "Metadata geo"
  },
  valueMinutes : {
    id : "mcore.valueMinutes",
    defaultMessage : "{minutes} minutes"
  },
  searchStringInAllContent : {
    id : "mcore.searchStringInAllContent",
    defaultMessage : "Search for string in all content"
  },
  productionSearch : {
    id : "mcore.productionSearch",
    defaultMessage : "The search is carried out on the names of the productions and on the recipient contacts (name, description and contact details)"
  },
  supervisionDiffusionsSearch : {
    id : "mcore.supervisionDiffusionsSearch",
    defaultMessage : "The search is carried out on the names of the contacts and their coordinates."
  },
  gridTemplate : {
    id : "mcore.gridTemplate",
    defaultMessage : "Grid template"
  },
  paiTemplate : {
    id : "mcore.paiTemplate",
    defaultMessage : "PAI template"
  },
  serverConfig : {
    id : "mcore.serverConfig",
    defaultMessage : "Server configuration"
  },
  refreshNonEditableData : {
    id : "mcore.refreshNonEditableData",
    defaultMessage : "Refresh non editable data"
  },
  imgType : {
    id : "mcore.imgType",
    defaultMessage : "Image type"
  },
  userPreferences : {
    id : "mcore.userPreferences",
    defaultMessage : "User preferences"
  },
  validateAndGenerate : {
    id : "mcore.validateAndGenerate",
    defaultMessage : "Validate and generate"
  },
  approveDocumentAndSpread : {
    id : "mcore.approveDocumentAndSpread",
    defaultMessage : "Approve all documents & Spread"
  },
  distributionInProgress : {
    id : "mcore.distributionInProgress",
    defaultMessage : "Distribution in progress"
  },
  approvalInProgress : {
    id : "mcore.approvalInProgress",
    defaultMessage : "Approval in progress"
  },
  drawingArea : {
    id : "mcore.drawingArea",
    defaultMessage : "Drawing area"
  },
  dataDrawing : {
    id : "mcore.dataDrawing",
    defaultMessage : "Data drawing"
  },
  texts : {
    id : "mcore.texts",
    defaultMessage : "Texts"
  },
  images : {
    id : "mcore.images",
    defaultMessage : "Images"
  },
  insertImage : {
    id : "mcore.insertImage",
    defaultMessage : "Insert image"
  },
  insertText : {
    id : "mcore.insertText",
    defaultMessage : "Insert text"
  },
  opacity : {
    id : "mcore.opacity",
    defaultMessage : "Opacity"
  },
  strokeWidth : {
    id : "mcore.strokeWidth",
    defaultMessage : "Stroke width"
  },
  activeFilters : {
    id : "mcore.activeFilters",
    defaultMessage : "Active filters"
  },
  importMetronomeProduct : {
    id : "mcore.importMetronomeProduct",
    defaultMessage : "Import metronome product"
  },
  importOkrProducts : {
    id : "mcore.importOkrProducts",
    defaultMessage : "Import Okapi products"
  },
  fieldDisplayOptions : {
    id : "mcore.fieldDisplayOptions",
    defaultMessage : "Field display Options"
  },
  fieldValidation : {
    id : "mcore.fieldValidation",
    defaultMessage : "Field validation"
  },
  loadingInProgress : {
    id : "mcore.loadingInProgress",
    defaultMessage : "Loading in progress"
  },
  hideInLegend : {
    id : "mcore.hideInLegend",
    defaultMessage : "Hide in legend"
  },
  hideByDefault : {
    id : "mcore.hideByDefault",
    defaultMessage : "Hide by default"
  },
  visualizeProduct : {
    id : "mcore.visualizeProduct",
    defaultMessage : "Visualize product(s)"
  },
  updateWithParam : {
    id : "mcore.updateWithParam",
    defaultMessage : "Update when modifying parameter"
  },
  startTime : {
    id : "mcore.startTime",
    defaultMessage : "Start time"
  },
  endTime : {
    id : "mcore.endTime",
    defaultMessage : "End time"
  },
  referenceMinute : {
    id : "mcore.referenceMinute",
    defaultMessage : "Reference minute"
  },
  rowHeader : {
    id : "mcore.rowHeader",
    defaultMessage : "Row header"
  },
  errorRestoringDocument : {
    id : "mcore.errorRestoringDocument",
    defaultMessage : "An error occurred while restoring the document"
  },
  restoreDocumentOk : {
    id : "mcore.restoreDocumentOk",
    defaultMessage : "Document restoration complete"
  },
  autosaveFailed : {
    id : "mcore.autosaveFailed",
    defaultMessage : "Autosave failed"
  },
  autoSavedDocumentFound : {
    id : "mcore.autoSavedDocumentFound",
    defaultMessage : "An autosaved document of {day} at {hour} has been found. Do you want to recover it ?"
  },
  restoreOldVersion : {
    id : "mcore.restoreOldVersion",
    defaultMessage : "Restore and old version"
  },
  confirmRestoreOldVersion : {
    id : "mcore.confirmRestoreOldVersion",
    defaultMessage : "Are you sure you want to restore content of {day} at {hour} ?"
  },
  editableAreaWithNoLabelError : {
    id : "mcore.editableAreaWithNoLabelError",
    defaultMessage : "Some editable area has no label"
  },
  compareBooleanValue : {
    id : "mcore.compareBooleanValue",
    defaultMessage : "To compare a boolean value, consider true=1 and false=0"
  },
  applyBooleanValue : {
    id : "mcore.applyBooleanValue",
    defaultMessage : "To apply a boolean value, consider 1=true and 0=false"
  },
  isSeasonalDistribution : {
    id : "mcore.isSeasonalDistribution",
    defaultMessage : "Seasonal distribution"
  },
  useInitLabel : {
    id : "mcore.useInitLabel",
    defaultMessage : "Use initializations labels"
  },
  maxNumber : {
    id : "mcore.maxNumber",
    defaultMessage : "Max number"
  },
  ignore : {
    id : "mcore.ignore",
    defaultMessage : "Ignore"
  },
  merge : {
    id : "mcore.merge",
    defaultMessage : "Merge"
  },
  blockingConflicts : {
    id : "mcore.blockingConflicts",
    defaultMessage : "Blocking conflicts"
  },
  nonBlockingConflicts : {
    id : "mcore.nonBlockingConflicts",
    defaultMessage : "Non blocking conflicts"
  },
  old : {
    id : "mcore.old",
    defaultMessage : "Old"
  },
  both : {
    id : "mcore.both",
    defaultMessage : "Both"
  },
  newName : {
    id : "mcore.newName",
    defaultMessage : "New name"
  },
  pointWithSameProperty : {
    id : "mcore.pointWithSameProperty",
    defaultMessage : "{count} other {count, plural, one {point} other {points}} with same {property}"
  },
  generalImportConfig : {
    id : "mcore.generalImportConfig",
    defaultMessage : "General import configuration"
  },
  mergeConfigNotice : {
    id : "mcore.mergeConfigNotice",
    defaultMessage : "When merging, you can choose whether the property comes from the imported point or from an existing point, or both (except for the coordinates)"
  },
  blockingConflictNotice : {
    id : "mcore.blockingConflictNotice",
    defaultMessage : "Blocking conflicts occur when you try to import a point with an existing name in the database"
  },
  nonBlockingConflictNotice : {
    id : "mcore.nonBlockingConflictNotice",
    defaultMessage : "Non-blocking conflicts occur when you try to import a point with similar properties to one or more existing points in the database"
  },
  namedPointWithSameProperty : {
    id : "mcore.namedPointWithSameProperty",
    defaultMessage : "The {count, plural, one {point} other {points}} {geos} {count, plural, one {has} other {have}} the same {property}"
  },
  pointToImport : {
    id : "mcore.pointToImport",
    defaultMessage : "Point to import"
  },
  existingPoint : {
    id : "mcore.existingPoint",
    defaultMessage : "Existing point"
  },
  actionToDo : {
    id : "mcore.actionToDo",
    defaultMessage : "Action to do"
  },
  alreadyExistedGeo : {
    id : "mcore.alreadyExistedGeo",
    defaultMessage : "A point named {name} already exist"
  },
  confirmImport : {
    id : "mcore.confirmImport",
    defaultMessage : "Are you sure you want to import {count} points ?"
  },
  noNewPointToImport : {
    id : "mcore.noNewPointToImport",
    defaultMessage : "There are no new points to import"
  },
  identicalPointsInDatabase : {
    id : "mcore.identicalPointsInDatabase",
    defaultMessage : "Identical points in the database"
  },
  identicalPointsNotice : {
    id : "mcore.identicalPointsNotice",
    defaultMessage : "The following points are already in the database"
  },
  newPoints : {
    id : "mcore.newPoints",
    defaultMessage : "New points"
  },
  newPointsNotice : {
    id : "mcore.newPointsNotice",
    defaultMessage : "The following points will be added to the database as is"
  },
  noNewPointNotice : {
    id : "mcore.noNewPointNotice",
    defaultMessage : "This file does not contain any new points"
  },
  pointsImportOk : {
    id : "mcore.pointsImportOk",
    defaultMessage : "The import of points was successful"
  },
  namesAreTheSame : {
    id : "mcore.namesAreTheSame",
    defaultMessage : "Names are the same"
  },
  imported : {
    id : "mcore.imported",
    defaultMessage : "Imported"
  },
  current : {
    id : "mcore.current",
    defaultMessage : "Current"
  },
  actionWhenConflict : {
    id : "mcore.actionWhenConflict",
    defaultMessage : "Action in case of conflict"
  },
  stillConflicts : {
    id : "mcore.stillConflicts",
    defaultMessage : "Changes made generate new conflicts"
  },
  unresolvedConflict : {
    id : "mcore.unresolvedConflict",
    defaultMessage : "Unresolved {count, plural, one {conflict} other {conflicts}}"
  },
  importOfPointsInProgress : {
    id : "mcore.importOfPointsInProgress",
    defaultMessage : "Import of {count} points in progress"
  },
  pleaseWait : {
    id : "mcore.pleaseWait",
    defaultMessage : "Please wait, this may take a while"
  },
  limitOrNotNumberOfVarValues : {
    id : "mcore.limitOrNotNumberOfVarValues",
    defaultMessage : "Limit or not number of variable values displayed"
  },
  maxNumberOfVarValuesDisplayed : {
    id : "mcore.maxNumberOfVarValuesDisplayed",
    defaultMessage : "Maximal number of variable values displayed"
  },
  confirmShowAllVarValues : {
    id : "mcore.confirmShowAllVarValues",
    defaultMessage : "Are you sure you want to display up to {count} variable values ?"
  },
  clipping : {
    id : "mcore.clipping",
    defaultMessage : "Clipping"
  },
  removeClipping : {
    id : "mcore.removeClipping",
    defaultMessage : "Remove clipping"
  },
  okapiImport : {
    id : "mcore.okapiImport",
    defaultMessage : "Okapi import"
  },
  geosNumber : {
    id : "mcore.geosNumber",
    defaultMessage : "Number of points in the list"
  },
  wmsLayer : {
    id : "mcore.wmsLayer",
    defaultMessage : "WMS Layer"
  },
  geojsonLayer : {
    id : "mcore.geojsonLayer",
    defaultMessage : "GeoJSON Layer"
  },
  confirmExportSmallSize : {
    id : "mcore.confirmExportSmallSize",
    defaultMessage : "Are you sure you want to export {count} elements ?"
  },
  confirmExportMediumSize : {
    id : "mcore.confirmExportMediumSize",
    defaultMessage : "Are you sure you want to export {count} elements ? The request may take a while."
  },
  confirmExportLargeSize : {
    id : "mcore.confirmExportLargeSize",
    defaultMessage : "Are you sure you want to export {count} elements ? The request may take a while or fail."
  },
  exportSuccess : {
    id : "mcore.exportSuccess",
    defaultMessage : "Export success"
  },
  exportFailure : {
    id : "mcore.exportFailure",
    defaultMessage : "Export failed, try again or select fewer items"
  },
  overlaysOn : {
    id : "mcore.overlaysOn",
    defaultMessage : "Overlays on {item}"
  },
  horizontalOffset : {
    id : "mcore.horizontalOffset",
    defaultMessage : "Horizontal offset"
  },
  verticalOffset : {
    id : "mcore.verticalOffset",
    defaultMessage : "Vertical offset"
  },
  scale : {
    id : "mcore.scale",
    defaultMessage : "Scale"
  },
  addFeatures : {
    id : "mcore.addFeatures",
    defaultMessage : "Add features"
  },
  stroke : {
    id : "mcore.stroke",
    defaultMessage : "Stroke"
  },
  fill : {
    id : "mcore.fill",
    defaultMessage : "Fill"
  },
  deletedDocuments : {
    id : "mcore.deletedDocuments",
    defaultMessage : "The documents are no longer accessible because the distribution is too old."
  },
  pdfDocuments : {
    id : "mcore.pdfDocuments",
    defaultMessage : "PDF document(s)"
  },
  round : {
    id : "mcore.round",
    defaultMessage : "Round"
  },
  butt : {
    id : "mcore.butt",
    defaultMessage : "Butt"
  },
  confirmDeleteMedias : {
    id : "mcore.confirmDeleteMedias",
    defaultMessage : "Are you sure you want to delete the selected media(s) ?"
  },
  dynamicList : {
    id : "mcore.dynamicList",
    defaultMessage : "Dynamic list"
  },
  bevel : {
    id : "mcore.bevel",
    defaultMessage : "Bevel"
  },
  miter : {
    id : "mcore.miter",
    defaultMessage : "Miter"
  },
  lineCap : {
    id : "mcore.lineCap",
    defaultMessage : "Line cap"
  },
  lineJoin : {
    id : "mcore.lineJoin",
    defaultMessage : "Line join"
  },
  lineDash : {
    id : "mcore.lineDash",
    defaultMessage : "Line dash"
  },
  lineDashOffset : {
    id : "mcore.lineDashOffset",
    defaultMessage : "Line dash offset"
  },
  miterLimit : {
    id : "mcore.miterLimit",
    defaultMessage : "Miter limit"
  },
  strokeColor : {
    id : "mcore.strokeColor",
    defaultMessage : "Stroke color"
  },
  radius : {
    id : "mcore.radius",
    defaultMessage : "Radius"
  },
  textPlacement : {
    id : "mcore.textPlacement",
    defaultMessage : "Text placement"
  },
  bannerLinkConfiguration : {
    id : "mcore.bannerLinkConfiguration",
    defaultMessage : "Banner link configuration "
  },
  imageLink : {
    id : "mcore.imageLink",
    defaultMessage : "Image link"
  },
  possibleCustomConfig : {
    id : "mcore.possibleCustomConfig",
    defaultMessage : "Possible custom configuration"
  },
  lineBreak : {
    id : "mcore.lineBreak",
    defaultMessage : "Line break"
  },
  allDataMonitoring : {
    id : "mcore.allDataMonitoring",
    defaultMessage : "All data monitoring"
  },
  evolution : {
    id : "mcore.evolution",
    defaultMessage : "Evolution"
  },
  instructions : {
    id : "mcore.instructions",
    defaultMessage : "Instructions"
  },
  ack : {
    id : "mcore.ack",
    defaultMessage : "ACK"
  },
  tableView : {
    id : "mcore.tableView",
    defaultMessage : "Table view"
  },
  mapView : {
    id : "mcore.mapView",
    defaultMessage : "Map view"
  },
  mixedView : {
    id : "mcore.mixedView",
    defaultMessage : "Mixed view"
  },
  productionBased : {
    id : "mcore.productionBased",
    defaultMessage : "Production"
  },
  complexOperation : {
    id : "mcore.complexOperation",
    defaultMessage : "Complex operation"
  },
  backToLine : {
    id : "mcore.backToLine",
    defaultMessage : "Back to line"
  },
  actionType : {
    id : "mcore.actionType",
    defaultMessage : "Action type"
  },
  instruction : {
    id : "mcore.instruction",
    defaultMessage : "Instruction"
  },
  block : {
    id : "mcore.block",
    defaultMessage : "Block"
  },
  nothing : {
    id : "mcore.nothing",
    defaultMessage : "Nothing"
  },
  displayMonitoringConfig : {
    id : "mcore.displayMonitoringConfig",
    defaultMessage : "Display configuration in the monitoring"
  },
  customerCall : {
    id : "mcore.customerCall",
    defaultMessage : "Customer call"
  },
  startProduction : {
    id : "mcore.startProduction",
    defaultMessage : "Start production"
  },
  parameterDisplayStyle : {
    id : "mcore.parameterDisplayStyle",
    defaultMessage : "Form of representation of the parameter value"
  },
  displayParameter : {
    id : "mcore.displayParameter",
    defaultMessage : "Display parameter"
  },
  missingDimensions : {
    id : "mcore.missingDimensions",
    defaultMessage : "Missing dimensions : {missingDimensions}"
  },
  atLeastOneLineRequired : {
    id : "mcore.atLeastOneLineRequired",
    defaultMessage : "At least one line required"
  },
  unknowInstruction : {
    id : "mcore.unknowInstruction",
    defaultMessage : "Unknow instruction"
  },
  noArea : {
    id : "mcore.noArea",
    defaultMessage : "No area"
  },
  dataQueryInProgress : {
    id : "mcore.dataQueryInProgress",
    defaultMessage : "Data query in progress"
  },
  confirmAckDataMonitoring : {
    id : "mcore.confirmAckDataMonitoring",
    defaultMessage : "Are you sure you want to acknowledge this monitoring?"
  },
  domainConfigHasChanged : {
    id : "mcore.domainConfigHasChanged",
    defaultMessage : "The way to configure a domain has changed. To modify it, you must create a variable of type domain"
  },
  domainReadOnly : {
    id : "mcore.domainReadOnly",
    defaultMessage : "Preview of the current domain"
  },
  areasLabel : {
    id : "mcore.areasLabel",
    defaultMessage : "Area(s)"
  },
  updateDate : {
    id : "mcore.updateDate",
    defaultMessage : "Update date (name)"
  },
  unknowDate : {
    id : "mcore.unknowDate",
    defaultMessage : "Unknow date"
  },
  selectProductType : {
    id : "mcore.selectProductType",
    defaultMessage : "Select a product type"
  },
  productsData : {
    id : "mcore.productsData",
    defaultMessage : "Data products"
  },
  layoutProducts : {
    id : "mcore.layoutProducts",
    defaultMessage : "Layout products"
  },
  othersProducts : {
    id : "mcore.othersProducts",
    defaultMessage : "Others products"
  },
  dataMonitoringName : {
    id : "mcore.dataMonitoringName",
    defaultMessage : "Data monitoring name"
  },
  lastUpdateDataMonitoringDate : {
    id : "mcore.lastUpdateDataMonitoringDate",
    defaultMessage : "Last data monitoring update date"
  },
  amendment_time_duration_explanation : {
    id : "mcore.amendment_time_duration_explanation",
    defaultMessage : "Duration (in minutes) between <b>END</b> and <b>AMENDMENT</b> time. No time limit if empty"
  },
  end_time_amendment_explanation : {
    id : "mcore.end_time_amendment_explanation",
    defaultMessage : "After this time, the task cannot be amended"
  },
  end_time_amendment_duration : {
    id : "mcore.end_time_amendment_duration",
    defaultMessage : "End amendment time"
  },
  amendment_time_duration : {
    id : "mcore.amendment_time_duration",
    defaultMessage : "Hours of end/amendment"
  },
  lastCalculationTime : {
    id : "mcore.lastCalculationTime",
    defaultMessage : "Last calculation time"
  },
  currentColor : {
    id : "mcore.currentColor",
    defaultMessage : "Color of the risk distributed to the customer"
  },
  maxCurrentColor : {
    id : "mcore.maxCurrentColor",
    defaultMessage : "Color of the max risk distributed to the customer"
  },
  unknownMaxCurrentColor : {
    id : "mcore.unknownMaxCurrentColor",
    defaultMessage : "Unknown color of the max risk distributed to the customer"
  },
  acknowledgeMonitoring : {
    id : "mcore.acknowledgeMonitoring",
    defaultMessage : "Acknowledge monitoring"
  },
  maxCalculatedColor : {
    id : "mcore.maxCalculatedColor",
    defaultMessage : "Color of the max risk calculated"
  },
  allowCopyingOfTabValues : {
    id : "mcore.allowCopyingOfTabValues",
    defaultMessage : "Allow copying of tab values"
  },
  copyValuesTo : {
    id : "mcore.copyValuesTo",
    defaultMessage : "Copy values to"
  },
  allOtherTabs : {
    id : "mcore.allOtherTabs",
    defaultMessage : "All other tabs"
  },
  isShortcutsActive : {
    id : "mcore.isShortcutsActive",
    defaultMessage : "Shortcuts for text fields"
  },
  clickToDisplayInTable : {
    id : "mcore.clickToDisplayInTable",
    defaultMessage : "Click to display in table"
  },
  startMinuteMonitoring : {
    id : "mcore.startMinuteMonitoring",
    defaultMessage : "Start at the minute"
  },
  dataMonitoringFrequency : {
    id : "mcore.dataMonitoringFrequency",
    defaultMessage : "Update frequency"
  },
  lastExpertiseDate : {
    id : "mcore.lastExpertiseDate",
    defaultMessage : "Date of last entry validated by the forecaster"
  },
  noComparisonValue : {
    id : "mcore.noComparisonValue",
    defaultMessage : "Missing comparison data"
  },
  instagram : {
    id : "mcore.instagram",
    defaultMessage : "Instagram"
  },
  dataMonitoringInfo : {
    id : "mcore.dataMonitoringInfo",
    defaultMessage : "Data monitoring informations"
  },
  lastUpdateCalculatedDataDate : {
    id : "mcore.lastUpdateCalculatedDataDate",
    defaultMessage : "Date of availability of the calculated data"
  },
  lastUpdateExpertisedDataDate : {
    id : "mcore.lastUpdateExpertisedDataDate",
    defaultMessage : "Date of availability of the expertised data"
  },
  calculatedPeriod : {
    id : "mcore.calculatedPeriod",
    defaultMessage : "Calculated data period"
  },
  ftpUseSFTP : {
    id : "mcore.ftpUseSFTP",
    defaultMessage : "Use SFTP"
  },
  centerMap : {
    id : "mcore.centerMap",
    defaultMessage : "Center the map on the areas concerned"
  },
  refreshMap : {
    id : "mcore.refreshMap",
    defaultMessage : "Refresh the map"
  },
  showMore : {
    id : "mcore.showMore",
    defaultMessage : "Show more"
  },
  showLess : {
    id : "mcore.showLess",
    defaultMessage : "Show less"
  },
  showDetails : {
    id : "mcore.showDetails",
    defaultMessage : "Show details"
  },
  hideDetails : {
    id : "mcore.hideDetails",
    defaultMessage : "Hide details"
  },
  missing : {
    id : "mcore.missing",
    defaultMessage : "Missing"
  },
  datajson : {
    id : "mcore.datajson",
    defaultMessage : "JSON data"
  },
  reduceShortTable : {
    id : "mcore.reduceShortTable",
    defaultMessage : "Reduce map table by default"
  },
  hideShortTable : {
    id : "mcore.hideShortTable",
    defaultMessage : "Hide table on map"
  },
  displayShortTable : {
    id : "mcore.displayShortTable",
    defaultMessage : "Display table on map"
  },
  runtime : {
    id : "mcore.runtime",
    defaultMessage : "Runtime"
  },
  availableTimes : {
    id : "mcore.availableTimes",
    defaultMessage : "Available Times"
  },
  showData : {
    id : "mcore.showData",
    defaultMessage : "Show Data"
  },
  noConfiguredProcess : {
    id : "mcore.noConfiguredProcess",
    defaultMessage : "No configured process was found for this service"
  },
  readOnlyName : {
    id : "mcore.readOnlyName",
    defaultMessage : "Be careful, after saving the name will no longer be editable"
  },
  overlaysLoop : {
    id : "mcore.overlaysloop",
    defaultMessage : "Overlays loop"
  },
  oauthRedirectDisclaimer : {
    id : "mcore.oauthRedirectDisclaimer",
    defaultMessage : "Authentication is finalizing, please do not close this page."
  },
  productionDescription : {
    id : "mcore.productionDescription",
    defaultMessage : "Description of production"
  },
  importFonts : {
    id : "mcore.importFonts",
    defaultMessage : "Import fonts"
  },
  semicolon : {
    id : "mcore.semicolon",
    defaultMessage : "Semicolon"
  },
  pipe : {
    id : "mcore.pipe",
    defaultMessage : "Pipe"
  },
  space : {
    id : "mcore.space",
    defaultMessage : "Space"
  },
  actualPosition : {
    id : "mcore.actualPosition",
    defaultMessage : "Actual position"
  },
  anchor : {
    id : "mcore.anchor",
    defaultMessage : "Anchor"
  },
  anchorVariable : {
    id : "mcore.anchorVariable",
    defaultMessage : "Anchor variable"
  },
  anchorToMap : {
    id : "mcore.anchorToMap",
    defaultMessage : "Anchor to map"
  },
  horizontalAnchor : {
    id : "mcore.horizontalAnchor",
    defaultMessage : "Horizontal anchor"
  },
  verticalAnchor : {
    id : "mcore.verticalAnchor",
    defaultMessage : "Vertical anchor"
  },
  droppedOverlays : {
    id : "mcore.droppedOverlays",
    defaultMessage : "Dropped overlays"
  }
})

export default { ...commonMessages, ...messages }
