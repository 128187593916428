/* eslint camelcase:0, no-param-reassign:0*/
import PureMocker from "mreact/lib/fetch-mocker"
import { v1 as uuid } from "uuid"
import { safeJSONstringify } from "mreact/lib/utils"
import cookies from "js-cookie"

class Mocker extends PureMocker {

  enable() { this.enabled = true }
  disable() { this.enabled = false }

}


let activeHosts = []

export const parseUrlReg = /(?:(?:(https?:|ftp:)\/\/)?([\w-.]+)(?::([0-9]{1,6}))?)?(\/.*?)?$/

export default class RestClient {

  static list = []

  static host

  static loginPath = ""

  static cookieExpiration = 7

  static changeAllHost(host) {
    RestClient.list.forEach(client => client.setHost(host))
    RestClient.host = host
  }

  static disconnectAll() {
    // pour rendre possible la désactivation ponctuelle d'une API
    activeHosts = RestClient.list.filter(client => !client.mocker.enabled)
    activeHosts.forEach(client => client.mocker.enable())
  }

  static connectAll() {
    activeHosts.forEach(client => client.mocker.disable())
  }

  constructor(url) {

    this.mocker = new Mocker(this.baseUrl)

    this.autoAddSlash = true

    this.setHost(RestClient.host || window.location.origin)

    this.baseUrl = url

    this._initLogin()

    RestClient.list.push(this)
  }

  _initLogin() {

    // this.loginPath = "/login"
    this.token = null
    this.username = null
    this.user_id = null
    this._remember = false
    this._storageName = "user"
  }

  getHost() {
    return this.protocol + "//" + this.hostname + (this.port ? ":" + this.port : "")
  }

  setHost(host) {

    const matches = parseUrlReg.exec(host)

    if (!matches) throw new Error(host + " : not a correct host")

    this.protocol = matches[1] || "http:"
    this.hostname = matches[2]
    this.port = matches[3] || ""
  }

  get loginUrl() {
    return this.getHost() + RestClient.loginPath
  }

  get baseUrl() {

    let pathname = this.pathname || ""

    if (this.autoAddSlash && pathname.slice(-1) !== "/") pathname += "/"

    return this.getHost() + pathname
  }

  set baseUrl(url) {

    const { hostname, protocol, port } = this

    const matches = (url && parseUrlReg.exec(url)) || []

    this.protocol = matches[1] || protocol
    this.hostname = matches[2] || hostname
    this.port = matches[2] ? (matches[3] || "") : (matches[3] || port)

    this.pathname = matches[4] || (this.autoAddSlash ? "/" : "")

    this.mocker.baseUrl = this.baseUrl
  }

  get protocol() {
    return this._protocol
  }

  set protocol(protocol) {
    this._protocol = protocol
    this.mocker.baseUrl = this.baseUrl
  }

  get hostname() {
    return this._hostname
  }

  set hostname(hostname) {
    this._hostname = hostname
    this.mocker.baseUrl = this.baseUrl
  }

  get port() {
    return this._port
  }

  set port(port) {
    this._port = port
    this.mocker.baseUrl = this.baseUrl
  }

  get pathname() {
    return this._pathname
  }

  set pathname(pathname) {
    this._pathname = pathname
    this.mocker.baseUrl = this.baseUrl
  }

  _makeUrl(id) {

    return this.baseUrl + id + "/"
  }

  create(data = {}) {

    const opt = {
      method : "POST",
      body : safeJSONstringify(data)
    }

    return this.fetchJSON(this.baseUrl, opt)

  }

  delete(id) {

    return this.fetchJSON(this._makeUrl(id), { method : "DELETE" })

  }

  remove(id) {

    return this.delete(id)
  }

  get(id) {

    return this.fetchJSON(this._makeUrl(id), { method : "GET" })

  }

  find(query = {}) {
    const fetchUrl = new URL(this.baseUrl)

    if (typeof query === "string") return this.find({ search : query })

    for (const key in query) {
      if (query[key] != null) fetchUrl.searchParams.append(key, query[key])
    }

    return this.fetchJSON(fetchUrl, { method : "GET" })

  }

  update(id, data) {

    if (!data && typeof id === "object") {
      data = id
      id = data.id || data.uuid
    }

    return this.fetchJSON(this._makeUrl(id), { method : "PUT", body : safeJSONstringify(data) })
  }

  patch(id, data) {

    if (!data && typeof id === "object") {
      data = id
      id = data.id || data.uuid
    }

    return this.fetchJSON(this._makeUrl(id), { method : "PATCH", body : safeJSONstringify(data) })
  }

  options() {

    return this.fetchJSON(this.baseUrl, { method : "OPTIONS" })

  }

  async duplicate(id) {

    const item = await this.get(id)

    delete item.id

    item.name = (item.name || "") + "-1"

    return await this.create(item)
  }

  checkToken(token) {

    if (!token) return Promise.reject(new Error("token is falsy"))
    let promise

    if (this.mocker.enabled) {
      promise = Promise.resolve({ is_superuser : true, user_id : 1 })
    } else {
      promise = fetch(this.loginUrl + token + "/")
        .then(this._processResponse)
        .catch(this._processError)
    }

    return promise.then(res => {
      this.username = res.username || this.username
      this.user_id = res.user_id || this.user_id

      return res
    })

  }

  login(username, password) {

    const token = this.getToken()

    if (token && this.username === username) return this.checkToken(token)

    this.logout()

    const opt = { method : "POST", body : safeJSONstringify({ username, password, force : 1 }) }

    let promise

    if (this.mocker.enabled) {
      promise = Promise.resolve({ token : uuid() })
    } else {
      promise = fetch(this.loginUrl, opt)
        .then(this._processResponse)
        .catch(this._processError)
    }


    return promise.then(res => {
      this.token = res.token
      this.username = username
      this.user_id = res.user_id
      this.storeToken()

      return res
    }).then(() => this.checkToken(this.token))
  }

  get remember() {

    return this._remember
  }

  set remember(value) {
    const bool = Boolean(value)

    if (bool !== this._remember) {
      this._remember = bool

      if (this.username && this.token) this.storeToken()
    }
  }

  storeToken() {

    const { username, token, user_id, remember } = this

    cookies.set(
      this._storageName,
      safeJSONstringify({ username, token, user_id, host : RestClient.host, remember }),
      remember ? { expires : RestClient.cookieExpiration } : null
    )
  }

  getToken() {

    const json = cookies.get(this._storageName)

    if (!json) return null

    const fields = JSON.parse(json)

    if (fields.host !== RestClient.host) return null

    this.username = fields.username
    this.user_id = fields.user_id
    this.token = fields.token
    this.remember = fields.remember

    return this.token
  }

  removeStorage() {
    cookies.remove(this._storageName)
  }

  logout() {

    this.token = null
    this.username = null
    this.user_id = null
    this.removeStorage()
  }

  get logged() {

    if (!this.token) this.getToken()

    return Boolean(this.token)
  }

  _processResponse(response) {

    if (response.headers.get("Content-Type") === null) {
      return null
    } else if (response.ok) {
      if (response.headers.get("Content-Length") === "0") {
        return null
      } else {
        return response.json()
      }
    } else {

      let error = {
        status : response.status,
        statusText : response.statusText
      }

      const contentType = response.headers.get("content-type")

      if (contentType && contentType.indexOf("application/json") !== -1) {

        return response.json()
          .then(res => {
            error = { ...error, ...res }
            throw error
          })
      } else {
        return response.text()
          .then(res => {

            const msg = { message : res }

            error = { ...error, ...msg }
            throw error
          })
      }
    }

  }

  _processError(err) {

    if (err.json) {

      return err.json()
        .then(errorObject => { throw errorObject })

    } else {
      throw err
    }

  }

  _setHeaders(additionalHeaders) {

    const headers = new Headers()

    const lang = sessionStorage.getItem("lang")

    if (lang) headers.set("Accept-Language", lang)

    const token = this.getToken()

    if (token) headers.set("X-Synopsis-Token", token)

    if (additionalHeaders) {

      if (additionalHeaders instanceof Headers) {
        for (const [key, value] of additionalHeaders.entries()) {
          headers.set(key, value)
        }
      } else {
        for (const key in additionalHeaders) {
          if (Object.prototype.hasOwnProperty.call(additionalHeaders, key)) {
            headers.set(key, additionalHeaders[key])
          }
        }
      }

    }

    return headers
  }

  RETRY_STATUS = [502, 503, 504]
  RETRY_DELAY = 2500
  MAX_TRIES = 5
  MAX_DURATION = 30000

  // eslint-disable-next-line max-params
  fetchJSON(url, options = {}, _tryCount = 1, _tryStart = Date.now()) {

    const { headers, ...opt } = options

    opt.headers = this._setHeaders(headers)

    if (typeof url === "string" && url.indexOf("http") !== 0) {
      url = this.getHost() + url
    }

    if (options.body && typeof options.body === "string") {
      opt.headers.set("Content-Type", "application/json")
    }

    this.controller = new AbortController()
    opt.signal = this.controller.signal

    const promise = this.mocker.enabled ? this.mocker.fetch(url, opt) : fetch(url, opt)

    return promise
      .then(this._processResponse)
      .catch(e => {
        if ((
          this.RETRY_STATUS.includes(e.status)
        ) && (
          _tryCount < this.MAX_TRIES
        ) && (
          (Date.now() + this.RETRY_DELAY) - _tryStart < this.MAX_DURATION
        )) {
          console.warn(`${url} : request error ${e.status}. Will retry in ${this.RETRY_DELAY}ms`)

          return this.wait(this.RETRY_DELAY).then(() => this.fetchJSON(url, options, _tryCount + 1, _tryStart))
        } else return this._processError(e)
      })

  }

  fetch(url, options = {}) {

    const { headers, ...opt } = options

    opt.headers = this._setHeaders(headers)

    if (typeof url === "string" && url.indexOf("http") !== 0) {
      url = this.getHost() + url
    }

    this.controller = new AbortController()
    opt.signal = this.controller.signal

    const promise = this.mocker.enabled ? this.mocker.fetch(url, opt) : fetch(url, opt)

    return promise
  }

  abort() {
    if (this.controller) this.controller.abort()
  }

  wait(ms) {

    return new Promise(resolve => setTimeout(resolve, ms))
  }

}

Object.defineProperty(RestClient, "isOffline", {
  get() { return !PRODUCTION && this.host === "disconnected" }
})
