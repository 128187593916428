import { createRoot } from "react-dom/client"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import projectConfig from "./config"
import RestClient from "mreact/lib/RestClient"

const host = sessionStorage.getItem("host")

if (host) RestClient.host = host // l'hôte doit être défini avant la construction des apis

async function getConfig() {

  let url = "/public/gui/metronome/synconfig/config.json?time=" + Date.now()

  const urlParams = new URLSearchParams(window.location.search)
  const configName = urlParams.get("config") || "dev"

  if (!PRODUCTION) {
    url = "/public/" + configName + "/config.json?time=" + Date.now()
  }

  const response = await fetch(url)

  if (response.ok) {
    const config = await response.json()

    return config
  } else {
    return {}
  }
}

/* L'import App est fait de cette façon à cause du middleware Sentry-Redux
 * En effet quand on fait import App, on init le store Redux et ses middlesware
 * le Sentry.init n'est pas encore lancé, il faut donc que le Sentry.init soit
 * effectué avant que l'import App ne soit fait*/
getConfig()
  .then(serverConfig => {

    // Dans des anciens fichiers de conf, la version peut toujours etre présent
    // Hors on ne veut plus la surcharger, donc on retire
    if (serverConfig) {
      delete serverConfig.version
      delete serverConfig.versionName
    }

    const config = {
      ...projectConfig,
      ...serverConfig
    }

    const { lang, languages = [], cookieExpiration } = config

    if (lang && languages.includes(lang)) sessionStorage.setItem("lang", lang)

    if (cookieExpiration) RestClient.cookieExpiration = cookieExpiration

    if (PRODUCTION && config.sentry) {
      Sentry.init({
        dsn : config.sentry,
        integrations : [new BrowserTracing()],
        release : RELEASE,
        tracesSampleRate : 0
      })
    }

    import(/* webpackChunkName: "app" */ "./App").then(mod => {

      const App = mod.default

      const div = document.createElement("div")

      document.body.appendChild(div)


      createRoot(div).render(<App config={ config } />)
    })
  })
