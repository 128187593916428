import mreactMessages from "mreact/messages"
import { defineMessages } from "react-intl"


export const messages = defineMessages({
  access_message : {
    id : "mcommon.access_message",
    defaultMessage : "access"
  },
  dev : {
    id : "mcommon.dev",
    defaultMessage : "Dev"
  },
  documentation : {
    id : "mcommon.documentation",
    defaultMessage : "Documentation"
  },
  mdata : {
    id : "mcommon.mdata",
    defaultMessage : "MData"
  },
  maintenanceMessage : {
    id : "mcommon.maintenanceMessage",
    defaultMessage : "Maintenance message"
  },
  valueRequired : {
    id : "mcommon.valueRequired",
    defaultMessage : "Value is required"
  },
  valOption : {
    id : "mcommon.valOption",
    defaultMessage : "Option value"
  },
  labelOption : {
    id : "mcommon.labelOption",
    defaultMessage : "Option label"
  },
  technicalName : {
    id : "mcommon.technicalName",
    defaultMessage : "Technical Name"
  },
  technicalNameForService : {
    id : "mcommon.technicalNameForService",
    defaultMessage : "Technical name for this service"
  },
  technicalNames : {
    id : "mcommon.technicalNames",
    defaultMessage : "Associate with MData Service"
  },
  catalogs : {
    id : "mcommon.catalogs",
    defaultMessage : "Catalogs"
  },
  creation_date : {
    id : "mcommon.creation_date",
    defaultMessage : "Creation date"
  },
  mreactCatalog : {
    id : "mcommon.mreactCatalog",
    defaultMessage : "MReact Catalog"
  },
  customer_name : {
    id : "mcommon.customer_name",
    defaultMessage : "Customer name"
  },
  customer_name_and_contact : {
    id : "mcommon.customer_name_and_contact",
    defaultMessage : "Customer name : contact"
  },
  mopenlayerCatalog : {
    id : "mcommon.mopenlayerCatalog",
    defaultMessage : "MOpenlayers Catalog"
  },
  mcommonCatalog : {
    id : "mcommon.mcommonCatalog",
    defaultMessage : "MCommon Catalog"
  },
  mcoreCatalog : {
    id : "mcommon.mcoreCatalog",
    defaultMessage : "MCore Catalog"
  },
  mcoreDocumentation : {
    id : "mcommon.mcoreDocumentation",
    defaultMessage : "MCore Documentation"
  },
  mdashboardCatalog : {
    id : "mcommon.mdashboardCatalog",
    defaultMessage : "Dashboard Catalog"
  },
  taskTableProfilesAdmin : {
    id : "mcommon.taskTableProfilesAdmin",
    defaultMessage : "Task table management"
  },
  dashboardProductionCenter : {
    id : "mcommon.dashboardProductionCenter",
    defaultMessage : "Production center"
  },
  dashboardProductionCenterSmall : {
    id : "mcommon.dashboardProductionCenterSmall",
    defaultMessage : "Prod. center"
  },
  dashboardProductionCenters : {
    id : "mcommon.dashboardProductionCenters",
    defaultMessage : "Production centers"
  },
  dashboardEventTypes : {
    id : "mcommon.dashboardEventTypes",
    defaultMessage : "Event types management"
  },
  muserCatalog : {
    id : "mcommon.muserCatalog",
    defaultMessage : "MUser Catalog"
  },
  eventAggregatorCatalog : {
    id : "mcommon.eventAggregatorCatalog",
    defaultMessage : "Event aggregator catalog"
  },
  eventAggregator : {
    id : "mcommon.eventAggregator",
    defaultMessage : "Event aggregator"
  },
  design : {
    id : "mcommon.design",
    defaultMessage : "Design"
  },
  css_vars : {
    id : "mcommon.css_vars",
    defaultMessage : "CSS Variables"
  },
  styled_tips : {
    id : "mcommon.styled_tips",
    defaultMessage : "Styled Components + CSS variables"
  },
  pointsOfInterests : {
    id : "mcommon.pointsOfInterests",
    defaultMessage : "Points Of Interests"
  },
  icon : {
    id : "mcommon.icon",
    defaultMessage : "Icon"
  },
  icons : {
    id : "mcommon.icons",
    defaultMessage : "Icons"
  },
  quit : {
    id : "mcommon.quit",
    defaultMessage : "Quit"
  },
  hide : {
    id : "mcommon.hide",
    defaultMessage : "Hide"
  },
  productionArchives : {
    id : "mcommon.productionArchives",
    defaultMessage : "Production archives"
  },
  noDateSelected : {
    id : "mcommon.noDateSelected",
    defaultMessage : "No date selected"
  },
  archivesOf : {
    id : "mcommon.archivesOf",
    defaultMessage : "Archives of"
  },
  archives : {
    id : "mcommon.archives",
    defaultMessage : "Archives"
  },
  noArchiveAvailable : {
    id : "mcommon.noArchiveAvailable",
    defaultMessage : "No archive available for this production"
  },
  addCondition : {
    id : "mcommon.addCondition",
    defaultMessage : "Add a condition"
  },
  eventAggregatorConfiguration : {
    id : "mcommon.eventAggregatorConfiguration",
    defaultMessage : "Event aggregator configuration"
  },
  eventAggregatorSubscription : {
    id : "mcommon.eventAggregatorSubscription",
    defaultMessage : "Event aggregator subscription"
  },
  metro_loader : {
    id : "mcommon.metro_loader",
    defaultMessage : "Animated logo"
  },
  groupBy : {
    id : "mcommon.groupBy",
    defaultMessage : "Group by"
  },
  editors : {
    id : "mcommon.editors",
    defaultMessage : "Editors"
  },
  mapEditor : {
    id : "mcommon.mapEditor",
    defaultMessage : "Map editor"
  },
  mapExpertise : {
    id : "mcommon.mapExpertise",
    defaultMessage : "Map expertise"
  },
  textEditor : {
    id : "mcommon.textEditor",
    defaultMessage : "Text editor"
  },
  gridEditor : {
    id : "mcommon.gridEditor",
    defaultMessage : "Grid editor"
  },
  paiEditor : {
    id : "mcommon.paiEditor",
    defaultMessage : "PAI editor"
  },
  shortcuts : {
    id : "mcommon.shortcuts",
    defaultMessage : "Shortcuts"
  },
  subscriptionActive : {
    id : "mcommon.subscriptionActive",
    defaultMessage : "Subscription active"
  },
  subscriptionIsActive : {
    id : "mcommon.subscriptionIsActive",
    defaultMessage : "Subscription is active"
  },
  subscriptionIsNoActive : {
    id : "mcommon.subscriptionIsNoActive",
    defaultMessage : "Subscription is nonactive"
  },
  substitutions : {
    id : "mcommon.substitutions",
    defaultMessage : "Substitutions"
  },
  substitutionEditor : {
    id : "mcommon.substitutionEditor",
    defaultMessage : "Substitution editor"
  },
  substitutionSet : {
    id : "mcommon.substitutionSet",
    defaultMessage : "Substitution set"
  },
  tagsEditor : {
    id : "mcommon.tagsEditor",
    defaultMessage : "Tags editor"
  },
  themes : {
    id : "mcommon.themes",
    defaultMessage : "Themes"
  },
  generalConfiguration : {
    id : "mcommon.generalConfiguration",
    defaultMessage : "General configuration"
  },
  noDimensions : {
    id : "mcommon.noDimensions",
    defaultMessage : "No dimensions are selected"
  },
  genericCrud : {
    id : "mcommon.genericCrud",
    defaultMessage : "Generic CRUD"
  },
  admin : {
    id : "mcommon.admin",
    defaultMessage : "Administration"
  },
  agat : {
    id : "mcommon.agat",
    defaultMessage : "AGAT"
  },
  directory : {
    id : "mcommon.directory",
    defaultMessage : "Directory"
  },
  contactFunctions : {
    id : "mcommon.contactFunctions",
    defaultMessage : "Contact functions"
  },
  sectors : {
    id : "mcommon.sectors",
    defaultMessage : "Activity sectors"
  },
  taskConfiguration : {
    id : "mcommon.taskConfiguration",
    defaultMessage : "Task configuration"
  },
  productModels : {
    id : "mcommon.productModels",
    defaultMessage : "Product models"
  },
  productions : {
    id : "mcommon.productions",
    defaultMessage : "Productions"
  },
  productionsProductsTrigger : {
    id : "mcommon.productionsProductsTrigger",
    defaultMessage : "Productions (customers, expertises, products, distributions, triggers)"
  },
  process : {
    id : "mcommon.process",
    defaultMessage : "Process"
  },
  provider : {
    id : "mcommon.provider",
    defaultMessage : "Provider"
  },
  prodVars : {
    id : "mcommon.prodVars",
    defaultMessage : "Variables"
  },
  supervision : {
    id : "mcommon.supervision",
    defaultMessage : "Supervision"
  },
  datas : {
    id : "mcommon.datas",
    defaultMessage : "Data"
  },
  taskboards : {
    id : "mcommon.taskboards",
    defaultMessage : "Tasks table"
  },
  user : {
    id : "mcommon.user",
    defaultMessage : "User"
  },
  users : {
    id : "mcommon.users",
    defaultMessage : "Users"
  },
  groups : {
    id : "mcommon.groups",
    defaultMessage : "Groups"
  },
  extrafields : {
    id : "mcommon.extrafields",
    defaultMessage : "Extrafields"
  },
  extrafieldGroups : {
    id : "mcommon.extrafieldGroups",
    defaultMessage : "Extrafield Groups"
  },
  geos : {
    id : "mcommon.geos",
    defaultMessage : "Points"
  },
  tasks : {
    id : "mcommon.tasks",
    defaultMessage : "Tasks"
  },
  diffusions : {
    id : "mcommon.diffusions",
    defaultMessage : "Diffusions"
  },
  customerCategories : {
    id : "mcommon.customerCategories",
    defaultMessage : "Customers categories"
  },
  customerCallReasons : {
    id : "mcommon.customerCallReasons",
    defaultMessage : "Objects"
  },
  types : {
    id : "mcommon.types",
    defaultMessage : "Customers types"
  },
  generate : {
    id : "mcommon.generate",
    defaultMessage : "Generate"
  },
  dev_message : {
    id : "mcommon.dev_message",
    defaultMessage : "For the geeks"
  },
  service : {
    id : "mcommon.service",
    defaultMessage : "Category"
  },
  system_configurations : {
    id : "mcommon.system_configurations",
    defaultMessage : "System configurations"
  },
  newExpertise : {
    id : "mcommon.newExpertise",
    defaultMessage : "New expertise model"
  },
  expertiseFieldName : {
    id : "mcommon.expertiseFieldName",
    defaultMessage : "Expertise field name"
  },
  expertiseModelName : {
    id : "mcommon.expertiseModelName",
    defaultMessage : "Expertise model name"
  },
  expertiseVarName : {
    id : "mcommon.expertiseVarName",
    defaultMessage : "Expertise variable name"
  },
  call_history : {
    id : "mcommon.call_history",
    defaultMessage : "Customer care"
  },
  customerCallHistory : {
    id : "mcommon.customerCallHistory",
    defaultMessage : "Customer call history"
  },
  product : {
    id : "mcommon.product",
    defaultMessage : "Product"
  },
  modelproduct : {
    id : "mcommon.modelproduct",
    defaultMessage : "Model Product"
  },
  products : {
    id : "mcommon.products",
    defaultMessage : "Products"
  },
  distributionproduct : {
    id : "mcommon.distributionproduct",
    defaultMessage : "Product"
  },
  distributionproducts : {
    id : "mcommon.distributionproducts",
    defaultMessage : "Products to send"
  },
  distributionproductslabel : {
    id : "mcommon.distributionproductslabel",
    defaultMessage : "Products to send"
  },
  distributionrecipients : {
    id : "mcommon.distributionrecipients",
    defaultMessage : "Recipients"
  },
  distributionrecipient : {
    id : "mcommon.distributionrecipient",
    defaultMessage : "Recipient"
  },
  categories : {
    id : "mcommon.categories",
    defaultMessage : "Categories"
  },
  geo : {
    id : "mcommon.geo",
    defaultMessage : "Geographical position"
  },
  dataGeo : {
    id : "mcommon.dataGeo",
    defaultMessage : "Geographical position data"
  },
  customers : {
    id : "mcommon.customers",
    defaultMessage : "Customers"
  },
  customersContactsSubsriptions : {
    id : "mcommon.customersContactsSubsriptions",
    defaultMessage : "Customers (contacts and subscriptions)"
  },
  customersAssociatedDatas : {
    id : "mcommon.customersAssociatedDatas",
    defaultMessage : "Customers and associated datas"
  },
  subscription : {
    id : "mcommon.subscription",
    defaultMessage : "Subscription"
  },
  subscriptionName : {
    id : "mcommon.subscriptionName",
    defaultMessage : "Subscription name"
  },
  subscriptions : {
    id : "mcommon.subscriptions",
    defaultMessage : "Subscriptions"
  },
  productiontriggers : {
    id : "mcommon.productiontriggers",
    defaultMessage : "Production triggers"
  },
  productiontrigger : {
    id : "mcommon.productiontrigger",
    defaultMessage : "Production trigger"
  },
  scheduleTaskTriggerModel : {
    id : "mcommon.scheduleTaskTriggerModel",
    defaultMessage : "Scheduled Task Trigger"
  },
  domains : {
    id : "mcommon.domains",
    defaultMessage : "Domains"
  },
  dimensionValues : {
    id : "mcommon.dimensionValues",
    defaultMessage : "Dimension values"
  },
  dimensions : {
    id : "mcommon.dimensions",
    defaultMessage : "Dimensions"
  },
  dimension : {
    id : "mcommon.dimension",
    defaultMessage : "Dimension"
  },
  domain : {
    id : "mcommon.domain",
    defaultMessage : "domain"
  },
  projection : {
    id : "mcommon.projection",
    defaultMessage : "Projection"
  },
  address : {
    id : "mcommon.address",
    defaultMessage : "Address"
  },
  addresses : {
    id : "mcommon.addresses",
    defaultMessage : "Addresses"
  },
  contact : {
    id : "mcommon.contact",
    defaultMessage : "Contact"
  },
  contacts : {
    id : "mcommon.contacts",
    defaultMessage : "Contacts"
  },
  taskboard : {
    id : "mcommon.taskboard",
    defaultMessage : "Tasks Table"
  },
  production : {
    id : "mcommon.production",
    defaultMessage : "Production"
  },
  productionWithExpertise : {
    id : "mcommon.production_with_expertise",
    defaultMessage : "Production with expertise"
  },
  distributions : {
    id : "mcommon.distributions",
    defaultMessage : "Distributions"
  },
  distribution : {
    id : "mcommon.distribution",
    defaultMessage : "Distribution"
  },
  recipient : {
    id : "mcommon.recipient",
    defaultMessage : "Recipient"
  },
  addCustomer : {
    id : "mcommon.addCustomer",
    defaultMessage : "At first add customer to the production"
  },
  recipients : {
    id : "mcommon.recipients",
    defaultMessage : "Recipients"
  },
  sector : {
    id : "mcommon.sector",
    defaultMessage : "Sector"
  },
  productionProducts : {
    id : "mcommon.production-products",
    defaultMessage : "Productions/Products"
  },
  bbox : {
    id : "mcommon.bbox",
    defaultMessage : "BBox"
  },
  visibility : {
    id : "mcommon.visibility",
    defaultMessage : "Visibility"
  },
  profiles : {
    id : "mcommon.profiles",
    defaultMessage : "Profiles"
  },
  period : {
    id : "mcommon.period",
    defaultMessage : "Period"
  },
  invalidPeriod : {
    id : "mcommon.invalidPeriod",
    defaultMessage : "Invalid period"
  },
  identifier : {
    id : "mcommon.identifier",
    defaultMessage : "Identifier"
  },
  identifiers : {
    id : "mcommon.identifiers",
    defaultMessage : "Identifiers"
  },
  nameForIdentifier : {
    id : "mcommon.nameForIdentifier",
    defaultMessage : "Name for identifier"
  },
  soon : {
    id : "mcommon.soon",
    defaultMessage : "Expires soon"
  },
  gone : {
    id : "mcommon.gone",
    defaultMessage : "Expired"
  },
  forthcoming : {
    id : "mcommon.forthcoming",
    defaultMessage : "Forthcoming"
  },
  nocontext : {
    id : "mcommon.nocontext",
    defaultMessage : "No one"
  },
  configuration : {
    id : "mcommon.configuration",
    defaultMessage : "Configuration"
  },
  model : {
    id : "mcommon.model",
    defaultMessage : "Model"
  },
  condition : {
    id : "mcommon.condition",
    defaultMessage : "Condition"
  },
  confirmExport : {
    id : "mcommon.confirmExport",
    defaultMessage : "Are you sure you want to export {count, number} {count, plural, one {item} other {items}} ?"
  },
  importReport : {
    id : "mcommon.import_report",
    defaultMessage : "Import report"
  },
  nextStep : {
    id : "mcommon.nextStep",
    defaultMessage : "Next step"
  },
  export : {
    id : "mcommon.export",
    defaultMessage : "Export"
  },
  active : {
    id : "mcommon.active",
    defaultMessage : "Active"
  },
  capitalize : {
    id : "mcommon.capitalize",
    defaultMessage : "capitalize"
  },
  upper : {
    id : "mcommon.upper",
    defaultMessage : "upper"
  },
  level : {
    id : "mcommon.level",
    defaultMessage : "Level"
  },
  low_priority : {
    id : "mcommon.low_priority",
    defaultMessage : "Low priority"
  },
  standard_priority : {
    id : "mcommon.standard_priority",
    defaultMessage : "Standard priority"
  },
  medium_priority : {
    id : "mcommon.medium_priority",
    defaultMessage : "Medium priority"
  },
  high : {
    id : "mcommon.high",
    defaultMessage : "High"
  },
  high_priority : {
    id : "mcommon.high_priority",
    defaultMessage : "High priority"
  },
  lower : {
    id : "mcommon.lower",
    defaultMessage : "lower"
  },
  trim : {
    id : "mcommon.trim",
    defaultMessage : "trim"
  },
  truncate : {
    id : "mcommon.truncate",
    defaultMessage : "truncate"
  },
  wordcount : {
    id : "mcommon.wordcount",
    defaultMessage : "wordcount"
  },
  tagName : {
    id : "mcommon.tagName",
    defaultMessage : "Tag name"
  },
  filter : {
    id : "mcommon.filter",
    defaultMessage : "Filter"
  },
  dueDate : {
    id : "mcommon.dueDate",
    defaultMessage : "Due date"
  },
  dueDateType : {
    id : "mcommon.dueDateType",
    defaultMessage : "Due date type"
  },
  night : {
    id : "mcommon.night",
    defaultMessage : "Night"
  },
  sliding : {
    id : "mcommon.sliding",
    defaultMessage : "Relative"
  },
  runTime : {
    id : "mcommon.runTime",
    defaultMessage : "Run Time"
  },
  nearest : {
    id : "mcommon.nearest",
    defaultMessage : "Nearest"
  },
  roundingTypeNearest : {
    id : "mcommon.roundingTypeNearest",
    defaultMessage : "Nearest"
  },
  timeGeneration : {
    id : "mcommon.timeGeneration",
    defaultMessage : "Generating time"
  },
  timeProduction : {
    id : "mcommon.timeProduction",
    defaultMessage : "Production time"
  },
  trustLevel : {
    id : "mcommon.trustLevel",
    defaultMessage : "Trust level"
  },
  availableChoices : {
    id : "mcommon.availableChoices",
    defaultMessage : "Available choices"
  },
  analysis : {
    id : "mcommon.analysis",
    defaultMessage : "Analysis"
  },
  valueVariation : {
    id : "mcommon.valueVariation",
    defaultMessage : "Data variation"
  },
  variation : {
    id : "mcommon.variation",
    defaultMessage : "Variation"
  },
  varProd : {
    id : "mcommon.varProd",
    defaultMessage : "Production variable"
  },
  variable : {
    id : "mcommon.variable",
    defaultMessage : "Variable"
  },
  variationDate : {
    id : "mcommon.variationDate",
    defaultMessage : "Variation date"
  },
  manualConfig : {
    id : "mcommon.manualConfig",
    defaultMessage : "Manual configuration"
  },
  timeType : {
    id : "mcommon.timeType",
    defaultMessage : "Type of date"
  },
  sourceType : {
    id : "mcommon.sourceType",
    defaultMessage : "Type of source"
  },
  source : {
    id : "mcommon.source",
    defaultMessage : "Source"
  },
  geoType : {
    id : "mcommon.geoType",
    defaultMessage : "Type of geographical position"
  },
  interestPoint : {
    id : "mcommon.interestPoint",
    defaultMessage : "Point of interest"
  },
  latlon : {
    id : "mcommon.latlon",
    defaultMessage : "Latitude / longitude"
  },
  lat : {
    id : "mcommon.lat",
    defaultMessage : "Latitude"
  },
  lon : {
    id : "mcommon.lon",
    defaultMessage : "Longitude"
  },
  loopVariable : {
    id : "mcommon.loopVariable",
    defaultMessage : "Loop variable"
  },
  loops : {
    id : "mcommon.loops",
    defaultMessage : "Loops"
  },
  eventtrigger : {
    id : "mcommon.eventtrigger",
    defaultMessage : "Event trigger"
  },
  scheduletasktrigger : {
    id : "mcommon.scheduletasktrigger",
    defaultMessage : "Scheduled trigger"
  },
  incomingdatatasktrigger : {
    id : "mcommon.incomingdatatasktrigger",
    defaultMessage : "Trigger on incoming Data"
  },
  eventaggregatortasktrigger : {
    id : "mcommon.eventaggregatortasktrigger",
    defaultMessage : "Triggering on event aggregator"
  },
  manualtasktrigger : {
    id : "mcommon.manualtasktrigger",
    defaultMessage : "Manual trigger"
  },
  linkedtasktrigger : {
    id : "mcommon.linkedtasktrigger",
    defaultMessage : "Trigger linked to another task"
  },
  linkedtasktrigger_short : {
    id : "mcommon.linkedtasktrigger_short",
    defaultMessage : "Linked"
  },
  synopsis : {
    id : "mcommon.synopsis",
    defaultMessage : "Synopsis"
  },
  rounding : {
    id : "mcommon.rounding",
    defaultMessage : "Rounding"
  },
  roundingType : {
    id : "mcommon.roundingType",
    defaultMessage : "Rounding type"
  },
  riskSource : {
    id : "mcommon.riskSource",
    defaultMessage : "Risk source"
  },
  riskName : {
    id : "mcommon.riskName",
    defaultMessage : "Risk name"
  },
  fontManager : {
    id : "mcommon.fontManager",
    defaultMessage : "Font Manager"
  },
  fontManager1 : {
    id : "mcommon.fontManager1",
    defaultMessage : "View, edit and create differents"
  },
  mediaTitle : {
    id : "mcommon.mediaTitle",
    defaultMessage : "Media"
  },
  mediaManager : {
    id : "mcommon.mediaManager",
    defaultMessage : "Medias"
  },
  mediaManager1 : {
    id : "mcommon.mediaManager1",
    defaultMessage : "View, edit and create differents"
  },
  policeManager : {
    id : "mcommon.policeManager",
    defaultMessage : "Police Manager"
  },
  policeManager1 : {
    id : "mcommon.policeManager1",
    defaultMessage : "View, edit and create differents"
  },
  unit : {
    id : "mcommon.unit",
    defaultMessage : "Unit"
  },
  toConfigure : {
    id : "mcommon.toConfigure",
    defaultMessage : "To configure"
  },
  typeTextarea : {
    id : "mcommon.typeTextarea",
    defaultMessage : "Text area"
  },
  typeSelect : {
    id : "mcommon.typeSelect",
    defaultMessage : "Scrolling menu"
  },
  typeDate : {
    id : "mcommon.typeDate",
    defaultMessage : "Date"
  },
  fieldType : {
    id : "mcommon.fieldType",
    defaultMessage : "Field type"
  },
  refDate : {
    id : "mcommon.refDate",
    defaultMessage : "reference date"
  },
  risk : {
    id : "mcommon.risk",
    defaultMessage : "Risk"
  },
  risks : {
    id : "mcommon.risks",
    defaultMessage : "Risks"
  },
  risksTitle : {
    id : "mcommon.risksTitle",
    defaultMessage : "Risks management"
  },
  expertise : {
    id : "mcommon.expertise",
    defaultMessage : "Expertise"
  },
  expertises : {
    id : "mcommon.expertises",
    defaultMessage : "Expertise models"
  },
  expertiseField : {
    id : "mcommon.expertiseField",
    defaultMessage : "Expertise Field"
  },
  expertiseVariables : {
    id : "mcommon.expertiseVariables",
    defaultMessage : "Configuration options"
  },
  displayVariables : {
    id : "mcommon.displayVariables",
    defaultMessage : "Display options"
  },
  expertiseInterfaces : {
    id : "mcommon.expertiseInterfaces",
    defaultMessage : "Expertise Interfaces"
  },
  expertiseParameters : {
    id : "mcommon.expertiseParameters",
    defaultMessage : "Expertise Parameters"
  },
  dataParameters : {
    id : "mcommon.dataParameters",
    defaultMessage : "Data parameters"
  },
  dataServices : {
    id : "mcommon.dataServices",
    defaultMessage : "Data services"
  },
  imageSelector : {
    id : "mcommon.imageSelector",
    defaultMessage : "Image Selector"
  },
  imageUrl : {
    id : "mcommon.imageUrl",
    defaultMessage : "Image url"
  },
  imageName : {
    id : "mcommon.imageName",
    defaultMessage : "Image name"
  },
  backgroundImage : {
    id : "mcommon.backgroundImage",
    defaultMessage : "Background image"
  },
  backgroundPosition : {
    id : "mcommon.backgroundPosition",
    defaultMessage : "Background position"
  },
  backgroundRepeat : {
    id : "mcommon.backgroundRepeat",
    defaultMessage : "Background repeat"
  },
  backgroundSize : {
    id : "mcommon.backgroundSize",
    defaultMessage : "Background size"
  },
  productTemplates : {
    id : "mcommon.productTemplates",
    defaultMessage : "Product templates"
  },
  dataProducts : {
    id : "mcommon.dataProducts",
    defaultMessage : "XML products"
  },
  jsondataproducts : {
    id : "mcommon.jsondataproducts",
    defaultMessage : "JSON products"
  },
  csvdataProducts : {
    id : "mcommon.csvdataProducts",
    defaultMessage : "CSV products"
  },
  personalized : {
    id : "mcommon.personalized",
    defaultMessage : "Personalized"
  },
  chooseTemplate : {
    id : "mcommon.chooseTemplate",
    defaultMessage : "Choose template"
  },
  noPreviewAvailable : {
    id : "mcommon.noPreviewAvailable",
    defaultMessage : "No preview available"
  },
  noVarRegistered : {
    id : "mcommon.noVarRegistered",
    defaultMessage : "No variable registered"
  },
  emptyTemplate : {
    id : "mcommon.emptyTemplate",
    defaultMessage : "Empty Template"
  },
  customerList : {
    id : "mcommon.customerList",
    defaultMessage : "Customer list"
  },
  newCustomer : {
    id : "mcommon.newCustomer",
    defaultMessage : "New customer"
  },
  productionList : {
    id : "mcommon.productionList",
    defaultMessage : "Production List"
  },
  newProduction : {
    id : "mcommon.newProduction",
    defaultMessage : "New production"
  },
  taskSupervision : {
    id : "mcommon.taskSupervision",
    defaultMessage : "Task supervision"
  },
  distributionSupervision : {
    id : "mcommon.distributionSupervision",
    defaultMessage : "Distribution supervision"
  },
  userList : {
    id : "mcommon.userList",
    defaultMessage : "User list"
  },
  newUser : {
    id : "mcommon.newUser",
    defaultMessage : "New user"
  },
  loopVar : {
    id : "mcommon.loopVar",
    defaultMessage : "Loop variable"
  },
  aggregate : {
    id : "mcommon.aggregate",
    defaultMessage : "Parameter"
  },
  elevation : {
    id : "mcommon.elevation",
    defaultMessage : "Elevation"
  },
  time_ref_period : {
    id : "mcommon.time_ref_period",
    defaultMessage : "Time ref period"
  },
  etiquette : {
    id : "mcommon.etiquette",
    defaultMessage : "Tag"
  },
  tags : {
    id : "mcommon.tags",
    defaultMessage : "Tags"
  },
  unitH : {
    id : "mcommon.unitH",
    defaultMessage : "Unit"
  },
  optionalName : {
    id : "mcommon.optionalName",
    defaultMessage : "Name (optional)"
  },
  optionalLabel : {
    id : "mcommon.optionalLabel",
    defaultMessage : "Label (optional)"
  },
  origin_scheduled : {
    id : "mcommon.origin_scheduled",
    defaultMessage : "Scheduled"
  },
  origin_event : {
    id : "mcommon.origin_event",
    defaultMessage : "Event"
  },
  origin_manual : {
    id : "mcommon.origin_manual",
    defaultMessage : "Manual"
  },
  origin_incoming_data : {
    id : "mcommon.origin_incoming_data",
    defaultMessage : "Incoming data"
  },
  origin_event_aggregator : {
    id : "mcommon.origin_event_aggregator",
    defaultMessage : "Event aggregator"
  },
  summaryTableModalTitle : {
    id : "mcommon.summaryTableModalTitle",
    defaultMessage : "Management of personal web links"
  },
  summaryTableModalClose : {
    id : "mcommon.summaryTableModalClose",
    defaultMessage : "Close"
  },
  link : {
    id : "mcommon.link",
    defaultMessage : "Link"
  },
  defaultViewInitialization : {
    id : "mcommon.defaultViewInitialization",
    defaultMessage : "Default view initialization"
  },
  viewEdit_message : {
    id : "mcommon.viewEdit_message",
    defaultMessage : "View and edit the"
  },
  viewEditAll_message : {
    id : "mcommon.viewEditAll_message",
    defaultMessage : "View and edit all the "
  },
  viewEditCreate_message : {
    id : "mcommon.viewEditCreate_message",
    defaultMessage : "View, edit and create the "
  },
  viewEditCreateAll_message : {
    id : "mcommon.viewEditCreateAll_message",
    defaultMessage : "View, edit and create all the "
  },
  viewConsultModifyActivate : {
    id : "mcommon.viewConsultModifyActivate",
    defaultMessage : "Consult, modify and activate the"
  },
  view_message : {
    id : "mcommon.view_message",
    defaultMessage : "View the "
  },
  viewla_message : {
    id : "mcommon.viewla_message",
    defaultMessage : "View the "
  },
  new_view : {
    id : "mcommon.new_view",
    defaultMessage : "Add a custom view "
  },
  rename_view : {
    id : "mcommon.rename_view",
    defaultMessage : "Rename the view"
  },
  name_view : {
    id : "mcommon.name_view",
    defaultMessage : "View's name"
  },
  customView : {
    id : "mcommon.customView",
    defaultMessage : "Custom view"
  },
  preferencesSaved : {
    id : "mcommon.preferencesSaved",
    defaultMessage : "Preferences successfully saved"
  },
  preferencesSaveError : {
    id : "mcommon.preferencesSaveError",
    defaultMessage : "An error occured while saving preferences"
  },
  saveCustomView : {
    id : "mcommon.saveCustomView",
    defaultMessage : "Save custom view"
  },
  resetCustomView : {
    id : "mdashboard.resetCustomView",
    defaultMessage : "Reset custom view"
  },
  allElements : {
    id : "mcommon.allElements",
    defaultMessage : "All the elements"
  },
  enabledProduction : {
    id : "mcommon.enabledProduction",
    defaultMessage : "Enabled production"
  },
  display : {
    id : "mcommon.display",
    defaultMessage : "Display"
  },
  displayDataDateTime : {
    id : "mcommon.displayDataDateTime",
    defaultMessage : "DateTime display"
  },
  displayDataNumeric : {
    id : "mcommon.displayDataNumeric",
    defaultMessage : "Numerical display"
  },
  displayDataImage : {
    id : "mcommon.displayDataImage",
    defaultMessage : "Display data image"
  },
  disabledProduction : {
    id : "mcommon.disabledProduction",
    defaultMessage : "Disabled production"
  },
  oneManualTrigger : {
    id : "mcommon.oneManualTrigger",
    defaultMessage : "manual trigger"
  },
  manyManualTrigger : {
    id : "mcommon.manyManualTrigger",
    defaultMessage : "manual triggers"
  },
  param : {
    id : "mcommon.param",
    defaultMessage : "Parameter"
  },
  parametersServicesSubstitutions : {
    id : "mcommon.parametersServicesSubstitutions",
    defaultMessage : "Parameters, Parameter categories and substitutions"
  },
  zone : {
    id : "mcommon.zone",
    defaultMessage : "Zone"
  },
  timeRange : {
    id : "mcommon.timeRange",
    defaultMessage : "Time range"
  },
  startOfTimeRange : {
    id : "mcommon.startOfTimeRange",
    defaultMessage : "Start of time range"
  },
  endOfTimeRange : {
    id : "mcommon.endOfTimeRange",
    defaultMessage : "End of time range"
  },
  timeRangeInHours : {
    id : "mcommon.timeRangeInHours",
    defaultMessage : "Time range (in hours)"
  },
  defineFromTimeRange : {
    id : "mcommon.defineFromTimeRange",
    defaultMessage : "Define due dates from a time range"
  },
  selectFiles : {
    id : "mcommon.selectFiles",
    defaultMessage : "Select files"
  },
  dropFiles : {
    id : "mcommon.dropFiles",
    defaultMessage : "Drop files"
  },
  characterOfCompletion : {
    id : "mcommon.characterOfCompletion",
    defaultMessage : "Character of completion"
  },
  directionOfCompletion : {
    id : "mcommon.directionOfCompletion",
    defaultMessage : "Direction of completion"
  },
  chooseImage : {
    id : "mcommon.chooseImage",
    defaultMessage : "Choose image"
  },
  gallery : {
    id : "mcommon.gallery",
    defaultMessage : "Gallery"
  },
  mediaName : {
    id : "mcommon.mediaName",
    defaultMessage : "Media Name"
  },
  noMediaFound : {
    id : "mcommon.noMediaFound",
    defaultMessage : "No media found"
  },
  files : {
    id : "mcommon.files",
    defaultMessage : "Files"
  },
  importImages : {
    id : "mcommon.importImages",
    defaultMessage : "Import images"
  },
  activity : {
    id : "mcommon.activity",
    defaultMessage : "Activity"
  },
  action : {
    id : "mcommon.action",
    defaultMessage : "Action"
  },
  importSuccess : {
    id : "mcommon.importSuccess",
    defaultMessage : "Successfully imported"
  },
  incorrectType : {
    id : "mcommon.incorrectType",
    defaultMessage : "{name} has not a valid type : {type} (\"{allowedTypes}\" required)"
  },
  requiredField : {
    id : "mcommon.requiredField",
    defaultMessage : "Please enter a value"
  },
  prometheeConf : {
    id : "mcommon.prometheeConf",
    defaultMessage : "Promethee Configuration"
  },
  prometheeModels : {
    id : "mcommon.prometheeModels",
    defaultMessage : "Promethee models"
  },
  prometheeComponents : {
    id : "mcommon.prometheeComponents",
    defaultMessage : "Promethee components"
  },
  raw : {
    id : "mcommon.raw",
    defaultMessage : "Raw"
  },
  noChange : {
    id : "mcommon.noChange",
    defaultMessage : "No change"
  },
  textToHTML : {
    id : "mcommon.textToHTML",
    defaultMessage : "text ➔ HTML"
  },
  HTMLToText : {
    id : "mcommon.HTMLToText",
    defaultMessage : "HTML ➔ text"
  },
  numberOfCharacter : {
    id : "mcommon.numberOfCharacter",
    defaultMessage : "Number of character"
  },
  numbertext : {
    id : "mcommon.numbertext",
    defaultMessage : "Number ➔ Words"
  },
  unitDisplayed : {
    id : "mcommon.unitDisplayed",
    defaultMessage : "Display unit"
  },
  unitBefore : {
    id : "mcommon.unitBefore",
    defaultMessage : "Display unit before"
  },
  unitDefault : {
    id : "mcommon.unitDefault",
    defaultMessage : "Default unit"
  },
  unitCustom : {
    id : "mcommon.unitCustom",
    defaultMessage : "Custom unit"
  },
  unitFormat : {
    id : "mcommon.unitFormat",
    defaultMessage : "Format"
  },
  shortUnit : {
    id : "mcommon.shortUnit",
    defaultMessage : "Short"
  },
  longUnit : {
    id : "mcommon.longUnit",
    defaultMessage : "Long"
  },
  initialization : {
    id : "mcommon.initialization",
    defaultMessage : "Initialization"
  },
  observation : {
    id : "mcommon.observation",
    defaultMessage : "Observation"
  },
  satellite : {
    id : "mcommon.satellite",
    defaultMessage : "Satellite"
  },
  radar : {
    id : "mcommon.radar",
    defaultMessage : "Radar"
  },
  sigwx : {
    id : "mcommon.sigwx",
    defaultMessage : "Sigwx"
  },
  backgroundColor : {
    id : "mcommon.backgroundColor",
    defaultMessage : "Background color"
  },
  textColor : {
    id : "mcommon.textColor",
    defaultMessage : "Text color"
  },
  colorCode : {
    id : "mcommon.colorCode",
    defaultMessage : "Color code"
  },
  imageFile : {
    id : "mcommon.imageFile",
    defaultMessage : "Image file"
  },
  dataText : {
    id : "mcommon.dataText",
    defaultMessage : "dataText"
  },
  displayDataText : {
    id : "mcommon.displayDataText",
    defaultMessage : "Text display"
  },
  tasksConfiguration : {
    id : "mcommon.tasksConfiguration",
    defaultMessage : "Tasks configuration"
  },
  activityArea : {
    id : "mcommon.activityArea",
    defaultMessage : "Activity area"
  },
  modeling : {
    id : "mcommon.modeling",
    defaultMessage : "Modeling"
  },
  services : {
    id : "mcommon.services",
    defaultMessage : "Parameter categories"
  },
  parameterCategory : {
    id : "mcommon.parameterCategory",
    defaultMessage : "Parameter category"
  },
  productionTask : {
    id : "mcommon.productionTask",
    defaultMessage : "Production task"
  },
  productionTasks : {
    id : "mcommon.productionTasks",
    defaultMessage : "Production tasks"
  },
  productionTasksOther : {
    id : "mcommon.productionTasksOther",
    defaultMessage : "Production tasks and other tasks"
  },
  amendment : {
    id : "mcommon.amendment",
    defaultMessage : "Amendment"
  },
  otherTask : {
    id : "mcommon.otherTask",
    defaultMessage : "Exploitation task"
  },
  otherTasks : {
    id : "mcommon.otherTasks",
    defaultMessage : "Exploitation tasks"
  },
  numeric : {
    id : "mcommon.numeric",
    defaultMessage : "Numeric"
  },
  datetime : {
    id : "mcommon.datetime",
    defaultMessage : "Datetime"
  },
  timestamp : {
    id : "mcommon.timestamp",
    defaultMessage : "Timestamp"
  },
  json : {
    id : "mcommon.json",
    defaultMessage : "JSON"
  },
  xml : {
    id : "mcommon.xml",
    defaultMessage : "XML"
  },
  enum : {
    id : "mcommon.enum",
    defaultMessage : "List"
  },
  serviceName : {
    id : "mcommon.serviceName",
    defaultMessage : "Category name"
  },
  alternativeText : {
    id : "mcommon.alternativeText",
    defaultMessage : "Alternative text"
  },
  alternativeName : {
    id : "mcommon.alternativeName",
    defaultMessage : "Alternative Name"
  },
  useSubstitutions : {
    id : "mcommon.useSubstitutions",
    defaultMessage : "Use substitutions"
  },
  replaceNullValue : {
    id : "mcommon.replaceNullValue",
    defaultMessage : "Substitution value if non-existent"
  },
  externalFlow : {
    id : "mcommon.externalFlow",
    defaultMessage : "External flow"
  },
  externalwms : {
    id : "mcommon.externalwms",
    defaultMessage : "External WMS"
  },
  externalWMSServer : {
    id : "mcommon.externalWMSServer",
    defaultMessage : "External WMS Server"
  },
  choiceLayer : {
    id : "mcommon.choiceLayer",
    defaultMessage : "choice of layer"
  },
  layer : {
    id : "mcommon.layer",
    defaultMessage : "Layer"
  },
  layers : {
    id : "mcommon.layers",
    defaultMessage : "Layers"
  },
  viewle_message : {
    id : "mcommon.viewle_message",
    defaultMessage : "View the "
  },
  summaryTable : {
    id : "mcommon.summaryTable",
    defaultMessage : "Summary Table"
  },
  multiParamSubtitution : {
    id : "mcommon.multiParamSubtitution",
    defaultMessage : "Substitution of several parameters"
  },
  nbParameter : {
    id : "mcommon.nbParameter",
    defaultMessage : "Number of parameters"
  },
  dataToExtract : {
    id : "mcommon.dataToExtract",
    defaultMessage : "Data to extract"
  },
  sendPrometheeJson : {
    id : "mcommon.sendPrometheeJson",
    defaultMessage : "Send Promethee JSON"
  },
  processed : {
    id : "mcommon.processed",
    defaultMessage : "Processed"
  },
  notProcessed : {
    id : "mcommon.notProcessed",
    defaultMessage : "Not processed"
  },
  shortDueTime : {
    id : "mcommon.shortDueTime",
    defaultMessage : "Short due time"
  },
  late : {
    id : "mcommon.late",
    defaultMessage : "Late"
  },
  differentOriginProfile : {
    id : "mcommon.differentOriginProfile",
    defaultMessage : "Different origin profile"
  },
  rescueTask : {
    id : "mcommon.rescueTask",
    defaultMessage : "Rescue task"
  },
  rescueTaskName : {
    id : "mcommon.rescueTaskName",
    defaultMessage : "Rescue task name : {name}"
  },
  sent : {
    id : "mcommon.sent",
    defaultMessage : "Sent"
  },
  partiallySent : {
    id : "mcommon.partiallySent",
    defaultMessage : "Partially sent"
  },
  partialFailure : {
    id : "mcommon.partialFailure",
    defaultMessage : "Partial failure"
  },
  totalFailure : {
    id : "mcommon.totalFailure",
    defaultMessage : "Total failure"
  },
  partialFailureDissemination : {
    id : "mcommon.partialFailureDissemination",
    defaultMessage : "Partial failure of dissemination"
  },
  totalFailureDissemination : {
    id : "mcommon.totalFailureDissemination",
    defaultMessage : "Total failure of dissemination"
  },
  taken : {
    id : "mcommon.taken",
    defaultMessage : "Taken"
  },
  delegated : {
    id : "mcommon.delegated",
    defaultMessage : "Delegated"
  },
  monitoring : {
    id : "mcommon.monitoring",
    defaultMessage : "Monitoring"
  },
  autoMonitoring : {
    id : "mcommon.autoMonitoring",
    defaultMessage : "Auto Monitoring"
  },
  administrator : {
    id : "mcommon.administrator",
    defaultMessage : "Administrator"
  },
  displayNone : {
    id : "mcommon.displayNone",
    defaultMessage : "None"
  },
  allPID : {
    id : "mcommon.allPID",
    defaultMessage : "All risks"
  },
  allDatetime : {
    id : "mcommon.allDatetime",
    defaultMessage : "All dates"
  },
  allPoint : {
    id : "mcommon.allPoint",
    defaultMessage : "All areas"
  },
  body : {
    id : "mcommon.body",
    defaultMessage : "Body"
  },
  subject : {
    id : "mcommon.subject",
    defaultMessage : "Subject"
  },
  send_mode : {
    id : "mcommon.send_mode",
    defaultMessage : "Send mode"
  },
  filename : {
    id : "mcommon.filename",
    defaultMessage : "Filename"
  },
  allCustomer : {
    id : "mcommon.allCustomer",
    defaultMessage : "All customers"
  },
  allPeriods : {
    id : "mcommon.allPeriods",
    defaultMessage : "All periods"
  },
  defaultValues : {
    id : "mcommon.defaultValues",
    defaultMessage : "Default values"
  },
  mySelection : {
    id : "mcommon.mySelection",
    defaultMessage : "My selection"
  },
  initializations : {
    id : "mcommon.initializations",
    defaultMessage : "Initializations"
  },
  initConfigFor : {
    id : "mcommon.initConfigFor",
    defaultMessage : "Initialization configuration for"
  },
  decimalPlaces : {
    id : "mcommon.decimalPlaces",
    defaultMessage : "Decimal places"
  },
  rounded : {
    id : "mcommon.rounded",
    defaultMessage : "Rounded"
  },
  dataFormat : {
    id : "mcommon.dataFormat",
    defaultMessage : "Data format"
  },
  duplicateLastConfig : {
    id : "mcommon.duplicateLastConfig",
    defaultMessage : "Duplicate last config"
  },
  threshold : {
    id : "mcommon.threshold",
    defaultMessage : "Threshold"
  },
  thresholds : {
    id : "mcommon.thresholds",
    defaultMessage : "Thresholds"
  },
  thresholded : {
    id : "mcommon.thresholded",
    defaultMessage : "Thresholded"
  },
  addThreshold : {
    id : "mcommon.addThreshold",
    defaultMessage : "Add threshold"
  },
  other : {
    id : "mcommon.other",
    defaultMessage : "Other"
  },
  noInitDefined : {
    id : "mcommon.noInitDefined",
    defaultMessage : "No initialization defined"
  },
  noPaletteFound : {
    id : "mcommon.noPaletteFound",
    defaultMessage : "No palette found for this parameter"
  },
  saveAndQuit : {
    id : "mcommon.saveAndQuit",
    defaultMessage : "Save and quit"
  },
  saveToConfigurePalette : {
    id : "mcommon.saveToConfigurePalette",
    defaultMessage : "Save the parameter to configure its associated palettes"
  },
  configurePalette : {
    id : "mcommon.configurePalette",
    defaultMessage : "Configure associated palettes"
  },
  substitutionPalettes : {
    id : "mcommon.substitutionPalettes",
    defaultMessage : "Substitutions palettes"
  },
  newParam : {
    id : "mcommon.newParam",
    defaultMessage : "New parameter"
  },
  newGeo : {
    id : "mcommon.newGeo",
    defaultMessage : "New point of interest"
  },
  allowedTypesNotDefined : {
    id : "mcommon.allowedTypesNotDefined",
    defaultMessage : "Allowed parameter types are not defined"
  },
  allPublicPoints : {
    id : "mcommon.allPublicPoints",
    defaultMessage : "All public points"
  },
  allPublicRisks : {
    id : "mcommon.allPublicRisks",
    defaultMessage : "All public risks"
  },
  allPublicComponents : {
    id : "mcommon.allPublicComponents",
    defaultMessage : "All public components"
  },
  allPublicValues : {
    id : "mcommon.allPublicValues",
    defaultMessage : "All public values"
  },
  applicableCriteria : {
    id : "mcommon.applicableCriteria",
    defaultMessage : "Applicable criteria"
  },
  substitutionWithoutApplicableCriteria : {
    id : "mcommon.substitutionWithoutApplicableCriteria",
    defaultMessage : "Substitution without applicable criteria"
  },
  dataproduct : {
    id : "mcommon.dataproduct",
    defaultMessage : "XML product"
  },
  jsondataproduct : {
    id : "mcommon.jsondataproduct",
    defaultMessage : "JSON product"
  },
  csvdataproduct : {
    id : "mcommon.csvdataproduct",
    defaultMessage : "CSV product"
  },
  textproduct : {
    id : "mcommon.textproduct",
    defaultMessage : "Text product"
  },
  gridproduct : {
    id : "mcommon.gridproduct",
    defaultMessage : "Grid product"
  },
  gridcomponent : {
    id : "mcommon.gridcomponent",
    defaultMessage : "Grid component"
  },
  paicomponent : {
    id : "mcommon.paicomponent",
    defaultMessage : "Vector component"
  },
  paiproduct : {
    id : "mcommon.paiproduct",
    defaultMessage : "Vector product"
  },
  mapproduct : {
    id : "mcommon.mapproduct",
    defaultMessage : "Map product"
  },
  seriesproduct : {
    id : "mcommon.seriesproduct",
    defaultMessage : "Series product"
  },
  okapiproduct : {
    id : "mcommon.okapiproduct",
    defaultMessage : "Okapi product"
  },
  assemblyproduct : {
    id : "mcommon.assemblyproduct",
    defaultMessage : "Assembly product"
  },
  unableToFindPoint : {
    id : "mcommon.unableToFindPoint",
    defaultMessage : "Unable to find point \"{label}\""
  },
  sameAsParameter : {
    id : "mcommon.sameAsParameter",
    defaultMessage : "Same as parameter"
  },
  newSubstitution : {
    id : "mcommon.newSubstitution",
    defaultMessage : "New substitution"
  },
  selectSubstitution : {
    id : "mcommon.selectSubstitution",
    defaultMessage : "Select substitution"
  },
  favoriteSubstitutions : {
    id : "mcommon.favoriteSubstitutions",
    defaultMessage : "Favorite substitutions"
  },
  publicSubstitutions : {
    id : "mcommon.publicSubstitutions",
    defaultMessage : "Public substitutions"
  },
  privateSubstitutions : {
    id : "mcommon.privateSubstitutions",
    defaultMessage : "Private substitutions"
  },
  dataRequester : {
    id : "mcommon.dataRequester",
    defaultMessage : "Data requester"
  },
  defaultSubstitution : {
    id : "mcommon.defaultSubstitution",
    defaultMessage : "Default substitution"
  },
  substitutionText : {
    id : "mcommon.substitutionText",
    defaultMessage : "Substitution text"
  },
  substitutionAlternativeText : {
    id : "mcommon.substitutionAlternativeText",
    defaultMessage : "Alternative substitution text"
  },
  substitutionImage : {
    id : "mcommon.substitutionImage",
    defaultMessage : "Substitution image"
  },
  substitutionName : {
    id : "mcommon.substitutionName",
    defaultMessage : "Substitution set"
  },
  addPalette : {
    id : "mcommon.addPalette",
    defaultMessage : "Create pallet"
  },
  addRule : {
    id : "mcommon.addRule",
    defaultMessage : "Add rule"
  },
  palette : {
    id : "mcommon.palette",
    defaultMessage : "Colour scheme"
  },
  palettes : {
    id : "mcommon.palettes",
    defaultMessage : "Colour schemes"
  },
  substitutionType : {
    id : "mcommon.substitutionType",
    defaultMessage : "Type of substitution"
  },
  substitutionTypeValeur : {
    id : "mcommon.substitutionTypeValeur",
    defaultMessage : "Value"
  },
  substitutionTypeVariation : {
    id : "mcommon.substitutionTypeVariation",
    defaultMessage : "Variation"
  },
  expertiseTasks : {
    id : "mcommon.expertiseTasks",
    defaultMessage : "Expertise tasks"
  },
  storagePolicies : {
    id : "mcommon.storagePolicies",
    defaultMessage : "Storage Policies"
  },
  errorFields : {
    id : "mcommon.errorFields",
    defaultMessage : "Some required fields are not filled or not well filled"
  },
  channelsTypes : {
    id : "mcommon.channelsTypes",
    defaultMessage : "Channels"
  },
  ticketsTypes : {
    id : "mcommon.ticketsTypes",
    defaultMessage : "Tickets types"
  },
  sortBy : {
    id : "mcommon.sortBy",
    defaultMessage : "Sort by"
  },
  sortColumnsBy : {
    id : "mcommon.sortColumnsBy",
    defaultMessage : "Sort columns by"
  },
  sortLinesBy : {
    id : "mcommon.sortLinesBy",
    defaultMessage : "Sort lines by"
  },
  groupData : {
    id : "mcommon.groupData",
    defaultMessage : "Group data"
  },
  IN_PROGRESS : {
    id : "mcommon.IN_PROGRESS",
    defaultMessage : "In progress"
  },
  CREATED : {
    id : "mcommon.CREATED",
    defaultMessage : "Created"
  },
  IN_CREATION : {
    id : "mcommon.IN_CREATION",
    defaultMessage : "In creation"
  },
  IN_MODIFICATION : {
    id : "mcommon.IN_MODIFICATION",
    defaultMessage : "In modification"
  },
  DONE : {
    id : "mcommon.DONE",
    defaultMessage : "Done"
  },
  ERROR : {
    id : "mcommon.ERROR",
    defaultMessage : "Error"
  },
  INIT : {
    id : "mcommon.INIT",
    defaultMessage : "Initialized"
  },
  CANCELLED : {
    id : "mcommon.CANCELLED",
    defaultMessage : "Cancelled"
  },
  IGNORED : {
    id : "mcommon.IGNORED",
    defaultMessage : "Ignored"
  },
  WARNING : {
    id : "mcommon.WARNING",
    defaultMessage : "Warning"
  },
  importInProgress : {
    id : "mcommon.importInProgress",
    defaultMessage : "Importation in progress"
  },
  core : {
    id : "mcommon.core",
    defaultMessage : "Core"
  },
  customer_sectors : {
    id : "mcommon.customer_sectors",
    defaultMessage : "Customer sectors"
  },
  customer_types : {
    id : "mcommon.customer_types",
    defaultMessage : "Customer types"
  },
  log_errors : {
    id : "mcommon.log_errors",
    defaultMessage : "Errors"
  },
  log_error : {
    id : "mcommon.log_error",
    defaultMessage : "Errors"
  },
  log_informations : {
    id : "mcommon.log_informations",
    defaultMessage : "Informations"
  },
  log_warnings : {
    id : "mcommon.log_warnings",
    defaultMessage : "Warnings"
  },
  log_created : {
    id : "mcommon.log_created",
    defaultMessage : "Created"
  },
  log_updated : {
    id : "mcommon.log_updated",
    defaultMessage : "Mis à jour"
  },
  log_result : {
    id : "mcommon.log_result",
    defaultMessage : "Result"
  },
  lastEntry : {
    id : "mcommon.lastEntry",
    defaultMessage : "Last entry"
  },
  otherParameter : {
    id : "mcommon.otherParameter",
    defaultMessage : "Other parameter"
  },
  dateParameter : {
    id : "mcommon.dateParameter",
    defaultMessage : "Date parameter"
  },
  pointParameter : {
    id : "mcommon.pointParameter",
    defaultMessage : "Point parameter"
  },
  typeOfData : {
    id : "mcommon.typeOfData",
    defaultMessage : "Type of data"
  },
  sunday00 : {
    id : "mcommon.sunday00",
    defaultMessage : "Sunday 00:00"
  },
  monday00 : {
    id : "mcommon.monday00",
    defaultMessage : "Monday 00:00"
  },
  dayOfMonth : {
    id : "mcommon.dayOfMonth",
    defaultMessage : "Day of month 00:00"
  },
  dayOfYear : {
    id : "mcommon.dayOfYear",
    defaultMessage : "Day of year 00:00"
  },
  dataHistory : {
    id : "mcommon.dataHistory",
    defaultMessage : "Data history"
  },
  currentValue : {
    id : "mcommon.currentValue",
    defaultMessage : "Current value"
  },
  previousValue : {
    id : "mcommon.previousValue",
    defaultMessage : "Previous value"
  },
  specificDates : {
    id : "mcommon.specificDates",
    defaultMessage : "Specific dates"
  },
  roundedTo : {
    id : "mcommon.roundedTo",
    defaultMessage : "Rounded to"
  },
  gap : {
    id : "mcommon.gap",
    defaultMessage : "Gap"
  },
  colorCodeFormat : {
    id : "mcommon.colorCodeFormat",
    defaultMessage : "Color code format"
  },
  initDimensionValues : {
    id : "mcommon.initDimensionValues",
    defaultMessage : "Initialize dimension values ​​with those of production customers"
  },
  aggregationData : {
    id : "mcommon.aggregationData",
    defaultMessage : "Aggregation"
  },
  aggregationType : {
    id : "mcommon.aggregationType",
    defaultMessage : "Aggregation type"
  },
  proactiveContacts : {
    id : "mcommon.proactiveContacts",
    defaultMessage : "Proactive contacts"
  },
  otherContacts : {
    id : "mcommon.otherContacts",
    defaultMessage : "Other contacts"
  },
  prometheePeriods : {
    id : "mcommon.prometheePeriods",
    defaultMessage : "Promethee periods"
  },
  mustaches : {
    id : "mcommon.mustaches",
    defaultMessage : "To display Tags type '{{'"
  },
  minute : {
    id : "mcommon.minute",
    defaultMessage : "Minute"
  },
  missingData : {
    id : "mcommon.missingData",
    defaultMessage : "Missing data"
  },
  favorites : {
    id : "mcommon.favorites",
    defaultMessage : "Favorites"
  },
  confirmDuplicateItem : {
    id : "mcommon.confirmDuplicateItem",
    defaultMessage : "Are you sure ?"
  },
  projectionNotAvailable : {
    id : "mcommon.projectionNotAvailable",
    defaultMessage : "Global projection not available for this layer."
  },
  projectionsAvailableAre : {
    id : "mcommon.projectionsAvailableAre",
    defaultMessage : "The projections available are :"
  },
  previewWithProjection : {
    id : "mcommon.previewWithProjection",
    defaultMessage : "The preview is given with the layer's default projection."
  },
  sortByOrder : {
    id : "mcommon.sortByOrder",
    defaultMessage : "Sort by order"
  },
  ascending : {
    id : "mcommon.ascending",
    defaultMessage : "Ascending"
  },
  descending : {
    id : "mcommon.descending",
    defaultMessage : "Descending"
  },
  viewMore : {
    id : "mcommon.viewMore",
    defaultMessage : "{count} more"
  },
  automaticProduction : {
    id : "mcommon.automaticProduction",
    defaultMessage : "Automatic production"
  },
  expertProduction : {
    id : "mcommon.expertProduction",
    defaultMessage : "Expert production"
  },
  choosenRecipients : {
    id : "mcommon.choosenRecipients",
    defaultMessage : "Choosen recipients"
  },
  addressDetails : {
    id : "mcommon.addressDetails",
    defaultMessage : "Search customer name, contact name or address description"
  },
  publicGeo : {
    id : "mcommon.publicGeo",
    defaultMessage : "Public"
  },
  privateGeo : {
    id : "mcommon.privateGeo",
    defaultMessage : "Private"
  },
  xmet : {
    id : "mcommon.xmet",
    defaultMessage : "EXTRAMET"
  },
  xmetExtranets : {
    id : "mcommon.xmetExtranets",
    defaultMessage : "Extranets"
  },
  xmetComponents : {
    id : "mcommon.xmetComponents",
    defaultMessage : "Components"
  },
  boolean : {
    id : "mcommon.boolean",
    defaultMessage : "Boolean"
  },
  dim_time_ref_period : {
    id : "mcommon.dim_time_ref_period",
    defaultMessage : "Accumulation period"
  },
  mainDimensions : {
    id : "mcommon.mainDimensions",
    defaultMessage : "Main dimensions"
  },
  prometheeDimensions : {
    id : "mcommon.prometheeDimensions",
    defaultMessage : "Promethee dimensions"
  },
  others : {
    id : "mcommon.others",
    defaultMessage : "Others"
  },
  selected : {
    id : "mcommon.selected",
    defaultMessage : "selected"
  },
  selectedPlural : {
    id : "mcommon.selectedPlural",
    defaultMessage : "selected"
  },
  profils : {
    id : "mcommon.profils",
    defaultMessage : "Dashboards"
  },
  testMode : {
    id : "mcommon.testMode",
    defaultMessage : "Test mode"
  },
  assignmentMessage : {
    id : "mcommon.assignmentMessage",
    defaultMessage :
      "User {requesterName} wants to assign the following tasks :\n\n{assignmentsText}\n\nDo you accept ?"
  },
  cell_phone : {
    id : "mcommon.cell_phone",
    defaultMessage : "Cell phone"
  },
  distributionStateCreated : {
    id : "mcommon.distributionStateCreated",
    defaultMessage : "Sent to distribution system"
  },
  distributionStateInProgress : {
    id : "mcommon.distributionStateInProgress",
    defaultMessage : "Waiting for distribution status"
  },
  distributionStateDone : {
    id : "mcommon.distributionStateDone",
    defaultMessage : "Sent"
  },
  distributionStateError : {
    id : "mcommon.distributionStateError",
    defaultMessage : "Sent error"
  },
  TODO : {
    id : "mcommon.TODO",
    defaultMessage : "To do"
  },
  roundedPreviousHour : {
    id : "mcommon.roundedPreviousHour",
    defaultMessage : "Rounded to the previous hour"
  },
  roundedPosteriorHour : {
    id : "mcommon.roundedPosteriorHour",
    defaultMessage : "Rounded to the posterior hour"
  },
  roundedDownTriHourlySynoptic : {
    id : "mcommon.roundedDownTriHourlySynoptic",
    defaultMessage : "Rounded down to the lower tri-hourly synoptic time"
  },
  roundedUpTriHourSynoptic : {
    id : "mcommon.roundedUpTriHourSynoptic",
    defaultMessage : "Rounded up to the upper tri-hour synoptic hour"
  },
  lowerSynoptic : {
    id : "mcommon.lowerSynoptic",
    defaultMessage : "Lower synoptic"
  },
  upperSynoptic : {
    id : "mcommon.upperSynoptic",
    defaultMessage : "Upper synoptic"
  },
  distributionCreationError : {
    id : "mcommon.distributionCreationError",
    defaultMessage : "Error in creation"
  },
  checkDistributionConfiguration : {
    id : "mcommon.checkDistributionConfiguration",
    defaultMessage : "Check distribution configuration"
  },
  waitingCreation : {
    id : "mcommon.waitingCreation",
    defaultMessage : "Waiting creation"
  },
  geoWithIn : {
    id : "mcommon.geoWithIn",
    defaultMessage : "Contained in"
  },
  confirmSelectItems : {
    id : "mcommon.confirmSelectItems",
    defaultMessage : "Are you sure you want to select {count} points?"
  },
  distributionStateIgnored : {
    id : "mcommon.distributionStateIgnored",
    defaultMessage : "Recipient in error but ignored"
  },
  distributionStateCancelled : {
    id : "mcommon.distributionStateCancelled",
    defaultMessage : "Recipient cancelled"
  },
  distributionStateWarning : {
    id : "mcommon.distributionStateWarning",
    defaultMessage : "Completed with later error"
  },
  testModeDistribution : {
    id : "mcommon.testModeDistribution",
    defaultMessage : "No distribution"
  },
  operationalModeDistribution : {
    id : "mcommon.operationalModeDistribution",
    defaultMessage : "Active distributions on the operational environment"
  },
  zoneTitle : {
    id : "mcommon.zoneTitle",
    defaultMessage : "Zone title"
  },
  confirmUnselectItems : {
    id : "mcommon.confirmUnselectItems",
    defaultMessage : "Are you sure you want to unselect all items?"
  },
  businessManager : {
    id : "mcommon.businessManager",
    defaultMessage : "Business manager"
  },
  attachments : {
    id : "mcommon.attachments",
    defaultMessage : "Attachments"
  },
  attach : {
    id : "mcommon.attach",
    defaultMessage : "Attach"
  },
  uploadError : {
    id : "mcommon.uploadError",
    defaultMessage : "Unable to attach the file: the size must not exceed 10MB"
  },
  allCategories : {
    id : "mcommon.allCategories",
    defaultMessage : "All"
  },
  sameDimensionsRequired : {
    id : "mcommon.sameDimensionsRequired",
    defaultMessage : "Selected parameter must have the same dimensions than the initialized parameter"
  },
  synopsisConfig : {
    id : "mcommon.synopsisConfig",
    defaultMessage : "Synopsis Configuration"
  },
  newSynopsisConfig : {
    id : "mcommon.newSynopsisConfig",
    defaultMessage : "New Synopsis Configuration"
  },
  authority : {
    id : "mcommon.authority",
    defaultMessage : "Authority"
  },
  scheme : {
    id : "mcommon.scheme",
    defaultMessage : "Scheme"
  },
  port : {
    id : "mcommon.port",
    defaultMessage : "Port"
  },
  visible : {
    id : "mcommon.visible",
    defaultMessage : "Visible"
  },
  failoverActive : {
    id : "mcommon.failoverActive",
    defaultMessage : "Active failover"
  },
  failoverConfig : {
    id : "mcommon.failoverConfig",
    defaultMessage : "Failover configuration"
  },
  digitalForecast : {
    id : "mcommon.digitalForecast",
    defaultMessage : "DF / IBF Expertise Status"
  },
  month : {
    id : "mcommon.month",
    defaultMessage : "Month"
  },
  lastDayOfMonth : {
    id : "mcommon.lastDayOfMonth",
    defaultMessage : "Last day of month"
  },
  confirmInsertTasks : {
    id : "mcommon.confirmInsertTasks",
    defaultMessage : "Are your sure you want to insert these tasks ?"
  },
  confirmScheduleTasks : {
    id : "mcommon.confirmScheduleTasks",
    defaultMessage : "Are your sure you want to schedule these tasks ?"
  },
  forecast : {
    id : "mcommon.forecast",
    defaultMessage : "Forecast"
  },
  infographics : {
    id : "mcommon.infographics",
    defaultMessage : "Infographics"
  },
  unknown : {
    id : "mcommon.unknown",
    defaultMessage : "Unknown"
  },
  mode : {
    id : "mcommon.mode",
    defaultMessage : "Mode"
  },
  number : {
    id : "mcommon.number",
    defaultMessage : "Number"
  },
  eventType : {
    id : "mcommon.eventType",
    defaultMessage : "Event type"
  },
  event : {
    id : "mcommon.event",
    defaultMessage : "Event"
  },
  follow : {
    id : "mcommon.follow",
    defaultMessage : "Follow"
  },
  finish : {
    id : "mcommon.finish",
    defaultMessage : "Finish"
  },
  legend : {
    id : "mcommon.legend",
    defaultMessage : "Legend"
  },
  and : {
    id : "mcommon.and",
    defaultMessage : "And"
  },
  between : {
    id : "mcommon.between",
    defaultMessage : "Between"
  },
  automatic : {
    id : "mcommon.automatic",
    defaultMessage : "Automatic"
  },
  prevStep : {
    id : "mcommon.prevStep",
    defaultMessage : "Previous step"
  },
  messageErrorFetch : {
    id : "mcommon.messageErrorFetch",
    defaultMessage : "An error occurs while loading"
  },
  priority : {
    id : "mcommon.priority",
    defaultMessage : "Priority"
  },
  updateFailure : {
    id : "mcommon.updateFailure",
    defaultMessage : "An error occured when trying to update this item"
  },
  request : {
    id : "mcommon.request",
    defaultMessage : "Request"
  },
  visit : {
    id : "mcommon.visit",
    defaultMessage : "Visit"
  },
  agatKey : {
    id : "mcommon.agatKey",
    defaultMessage : "AGAT technical code"
  },
  distributionMeans : {
    id : "mcommon.distributionMeans",
    defaultMessage : "Distribution means"
  },
  fax : {
    id : "mcommon.fax",
    defaultMessage : "FAX"
  },
  contactName : {
    id : "mcommon.contactName",
    defaultMessage : "Contact name"
  },
  moduleDependency : {
    id : "mcommon.moduleDependency",
    defaultMessage : "Module dependency"
  },
  goodPractices : {
    id : "mcommon.goodPractices",
    defaultMessage : "Good practices"
  },
  setUpWorkspace : {
    id : "mcommon.setUpWorkspace",
    defaultMessage : "Set up workspace"
  },
  fr : {
    id : "mcommon.fr",
    defaultMessage : "French"
  },
  productionCenter : {
    id : "mcommon.productionCenter",
    defaultMessage : "Production center"
  },
  general : {
    id : "mcommon.general",
    defaultMessage : "General"
  },
  phone : {
    id : "mcommon.phone",
    defaultMessage : "Phone"
  },
  ftp : {
    id : "mcommon.ftp",
    defaultMessage : "FTP"
  },
  web : {
    id : "mcommon.web",
    defaultMessage : "Web"
  },
  postal : {
    id : "mcommon.postal",
    defaultMessage : "Postal"
  },
  voice : {
    id : "mcommon.voice",
    defaultMessage : "Voice"
  },
  requestInProgress : {
    id : "mcommon.requestInProgress",
    defaultMessage : "Request in progress"
  },
  author : {
    id : "mcommon.author",
    defaultMessage : "Author"
  },
  businessCustomer : {
    id : "mcommon.businessCustomer",
    defaultMessage : "Business customer"
  },
  en : {
    id : "mcommon.en",
    defaultMessage : "English"
  },
  finished : {
    id : "mcommon.finished",
    defaultMessage : "Finished"
  },
  confirmQuitEditor : {
    id : "mcommon.confirmQuitEditor",
    defaultMessage : "Are you sure you want to quit editing without saving ?"
  },
  crashErrorYouShouldReload : {
    id : "mcommon.crashErrorYouShouldReload",
    defaultMessage : "Something went wrong. You should save your work and reload the page"
  },
  selectedItems : {
    id : "mcommon.selectedItems",
    defaultMessage : "Selected items"
  },
  createSelection : {
    id : "mcommon.createSelection",
    defaultMessage : "Create a selection"
  },
  unableToFindPoints : {
    id : "mcommon.unableToFindPoints",
    defaultMessage : "Unable to find these points"
  },
  tooMuchElementsInView : {
    id : "mcommon.tooMuchElementsInView",
    defaultMessage : "Too much elements in view : Add filters or zoom up"
  },
  chooseGeo : {
    id : "mcommon.chooseGeo",
    defaultMessage : "Choose a geographical position"
  },
  coordinates : {
    id : "mcommon.coordinates",
    defaultMessage : "Coordinates"
  },
  paramsError : {
    id : "mcommon.paramsError",
    defaultMessage : "A problem occurred while retrieving parameters"
  },
  calculated : {
    id : "mcommon.calculated",
    defaultMessage : "Calculated"
  },
  defineOperation : {
    id : "mcommon.defineOperation",
    defaultMessage : "Define operation"
  },
  testWithValues : {
    id : "mcommon.testWithValues",
    defaultMessage : "Test with values"
  },
  nameOfOperationParam : {
    id : "mcommon.nameOfOperationParam",
    defaultMessage : "Name of parameter in operation"
  },
  nameOfOperationParams : {
    id : "mcommon.nameOfOperationParams",
    defaultMessage : "Name of parameters in operation"
  },
  calculation : {
    id : "mcommon.calculation",
    defaultMessage : "Calculation"
  },
  pagingAlgorithm : {
    id : "mcommon.pagingAlgorithm",
    defaultMessage : "Paging algorithm"
  },
  activityAreas : {
    id : "mcommon.activityAreas",
    defaultMessage : "Activity areas"
  },
  defaultInitialization : {
    id : "mcommon.defaultInitialization",
    defaultMessage : "Default initialization"
  },
  exportConfiguration : {
    id : "mcommon.exportConfiguration",
    defaultMessage : "Export configuration"
  },
  exportTable : {
    id : "mcommon.exportTable",
    defaultMessage : "Export table"
  },
  output : {
    id : "mcommon.output",
    defaultMessage : "Output"
  },
  logo : {
    id : "mcommon.logo",
    defaultMessage : "Logo"
  },
  csvImport : {
    id : "mcommon.csvImport",
    defaultMessage : "CSV import"
  },
  jsonImport : {
    id : "mcommon.jsonImport",
    defaultMessage : "JSON import"
  },
  distanceToPoint : {
    id : "mcommon.distanceToPoint",
    defaultMessage : "Distance to point"
  },
  useInitAsFallback : {
    id : "mcommon.useInitAsFallback",
    defaultMessage : "Use init value as fallback"
  },
  customUnit : {
    id : "mcommon.customUnit",
    defaultMessage : "Customized unit"
  },
  createdBy : {
    id : "mcommon.createdBy",
    defaultMessage : "Created by"
  },
  updatedBy : {
    id : "mcommon.updatedBy",
    defaultMessage : "Updated by"
  },
  createdOn : {
    id : "mcommon.createdOn",
    defaultMessage : "Created on"
  },
  updatedOn : {
    id : "mcommon.updatedOn",
    defaultMessage : "Updated on"
  },
  by : {
    id : "mcommon.by",
    defaultMessage : "By"
  },
  notAvailableForSelection : {
    id : "mcommon.notAvailableForSelection",
    defaultMessage : "Not available for this selection"
  },
  generalProperties : {
    id : "mcommon.generalProperties",
    defaultMessage : "General properties"
  },
  labelAlternative : {
    id : "mcommon.labelAlternative",
    defaultMessage : "Alternative name"
  },
  varPoint : {
    id : "mcommon.varPoint",
    defaultMessage : "Geographical position"
  },
  varTime : {
    id : "mcommon.varTime",
    defaultMessage : "Date"
  },
  useAggregationMethod : {
    id : "mcommon.useAggregationMethod",
    defaultMessage : "Use aggregation method"
  },
  customerConfiguration : {
    id : "mcommon.customerConfiguration",
    defaultMessage : "Customers configuration"
  },
  visualize : {
    id : "mcommon.visualize",
    defaultMessage : "Visualize"
  },
  parameterUnit : {
    id : "mcommon.parameterUnit",
    defaultMessage : "Parameter unit"
  },
  normal : {
    id : "mcommon.normal",
    defaultMessage : "Normal"
  },
  alert : {
    id : "mcommon.alert",
    defaultMessage : "Alert"
  },
  dataProvider : {
    id : "mcommon.dataProvider",
    defaultMessage : "Data provider"
  },
  dueDateEnd : {
    id : "mcommon.dueDateEnd",
    defaultMessage : "...and due date"
  },
  dueDateStart : {
    id : "mcommon.dueDateStart",
    defaultMessage : "Between due date..."
  },
  dayAbbreviation : {
    id : "mcommon.dayAbbreviation",
    defaultMessage : "D"
  },
  midnight : {
    id : "mcommon.midnight",
    defaultMessage : "00:00 UTC"
  },
  refTime : {
    id : "mcommon.refTime",
    defaultMessage : "Reference time"
  },
  changeImage : {
    id : "mcommon.changeImage",
    defaultMessage : "Change image"
  },
  confirmDepersonalizePalette : {
    id : "mcommon.confirmDepersonalizePalette",
    defaultMessage : "Are you sure you want to delete the personalization of the palette?"
  },
  dimensionsNeeded : {
    id : "mcommon.dimensionsNeeded",
    defaultMessage : "Dimension are required"
  },
  prometheeComponent : {
    id : "mcommon.prometheeComponent",
    defaultMessage : "Promethee component"
  },
  addAttachment : {
    id : "mcommon.addAttachment",
    defaultMessage : "Add attachment"
  },
  mapBackground : {
    id : "mcommon.mapBackground",
    defaultMessage : "Map background"
  },
  osm : {
    id : "mcommon.osm",
    defaultMessage : "OpenStreetMap"
  },
  landform : {
    id : "mcommon.landform",
    defaultMessage : "Landform"
  },
  dashboardDataMonitoring : {
    id : "mcommon.dashboardDataMonitoring",
    defaultMessage : "Data Monitoring task table : {selectedDashboard}"
  },
  selectTaskTableToMonitor : {
    id : "mcommon.selectTaskTableToMonitor",
    defaultMessage : "Please select a task table to monitor"
  },
  generalType : {
    id : "mcommon.generalType",
    defaultMessage : "General type"
  },
  attributes : {
    id : "mcommon.attributes",
    defaultMessage : "Attributes"
  },
  aggregateIfNull : {
    id : "mcommon.aggregateIfNull",
    defaultMessage : "Force aggregation if some values missing"
  },
  geojsonVariable : {
    id : "mcommon.geojsonVariable",
    defaultMessage : "GeoJSON parameter variable"
  },
  paletteTest : {
    id : "mcommon.paletteTest",
    defaultMessage : "Substitution palette test"
  },
  applicableDimensionsTest : {
    id : "mcommon.applicableDimensionsTest",
    defaultMessage : "Test specific application criteria"
  },
  textSubstitution : {
    id : "mcommon.textSubstitution",
    defaultMessage : "Text substitution"
  },
  altTextSubstitution : {
    id : "mcommon.altTextSubstitution",
    defaultMessage : "Alt text substitution"
  },
  colorSubstitution : {
    id : "mcommon.colorSubstitution",
    defaultMessage : "Color substitution"
  },
  imageSubstitution : {
    id : "mcommon.imageSubstitution",
    defaultMessage : "Image substitution"
  },
  noSubstitution : {
    id : "mcommon.noSubstitution",
    defaultMessage : "No matching substitution found"
  },
  extractionAggregation : {
    id : "mcommon.extractionAggregation",
    defaultMessage : "Extraction Aggregation"
  },
  min : {
    id : "mcommon.min",
    defaultMessage : "Minimum"
  },
  max : {
    id : "mcommon.max",
    defaultMessage : "Maximum"
  },
  average : {
    id : "mcommon.average",
    defaultMessage : "Average"
  },
  all : {
    id : "mcommon.all",
    defaultMessage : "All"
  },
  noDimension : {
    id : "mcommon.noDimension",
    defaultMessage : "No dimension to display"
  },
  allTypeValues : {
    id : "mcommon.allTypeValues",
    defaultMessage : "All values {type}"
  },
  applicationCriteriaDefinition : {
    id : "mcommon.applicationCriteriaDefinition",
    defaultMessage : "Definition of application criteria"
  },
  mdataDimensions : {
    id : "mcommon.mdataDimensions",
    defaultMessage : "Mdata dimensions"
  },
  synopsisDimensions : {
    id : "mcommon.synopsisDimensions",
    defaultMessage : "Synopsis dimensions"
  },
  foudrepoint : {
    id : "mcommon.foudrepoint",
    defaultMessage : "Lightning Grid Point"
  },
  message4d : {
    id : "mcommon.message4d",
    defaultMessage : "4D Message"
  },
  messagenbc : {
    id : "mcommon.messagenbc",
    defaultMessage : "CBN Message"
  },
  modelchamp : {
    id : "mcommon.modelchamp",
    defaultMessage : "Multi-Grib"
  },
  modelpoint : {
    id : "mcommon.modelpoint",
    defaultMessage : "Model Point"
  },
  nowcasting : {
    id : "mcommon.nowcasting",
    defaultMessage : "Nowcasting"
  },
  obspoint : {
    id : "mcommon.obspoint",
    defaultMessage : "Obs Grid Point"
  },
  profil1d : {
    id : "mcommon.profil1d",
    defaultMessage : "1D Profile"
  },
  radarimage : {
    id : "mcommon.radarimage",
    defaultMessage : "Radar Image"
  },
  satelliteimage : {
    id : "mcommon.satelliteimage",
    defaultMessage : "Satellite Image"
  },
  synopsisExtractors : {
    id : "mcommon.synopsisExtractors",
    defaultMessage : "SYNOPSIS Extractors"
  },
  cometProducts : {
    id : "mcommon.cometProducts",
    defaultMessage : "COMET products"
  },
  expertiseinterface : {
    id : "mcommon.expertiseinterface",
    defaultMessage : "Expertise interface"
  },
  producttemplate : {
    id : "mcommon.producttemplate",
    defaultMessage : "Product template"
  },
  viewLinks : {
    id : "mcommon.viewLinks",
    defaultMessage : "View links"
  },
  objectsRelatedTo : {
    id : "mcommon.objectsRelatedTo",
    defaultMessage : "Objects related to"
  },
  noLinksFound : {
    id : "mcommon.noLinksFound",
    defaultMessage : "No links found for this item"
  },
  paramdimensionvalue : {
    id : "mcommon.paramdimensionvalue",
    defaultMessage : "Dimension value"
  },
  ticket : {
    id : "mcommon.ticket",
    defaultMessage : "Exchange"
  },
  dataAvailability : {
    id : "mcommon.dataAvailability",
    defaultMessage : "Data Availability"
  },
  oauthRedirection : {
    id : "mcommon.oauthRedirection",
    defaultMessage : "OAuth Redirection"
  },
  oauthRedirectionDisclaimer : {
    id : "mcommon.oauthRedirectionDisclaimer",
    defaultMessage : "Please wait while you're being redirected. Do not close this window."
  },
  productconfig : {
    id : "mcommon.productconfig",
    defaultMessage : "Product configuration"
  },
  expertiseconfig : {
    id : "mcommon.expertiseconfig",
    defaultMessage : "Expertise configuration"
  },
  subscriptionproduction : {
    id : "mcommon.subscriptionproduction",
    defaultMessage : "Subscribed to a production"
  },
  paletteoverride : {
    id : "mcommon.paletteoverride",
    defaultMessage : "Custom substitution"
  },
  map : {
    id : "mcommon.map",
    defaultMessage : "Map"
  },
  nearestData : {
    id : "mcommon.nearestData",
    defaultMessage : "Data on the nearest date"
  },
  geojson : {
    id : "mcommon.geojson",
    defaultMessage : "GeoJSON"
  }
})

export default { ...mreactMessages, ...messages }
