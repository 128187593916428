import PermissionsAPI from "./Permissions"
import ModulesAPI from "./Modules"
import PreferencesAPI from "./Preferences"

const apis = {
  modules : new ModulesAPI(),
  permissions : new PermissionsAPI(),
  preferences : new PreferencesAPI()
}

export default apis
